export const ScheduleNumbers = {
  trevo: {
    1: {
      name: 'TREVO 31 25 de Abril - Malagueira',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+31+25+de+Abril+-+Malagueira.pdf',
    },
    2: {
      name: 'TREVO 21 Louredo - Luís de Camões',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+21+Louredo+-+Lui%CC%81s+de+Camo%CC%83es.pdf',
    },
    3: {
      name: 'TREVO 41 Gabriel Pereira - Casinha',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+41+Gabriel+Pereira+-+Casinha.pdf',
    },
    4: {
      name: 'TREVO 22 Canaviais - Parque Industrial (via Malagueira)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+22+Canaviais+-+Parque+Industrial+(via+Malagueira).pdf',
    },
    5: {
      name: 'TREVO 24 - Canaviais - Parque Industrial (via centro histórico)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+24+-+Canaviais+-+Parque+Industrial+(via+centro+histo%CC%81rico).pdf',
    },
    6: {
      name: 'TREVO 23 Frei Aleixo - Almeirim',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+23+Frei+Aleixo+-+Almeirim.pdf',
    },
    7: {
      name: 'TREVO Linha Azul Sul',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+Linha+Azul+Sul.pdf',
    },
    8: {
      name: 'TREVO 25 Canaviais - Luís de Camões',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+25+Canaviais+-+Lui%CC%81s+de+Camo%CC%83es.pdf',
    },
    9: {
      name: 'TREVO 32 25 de Abril - Malagueira',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+32+25+de+Abril+-+Malagueira.pdf',
    },
    10: {
      name: 'TREVO 33 Sra. Saúde - Fontanas',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+33+Sra.+Sau%CC%81de+-+Fontanas.pdf',
    },
    11: {
      name: 'TREVO 34 Cruz Picada - Sra. Saúde',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+34+Cruz+Picada+-+Sra.+Sau%CC%81de.pdf',
    },
    12: {
      name: 'Linha Azul Norte',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+Linha+Azul+Norte.pdf',
    },
    13: {
      name: 'TREVO 26 Canaviais - Parque Aeronáutico',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/TREVO+26+Canaviais+-+Parque+Aerona%CC%81utico.pdf',
    },
  },
  proximo: {
    1: {
      name: 'PXM17 | Praia de Faro - Penha (via S. Luís)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM17+%7C+Praia+de+Faro+-+Penha+(via+S.+Lui%CC%81s).pdf',
    },
    2: {
      name: 'PXM4P | Patacão - Mar e Guerra - Hospital',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM4P+%7C+Pataca%CC%83o+-+Mar+e+Guerra+-+Hospital.pdf',
    },
    3: {
      name: 'PXM4N | Hospital - Mar e Guerra - Patacão',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM4N+%7C+Hospital+-+Mar+e+Guerra+-+Pataca%CC%83o.pdf',
    },
    4: {
      name: 'PXM15 | T. Rodoviário - Aeroporto - Montenegro (via Hospital)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM15+%7C+T.+Rodovia%CC%81rio+-+Aeroporto+-+Montenegro+(via+Hospital).pdf',
    },
    5: {
      name: 'PXM1 | Continente - Centro de Saúde - Forum',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM1+%7C+Continente+-+Centro+de+Sau%CC%81de+-+Forum.pdf',
    },
    6: {
      name: 'PXM2 | Bom João - Atalaia',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM2+%7C+Bom+Joa%CC%83o+-+Atalaia.pdf',
    },
    7: {
      name: 'PXM3 | Mercado - Piscinas - Penha - Cemitério',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM3+%7C+Mercado+-+Piscinas+-+Penha+-+Cemite%CC%81rio.pdf',
    },
    8: {
      name: 'PXM5 | Terminal - Mercado - Bom João - Liceu - Forum',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM5+%7C+Terminal+-+Mercado+-+Bom+Joa%CC%83o+-+Liceu+-+Forum.pdf',
    },
    9: {
      name: 'PXM14 | Atalaia - Praia de Faro (via Montenegro e Aeroporto)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM14+%7C+Atalaia+-+Praia+de+Faro+(via+Montenegro+e+Aeroporto).pdf',
    },
    10: {
      name: 'PXM16 | T. Rodoviário - Praia de Faro (via Montenegro e Aeroporto)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM16+%7C+T.+Rodovia%CC%81rio+-+Praia+de+Faro+(via+Montenegro+e+Aeroporto).pdf',
    },
    11: {
      name: 'PXM19 | T. Rodoviário - Gambelas (via Aboim Ascensão)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM19+%7C+T.+Rodovia%CC%81rio+-+Gambelas+(via+Aboim+Ascensa%CC%83o).pdf',
    },
    12: {
      name: 'PXM18 | T. Rodoviário - Gambelas (via Penha)',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM18+%7C+T.+Rodovia%CC%81rio+-+Gambelas+(via+Penha).pdf',
    },
    13: {
      name: 'PXM1A | R. Alportel - Continente - Conceição',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM1A+%7C+R.+Alportel+-+Continente+-+Conceic%CC%A7a%CC%83o.pdf',
    },
    14: {
      name: 'PXM5A | Terminal - Forum - Liceu - Bom João - Mercado',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/PXM5A+%7C+Terminal+-+Forum+-+Liceu+-+Bom+Joa%CC%83o+-+Mercado.pdf',
    },
  },
  BEJA: {
    1: {
      name: 'URBANAS BEJA - Urbana 5a',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/URBANAS+BEJA+-+Urbana+5a.pdf',
    },
    2: {
      name: 'URBANAS BEJA - Urbana 5b',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/URBANAS+BEJA+-+Urbana+5b.pdf',
    },
    3: {
      name: 'URBANAS BEJA - Urbana 3 | Urbana 4 - Zona B',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/URBANAS+BEJA+-+Urbana+3+%7C+Urbana+4+-+Zona+B.pdf',
    },
    4: {
      name: 'URBANAS BEJA - Urbana 3 | Urbana 4 - Zona B',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/URBANAS+BEJA+-+Urbana+3+%7C+Urbana+4+-+Zona+B.pdf',
    },
    5: {
      name: 'URBANAS BEJA - Urbana 1 | Urbana 2 - Zona A',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/URBANAS+BEJA+-+Urbana+1+%7C+Urbana+2+-+Zona+A.pdf',
    },
    6: {
      name: 'URBANAS BEJA - Urbana 1 | Urbana 2 - Zona A',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/URBANAS+BEJA+-+Urbana+1+%7C+Urbana+2+-+Zona+A.pdf',
    },
  },
}
