import { Flex, Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

import satisfactionImageDesktop from '../../../../../../assets/satisfaction_poll_desktop.png'
import satisfactionImageMobile from '../../../../../../assets/satisfaction_poll_mobile.png'

export function ProximoSatisfactionSurvey() {
  return (
    <Flex h={{ sm: '716px' }} w='100%' align='center' justify='center' position='relative' overflow='hidden'>
      <Link to={'https://forms.gle/3DYBbDQveFiTBMrq7'} target={'_blank'} style={{ width: '100%' }}>
        <Flex display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} w={'100%'} h={'716px'}>
          <Image src={satisfactionImageDesktop} objectFit='cover' objectPosition={'left top'} w={'100%'} />
        </Flex>

        <Flex display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }} w={'100%'} h={'716px'}>
          <Image src={satisfactionImageMobile} objectFit='cover' objectPosition={'left top'} w={'100%'} />
        </Flex>
      </Link>
    </Flex>
  )
}
