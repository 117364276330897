import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import google from '../../../../../../assets/google_play_black.svg'
import apple from '../../../../../../assets/apple_black.svg'
import huawei from '../../../../../../assets/huawei_black.svg'
import appStore from '../../../../../../assets/app_store.svg'
import playStore from '../../../../../../assets/google_play.svg'
import appGallery from '../../../../../../assets/app_gallery.svg'
import { Link } from 'react-router-dom'

function PickPromoBanner({ mobile }) {
  const { t } = useTranslation()

  if (mobile) {
    return (
      <Flex display={{ sm: 'none', lg: 'flex' }} direction='column' w='100%' bg='#F2EEFA' border='1px solid #7537FA' borderRadius='8px' padding='24px' gap='32px'>
        <Flex direction='column' gap='8px' align='center' justify='center'>
          <Text fontSize='24px' fontWeight='700' color='#7537FA' textAlign='center'>
            {t('ticket_fares.buy_tickets_travel_card_passes_pick_hub')}
          </Text>
          <Text fontSize='16px' fontWeight='600' color='#7537FA' textAlign='center'>
            {t('home.pick_subtitle')}
          </Text>
        </Flex>
        <Flex direction='column' gap='16px' align='center'>
          <Text fontSize='14px' fontWeight='700' color='#282828'>
            {t('home.download_now')}
          </Text>
          <Flex gap='16px'>
            <Link to={'https://apps.apple.com/pt/app/pick-hub/id1343817323'} target={'_blank'}>
              <Image src={appStore} />
            </Link>
            <Link to={'https://play.google.com/store/apps/details?id=ubirider.ubirider'} target={'_blank'}>
              <Image src={playStore} />
            </Link>
            <Link to={'https://appgallery.huawei.com/app/C102997355'} target={'_blank'}>
              <Image src={appGallery} />
            </Link>
          </Flex>
        </Flex>
      </Flex>
    )
  } else {
    return (
      <Flex
        display={{ sm: 'flex', lg: 'none' }}
        direction='column'
        w='100%'
        bg='#F2EEFA'
        border='1px solid #7537FA'
        borderRadius='8px'
        padding='24px'
        gap='32px'
        align='center'
        justify='center'
      >
        <Flex direction='column' gap='8px' align='center' justify='center'>
          <Text fontSize='24px' fontWeight='700' color='#7537FA' textAlign='center'>
            {t('ticket_fares.buy_tickets_travel_card_passes_pick_hub')}
          </Text>
          <Text fontSize='16px' fontWeight='600' color='#7537FA' textAlign='center'>
            {t('home.pick_subtitle')}
          </Text>
        </Flex>

        <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} direction='column' gap='16px' align='center' justify='center' zIndex='5'>
          <Text fontSize='18px' fontWeight='700' color='#282828'>
            {t('home.download_now')}
          </Text>
          <Flex direction='row' gap='8px' display={{ sm: 'flex', lg: 'none' }}>
            <Flex
              align='center'
              justify='center'
              w='78px'
              h='48px'
              bg='white'
              border='1px solid #D0D5DD'
              borderRadius='4px'
              as='button'
              onClick={() => window.open('https://play.google.com/store/apps/details?id=ubirider.ubirider', '_blank')}
            >
              <Image src={google} />
            </Flex>
            <Flex
              align='center'
              justify='center'
              w='78px'
              h='48px'
              bg='white'
              border='1px solid #D0D5DD'
              borderRadius='4px'
              as='button'
              onClick={() => window.open('https://apps.apple.com/pt/app/pick-hub/id1343817323', '_blank')}
            >
              <Image src={apple} />
            </Flex>
            <Flex
              align='center'
              justify='center'
              w='78px'
              h='48px'
              bg='white'
              border='1px solid #D0D5DD'
              borderRadius='4px'
              as='button'
              onClick={() => window.open('https://appgallery.huawei.com/app/C102997355', '_blank')}
            >
              <Image src={huawei} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default PickPromoBanner
