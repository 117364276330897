import { Box, Divider, Flex, Text, Image, Link } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { FormItem, PhoneNumberInput, TextInput, validatePhoneNumber, Button, FileInput, Checkbox } from '@ubirider/pick-component-library'
import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { sendEmail } from '../../api/requests'
import { customError } from '../../helpers/log'

import asterisk from '../../assets/asterisk_red.svg'
import { useCustomToast } from '../../hooks/useToast'

function Recruitment() {
  const { t } = useTranslation()

  const showCustomToast = useCustomToast()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: null,
      email: null,
      phone: null,
      curriculum: null,
      presentation_letter: null,
      checked_permissions: null,
    },
  })

  const onSubmit = async data => {
    setLoading(true)
    try {
      await sendEmail('recruitment', data)
      reset({
        name: null,
        email: null,
        phone: null,
        curriculum: null,
        presentation_letter: null,
        checked_permissions: null,
      })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      showCustomToast('success', t('support.message_sent_toaster'))
    } catch (error) {
      customError(error)
      showCustomToast('success', t('support.message_failed_toaster'))
    }
    setLoading(false)
  }

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        align='center'
        pt={{ sm: '32px', md: '32px', lg: '100px', xl: '100px' }}
        pb={{ sm: '32px', md: '32px', lg: '100px', xl: '100px' }}
        as='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex direction='column' gap='24px' justify='center' align='center' padding={{ sm: '0 24px', md: '0 24px', lg: '0 130px', xl: '0 230px' }}>
          <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20' textAlign='center'>
            {t('recruitment.recruitment')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828' textAlign='center'>
            {t('recruitment.recruitment_description')}
          </Text>
        </Flex>

        <Flex direction='column' gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }} padding={{ sm: '0 24px', md: '0 24px', lg: '0 130px', xl: '0 230px' }} width='100%'>
          <Flex direction='column' gap='24px'>
            <Controller
              name='name'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.name')} error={errors.name}>
                  <TextInput {...field} background={'white'} />
                </FormItem>
              )}
            />

            <Controller
              name='email'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.email')} error={errors.email}>
                  <TextInput {...field} background={'white'} />
                </FormItem>
              )}
            />

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}>
              <Controller
                name='phone'
                control={control}
                rules={{
                  required: t('authentication.required_field'),
                  validate: { phone_number: v => (v !== null ? validatePhoneNumber(v) : true) || t('authentication.invalid_phone') },
                }}
                render={({ field }) => (
                  <FormItem required label={t('common.phone')} error={errors.phone}>
                    <PhoneNumberInput {...field} background={'white'} />
                  </FormItem>
                )}
              />

              <Box display={{ sm: 'none', lg: 'flex' }} w='100%' />
            </Flex>

            <Divider border='1px solid #98A2B366' />

            <Controller
              css={{ width: '100%' }}
              name='curriculum'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.curriculum')} error={errors.curriculum}>
                  <FileInput {...field} maxSize={2} expanded={false} multiple={false} allowedExtensions={['pdf', 'png', 'jpeg', 'jpg', 'doc', 'txt']} />
                </FormItem>
              )}
            />

            <Controller
              css={{ width: '100%' }}
              name='presentation_letter'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.presentation_letter')} error={errors.presentation_letter}>
                  <FileInput {...field} maxSize={2} expanded={false} multiple={false} allowedExtensions={['pdf', 'png', 'jpeg', 'jpg', 'doc']} />
                </FormItem>
              )}
            />
          </Flex>

          <Controller
            name='checked_permissions'
            control={control}
            rules={{ required: t('authentication.required_field') }}
            render={({ field: { value, onChange } }) => (
              <FormItem required error={errors.checked_permissions}>
                <Flex direction='column' gap='24px'>
                  <Text fontSize='14px' color='#475467'>
                    <Trans i18nKey='recruitment.data_collected_recruitment_1'>
                      The data collected is intended to be included in our pool of candidates. To find out more about our Privacy Policy click
                      <Link className='chakra-custom-link' sx={{ fontWeight: '600', textDecoration: 'underline' }} href='/privacy-policy' target='_blank'>
                        <Text textDecoration='underline' fontWeight='600'>
                          here
                        </Text>
                      </Link>
                      .
                    </Trans>
                  </Text>
                  <Flex gap='4px'>
                    <Image src={asterisk} />
                    <Text fontSize='14px' color='#475467'>
                      {t('recruitment.data_collected_recruitment_2')}
                    </Text>
                  </Flex>
                  <Flex gap='48px'>
                    <Flex gap='16px'>
                      <Checkbox
                        checked={value === true}
                        onChange={() => {
                          onChange(value === true ? null : true)
                        }}
                      />
                      <Text fontSize='14px' color='#282828'>
                        {t('common.yes')}
                      </Text>
                    </Flex>
                    <Flex gap='16px'>
                      <Checkbox
                        checked={value === false}
                        onChange={() => {
                          onChange(value === false ? null : false)
                        }}
                      />
                      <Text fontSize='14px' color='#282828'>
                        {t('common.no')}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </FormItem>
            )}
          />
        </Flex>

        <Flex display={{ sm: 'none', lg: 'flex' }} justify='flex-end' align='flex-end' width='100%' padding={{ sm: '0 24px', md: '0 24px', lg: '0 230px', xl: '0 230px' }}>
          <Button submit loading={loading} style='solid' type='primary' label={t('common.submit')} css={{ width: '152px' }} />
        </Flex>
        <Flex display={{ sm: 'flex', lg: 'none' }} justify='flex-end' align='flex-end' width='100%' padding={{ sm: '0 24px', md: '0 24px', lg: '0 230px', xl: '0 230px' }}>
          <Button submit loading={loading} style='solid' type='primary' label={t('common.submit')} css={{ width: '100%' }} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Recruitment
