import React from 'react'
import { Map } from '@ubirider/pick-component-library'
import { RouteLine } from './components/RouteLine'
import { StopMarker } from './components/StopMarker'
import { RealTimeBusMarker } from './components/RealTimeBusMarker'

function MapArea({ allBounds, bounds, network, selectedNetwork, selectedRoute, selectedStop, selectedPattern, stopSequence, hoveredRoute, hoveredStop }) {
  return (
    <Map
      startZoom={10}
      bounds={selectedNetwork ? bounds : allBounds}
      css={{ width: '100%', border: 'unset !important', borderRadius: 'unset !important' }}
      hideGoogleFooter={true}
      scrollToZoom={true}
      allowFullscreen={false}
    >
      {!selectedNetwork &&
        network?.routes?.map((route, i) => (
          <RouteLine
            focus={false}
            key={i}
            network={route}
            selectedRoute={selectedRoute}
            selectedStop={selectedStop}
            selectedPattern={selectedPattern}
            hoveredRoute={hoveredRoute}
          />
        ))}

      {selectedNetwork && (
        <RouteLine
          focus={true}
          network={selectedNetwork}
          allNetwork={network}
          selectedRoute={selectedRoute}
          selectedPattern={selectedPattern}
          selectedStop={selectedStop}
          hoveredRoute={hoveredRoute}
        />
      )}

      {!selectedNetwork && hoveredStop && (
        <StopMarker focus={false} network={network} selectedPattern={selectedPattern} stopSequence={network.stops} hoveredRoute={hoveredRoute} hoveredStop={hoveredStop} />
      )}

      {selectedNetwork && (
        <StopMarker
          focus={true}
          allNetwork={network}
          network={selectedNetwork}
          alerts={selectedNetwork?.alerts}
          selectedPattern={selectedPattern}
          selectedStop={selectedStop}
          stopSequence={stopSequence}
          hoveredStop={hoveredStop}
          hoveredRoute={hoveredRoute}
        />
      )}

      {selectedNetwork?.real_time?.length > 0 && (
        <RealTimeBusMarker network={selectedNetwork} selectedPattern={selectedPattern} selectedStop={selectedStop} hoveredStop={hoveredStop} />
      )}
    </Map>
  )
}

export default MapArea
