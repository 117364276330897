import { Flex, Text, Image, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'
import { AlertCauses, AlertLevelMap } from '../../../../../consts/alerts'
import { RouteLabel, Tag, TransportIcon } from '@ubirider/pick-component-library'
import { AlertModal } from '../../../../../modals/AlertModal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import dangerIcon from '../../../../../assets/alert_red_full.svg'
import infoIcon from '../../../../../assets/alert_blue_full.svg'
import alertIcon from '../../../../../assets/alert_yellow_full.svg'
import stopSign from '../../../../../assets/stop_sign.svg'

function AlertCard(props) {
  const { t } = useTranslation()
  const alertLevel = AlertLevelMap[props.alert.effect]

  const cardHeight = props.hasDescription ? { sm: '224px', md: '294px', lg: '294px', xl: '304px' } : { sm: '224px', md: '224px', lg: '224px', xl: '254px' }

  const [maxTags, setMaxTags] = useState({ sm: 0, lg: 0, xl: 0 })

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (props.alert?.affectedRoutes) {
      const numberNullShortNames = props.alert.affectedRoutes.filter(route => props.network.routes[route?.routeID - 1].shortName === '').length
      setMaxTags(numberNullShortNames === 0 ? { sm: 4, lg: 5, xl: 6 } : numberNullShortNames === 1 ? { sm: 2, lg: 3, xl: 4 } : { sm: 1, lg: 1, xl: 1 })
    }
  }, [props.alert])

  return (
    <Flex as='button' onClick={onOpen}>
      <Flex
        direction='column'
        w={props?.width ? props.width : { sm: '300px', md: '300px', lg: '320px', xl: '368px' }}
        h={props?.height ? undefined : cardHeight}
        gap='16px'
        padding='16px'
        border='1px solid #D0D5DD'
        bg='white'
        boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.1)'
        borderRadius='16px'
        justify='space-between'
      >
        <Flex direction='column' gap='16px'>
          <Flex gap='16px' align='center'>
            <Image src={alertLevel === 0 ? infoIcon : alertLevel === 1 ? alertIcon : alertLevel === 2 ? dangerIcon : null} />
            <Text fontSize='16px' fontWeight='700' color='#1D2939' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
              {props.alert.header}
            </Text>
          </Flex>
          <Flex gap='16px' align='center'>
            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
              {t('home.cause')}
            </Text>
            <Tag label={t(`home.${AlertCauses[props.alert.cause]}`)} />
          </Flex>
          {props.hasDescription && (
            <Flex display={{ sm: 'none', md: 'flex' }}>
              <div className='multiline-ellipsis-text'>{props.alert.description}</div>
            </Flex>
          )}
          <Flex direction='column' gap='8px' align='flex-start'>
            {props.alert?.affectedRoutes && (
              <>
                <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                  {t('network_schedule.routes')}
                </Text>
                <Flex direction='column' gap='16px' align='flex-start' justify='end' width='100%'>
                  <Wrap spacing={2} display={{ sm: 'none', xl: 'flex' }} align='center' whiteSpace='nowrap' overflow='hidden' textOverflow="'(More)'">
                    {props.alert?.affectedRoutes?.slice(0, maxTags.xl).map((route, i) => (
                      <WrapItem key={i} align='center'>
                        <RouteLabel
                          shortName={props.network.routes[route?.routeID - 1].shortName}
                          color={props.network.routes.find(obj => obj.id === route.routeID)?.color || '#1D2939'}
                          textColor={props.network.routes.find(obj => obj.id === route.routeID)?.textColor || 'white'}
                        />
                        {props.network.routes[route?.routeID - 1].shortName === '' && (
                          <Flex
                            padding='1px 8px 1.5px 12px'
                            ml='-4px'
                            borderTop='1px solid rgba(71, 84, 103, 0.40)'
                            borderRight='1px solid rgba(71, 84, 103, 0.40)'
                            borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                            rounded='0 4px 4px 0'
                            align='center'
                          >
                            <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                              {props.network.routes[route?.routeID - 1].name.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                            </Text>
                          </Flex>
                        )}
                      </WrapItem>
                    ))}
                    {props.alert?.affectedRoutes?.length - maxTags.xl > 0 && (
                      <Text fontSize='14px' fontWeight='600' color='#001D20'>
                        +{props.alert?.affectedRoutes?.length - maxTags.xl} ...
                      </Text>
                    )}
                  </Wrap>
                  <Wrap spacing={2} display={{ sm: 'none', lg: 'flex', xl: 'none' }} align='center'>
                    {props.alert?.affectedRoutes?.slice(0, maxTags.lg).map((route, i) => (
                      <WrapItem key={i}>
                        <RouteLabel
                          shortName={props.network.routes[route?.routeID - 1].shortName}
                          color={props.network.routes.find(obj => obj.id === route.routeID)?.color || '#1D2939'}
                          textColor={props.network.routes.find(obj => obj.id === route.routeID)?.textColor || 'white'}
                        />
                        {props.network.routes[route?.routeID - 1].shortName === '' && (
                          <Flex
                            padding='1px 8px 1.5px 12px'
                            ml='-4px'
                            borderTop='1px solid rgba(71, 84, 103, 0.40)'
                            borderRight='1px solid rgba(71, 84, 103, 0.40)'
                            borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                            rounded='0 4px 4px 0'
                            align='center'
                          >
                            <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                              {props.network.routes[route?.routeID - 1].name.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                            </Text>
                          </Flex>
                        )}
                      </WrapItem>
                    ))}
                    {props.alert?.affectedRoutes?.length - maxTags.lg > 0 && (
                      <Text fontSize='13px' fontWeight='600' color='#001D20'>
                        +{props.alert?.affectedRoutes?.length - maxTags.lg} ...
                      </Text>
                    )}
                  </Wrap>
                  <Wrap spacing={2} display={{ sm: 'flex', lg: 'none' }} align='center'>
                    {props.alert?.affectedRoutes?.slice(0, maxTags.sm).map((route, i) => (
                      <WrapItem key={i}>
                        <RouteLabel
                          shortName={props.network.routes[route?.routeID - 1].shortName}
                          color={props.network.routes.find(obj => obj.id === route.routeID)?.color || '#1D2939'}
                          textColor={props.network.routes.find(obj => obj.id === route.routeID)?.textColor || 'white'}
                        />
                        {props.network.routes[route?.routeID - 1].shortName === '' && (
                          <Flex
                            padding='1px 8px 1.5px 12px'
                            ml='-4px'
                            borderTop='1px solid rgba(71, 84, 103, 0.40)'
                            borderRight='1px solid rgba(71, 84, 103, 0.40)'
                            borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                            rounded='0 4px 4px 0'
                            align='center'
                          >
                            <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                              {props.network.routes[route?.routeID - 1].name.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                            </Text>
                          </Flex>
                        )}
                      </WrapItem>
                    ))}
                    {props.alert?.affectedRoutes?.length - maxTags.sm > 0 && (
                      <Text fontSize='13px' fontWeight='600' color='#001D20'>
                        +{props.alert?.affectedRoutes?.length - maxTags.sm} ...
                      </Text>
                    )}
                  </Wrap>
                </Flex>
              </>
            )}
            {props.alert?.affectedStops && (
              <>
                <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                  {t('network_schedule.stops')}
                </Text>
                <Flex direction='column' gap='16px' align='flex-start' justify='end' width='100%'>
                  <Wrap spacing={2} display={{ sm: 'none', xl: 'flex' }} align='center' whiteSpace='nowrap' overflow='hidden' textOverflow="'(More)'">
                    {props.alert?.affectedStops.slice(0, 2).map((stop, i) => (
                      <WrapItem key={i} align='center'>
                        <Flex
                          padding='0 8px'
                          borderTop='1px solid rgba(71, 84, 103, 0.40)'
                          borderLeft='1px solid rgba(71, 84, 103, 0.40)'
                          borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                          rounded='4px 0 0 4px'
                          mr='4px'
                          align='center'
                        >
                          <Image src={stopSign} h='22px' />
                        </Flex>
                        <Flex
                          padding='1px 8px 1.5px 12px'
                          ml='-4px'
                          border='1px solid rgba(71, 84, 103, 0.40)'
                          rounded='0 4px 4px 0'
                          align='center'
                          width='100px'
                          overflow='hidden'
                          whiteSpace='nowrap'
                          textOverflow='ellipsis'
                        >
                          <Text fontSize='13px' fontWeight='600' color='#1D2939' w='100px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
                            {props.network.stops
                              .find(networkStop => networkStop.id === stop.stopID)
                              .name.toLowerCase()
                              .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                          </Text>
                        </Flex>
                      </WrapItem>
                    ))}
                    {props.alert?.affectedStops?.length - 2 > 0 && (
                      <Text fontSize='14px' fontWeight='600' color='#001D20'>
                        +{props.alert?.affectedStops?.length - 2} ...
                      </Text>
                    )}
                  </Wrap>
                  <Wrap spacing={2} display={{ sm: 'none', lg: 'flex', xl: 'none' }} align='center'>
                    {props.alert?.affectedStops?.slice(0, 2).map((stop, i) => (
                      <WrapItem key={i} align='center'>
                        <Flex
                          padding='0 8px'
                          borderTop='1px solid rgba(71, 84, 103, 0.40)'
                          borderLeft='1px solid rgba(71, 84, 103, 0.40)'
                          borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                          rounded='4px 0 0 4px'
                          mr='4px'
                          align='center'
                        >
                          <Image src={stopSign} h='22px' />
                        </Flex>
                        <Flex
                          padding='1px 8px 1.5px 12px'
                          ml='-4px'
                          border='1px solid rgba(71, 84, 103, 0.40)'
                          rounded='0 4px 4px 0'
                          align='center'
                          width='78px'
                          overflow='hidden'
                          whiteSpace='nowrap'
                          textOverflow='ellipsis'
                        >
                          <Text fontSize='13px' fontWeight='600' color='#1D2939' w='78px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
                            {props.network.stops
                              .find(networkStop => networkStop.id === stop.stopID)
                              .name.toLowerCase()
                              .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                          </Text>
                        </Flex>
                      </WrapItem>
                    ))}
                    {props.alert?.affectedStops?.length - 2 > 0 && (
                      <Text fontSize='13px' fontWeight='600' color='#001D20'>
                        +{props.alert?.affectedStops?.length - 2} ...
                      </Text>
                    )}
                  </Wrap>
                  <Wrap spacing={2} display={{ sm: 'flex', lg: 'none' }} align='center'>
                    {props.alert?.affectedStops?.slice(0, 1).map((stop, i) => (
                      <WrapItem key={i} align='center'>
                        <Flex
                          padding='0 8px'
                          borderTop='1px solid rgba(71, 84, 103, 0.40)'
                          borderLeft='1px solid rgba(71, 84, 103, 0.40)'
                          borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                          rounded='4px 0 0 4px'
                          mr='4px'
                          align='center'
                        >
                          <Image src={stopSign} h='22px' />
                        </Flex>
                        <Flex
                          padding='1px 8px 1.5px 12px'
                          ml='-4px'
                          border='1px solid rgba(71, 84, 103, 0.40)'
                          rounded='0 4px 4px 0'
                          align='center'
                          width='156px'
                          overflow='hidden'
                          whiteSpace='nowrap'
                          textOverflow='ellipsis'
                        >
                          <Text fontSize='13px' fontWeight='600' color='#1D2939' w='156px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
                            {props.network.stops
                              .find(networkStop => networkStop.id === stop.stopID)
                              .name.toLowerCase()
                              .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                          </Text>
                        </Flex>
                      </WrapItem>
                    ))}
                    {props.alert?.affectedStops?.length - 1 > 0 && (
                      <Text fontSize='13px' fontWeight='600' color='#001D20'>
                        +{props.alert?.affectedStops?.length - 1} ...
                      </Text>
                    )}
                  </Wrap>
                </Flex>
              </>
            )}
            {props.alert?.affectedTransports && (
              <>
                <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                  {t('network_schedule.transport_type')}
                </Text>
                <Wrap spacing={2} align='center'>
                  {props.alert?.affectedTransports?.map((transport, i) => (
                    <WrapItem key={i} align='center'>
                      <Flex border='1.5px solid #1D2939' rounded='4px' padding='2px'>
                        <TransportIcon type={transport} />
                      </Flex>
                    </WrapItem>
                  ))}
                </Wrap>
              </>
            )}
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Flex gap='8px' align='center'>
            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
              {t('home.effective_from')}
            </Text>
            <Text fontSize='13px' color='#1D2939'>
              {new Date(props.alert?.startDate).toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
              })}
            </Text>
          </Flex>
          {props.alert?.endDate && (
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                {t('network_schedule.to')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {new Date(props.alert?.endDate).toLocaleString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false,
                })}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      <AlertModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} alert={props.alert} allNetwork={props.network} type={1} />
    </Flex>
  )
}

export default AlertCard
