import trevoConfig from './trevo.config'
import proximoConfig from './proximo.config'
import urbanasConfig from './urbanas.config'
import mobiazoresConfig from './mobiazores.config'

const chosenWebsite = process.env.REACT_APP_WEBSITE

let config
if (chosenWebsite === 'trevo') {
  config = trevoConfig
} else if (chosenWebsite === 'proximo') {
  config = proximoConfig
} else if (chosenWebsite === 'urbanas') {
  config = urbanasConfig
} else if (chosenWebsite === 'mobiazores') {
  config = mobiazoresConfig
} else {
  config = trevoConfig
}

export default config
