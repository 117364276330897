import { Flex, Text, Image, UnorderedList, ListItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import config from '../../config/configLoader'

import bgDesktop from '../../assets/promotion_bg_desktop.png'
import bgMobile from '../../assets/promotion_bg_mobile.png'
import monitor from '../../assets/promotion_monitors_bg.svg'
import monitorWide from '../../assets/promotion_monitors_bg_wide.svg'
import mupis from '../../assets/promotion_mupis_bg.svg'
import windshield from '../../assets/promotion_windshield_bg.svg'

export const Promotion = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (config.operator === 'trevo') {
    return (
      <Flex direction='column' flex='1'>
        <Flex display={{ sm: 'flex', lg: 'none' }}>
          <Image src={bgMobile} w={'100%'} />
        </Flex>
        <Flex
          direction='column'
          backgroundImage={{ lg: `url(${bgDesktop})` }}
          backgroundPosition={'center center'}
          backgroundSize={'cover'}
          backgroundRepeat={'no-repeat'}
          w='100%'
          h={{ lg: '427px' }}
        >
          <Flex
            direction='column'
            gap={{ sm: '32px', lg: '24px' }}
            padding={{ sm: '48px 24px', md: '48px 24px', lg: '0 130px', xl: '0 130px' }}
            maxW={{ lg: '60%' }}
            h='100%'
            w='100%'
            justify='center'
          >
            <Text color='white' fontSize={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }} fontWeight='700' textAlign={{ sm: 'center', lg: 'left' }}>
              {t('promotion.title')}
            </Text>
            <Text color='white' fontSize={{ sm: '14px', md: '14px', lg: '24px', xl: '24px' }} fontWeight={{ sm: '600', lg: '400' }} textAlign={{ sm: 'center', lg: 'left' }}>
              {t('promotion.tagline')}
            </Text>
          </Flex>
        </Flex>

        <Flex
          direction='column'
          flex='1'
          bg={{ sm: 'white', lg: '#F9F9FB' }}
          gap={{ sm: '56px', md: '56px', lg: '80px', xl: '80px' }}
          padding={{ sm: '32px 24px', md: '32px 24px', lg: '90px 70px', xl: '90px 130px' }}
          align='flex-start'
          justify='center'
        >
          <Text color='#101828' fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '18px' }} textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
            {t('promotion.format_description')}
          </Text>
          <Flex
            direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            flex='1'
            align='center'
            justify={{ sm: 'center', md: 'center', lg: 'flex-end', xl: 'flex-end' }}
            gap={{ sm: '56px', md: '56px', lg: '80px', xl: '80px' }}
          >
            <Flex
              direction='column'
              align={{ sm: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }}
              justify='center'
              width={{ xl: '80%' }}
              gap={{ sm: '24px', md: '24px', lg: '24px', xl: '32px' }}
            >
              <Text color='#001D20' fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700'>
                {t('promotion.vehicle_monitors')}
              </Text>
              <Image display={{ sm: 'flex', lg: 'none' }} align='center' justify='center' src={monitor} borderRadius='8px' />
              <Flex direction='column' gap={{ sm: '24px', md: '24px', lg: '32px', xl: '32px' }} align='flex-start'>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.exposure')}
                  </Text>
                  <Flex direction='column'>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.exposure_monitors_frequency')}
                    </Text>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.exposure_monitors_duration')}
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.benefits')}
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.speed_of_insertion')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.minimum_cost_production')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.availability_in_all_vehicles')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.ideal_seasonal_campaigns')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.ideal_institutional_campaigns_brand_promo')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.target_audience_urban_road_transport_users')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.pensioners')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.students')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.active_population')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.average_passengers_month')}
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </Flex>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.production')}
                  </Text>
                  <Flex direction='column'>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.production_monitors_image')}
                    </Text>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.production_monitors_dimensions')}
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.implementation_costs')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.implementation_costs_monitors')}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Image display={{ sm: 'none', lg: 'flex' }} src={monitorWide} h={{ lg: '592px', xl: '692px' }} />
          </Flex>
        </Flex>

        <Flex
          flex='1'
          bg='white'
          gap={{ sm: '56px', md: '56px', lg: '56px', xl: '120px' }}
          padding={{ sm: '32px 24px', md: '32px 24px', lg: '90px 70px', xl: '90px 130px' }}
          align='center'
          justify='flex-start'
        >
          <Image display={{ sm: 'none', lg: 'flex' }} src={mupis} h={{ lg: '592px', xl: '692px' }} />
          <Flex direction='column' align={{ sm: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }} justify='center' gap='32px'>
            <Flex direction='column' gap='8px' justify='center' align={{ sm: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }}>
              <Text color='#475467' fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700'>
                {t('promotion.mupis')}
              </Text>
              <Text color='#1D2939' fontSize='14px' fontWeight='600'>
                {t('promotion.mupis_description')}
              </Text>
            </Flex>
            <Image display={{ sm: 'flex', lg: 'none' }} src={mupis} borderRadius='8px' />
            <Flex direction='column' align='flex-start' gap={{ sm: '24px', md: '24px', lg: '32px', xl: '32px' }}>
              <Flex direction='column' gap='8px' align='flex-start'>
                <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                  {t('promotion.exposure')}
                </Text>
                <Flex direction='column'>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.exposure_mupis_days')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.exposure_mupis_dimensions')}
                  </Text>
                </Flex>
              </Flex>
              <Flex direction='column' gap='8px' align='flex-start'>
                <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                  {t('promotion.benefits')}
                </Text>
                <UnorderedList>
                  <ListItem>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.high_impact_visibility')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.premium_locations')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.ideal_seasonal_campaigns')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.ideal_institutional_campaigns_brand_promo')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.ideal_consumer_products_services')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text color='#282828' fontSize='14px' fontWeight='600'>
                      {t('promotion.target_audience_population_visitors')}
                    </Text>
                  </ListItem>
                </UnorderedList>
              </Flex>
              <Flex direction='column' gap='8px' align='flex-start'>
                <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                  {t('promotion.production')}
                </Text>
                <Flex direction='column'>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.production_monitors_image')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.production_mupis_dimensions')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.production_mupis_surface')}
                  </Text>
                </Flex>
              </Flex>
              <Flex direction='column' gap='8px' align='flex-start'>
                <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                  {t('promotion.implementation_costs')}
                </Text>
                <Text color='#282828' fontSize='14px' fontWeight='600'>
                  {t('promotion.implementation_costs_monitors')}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction='column'
          flex='1'
          bg={{ sm: 'white', lg: '#F9F9FB' }}
          gap={{ sm: '56px', md: '56px', lg: '56px', xl: '120px' }}
          padding={{ sm: '32px 24px', md: '32px 24px', lg: '90px 70px', xl: '90px 130px' }}
          align='flex-start'
          justify='center'
        >
          <Flex flex='1' align='center' justify={{ sm: 'center', md: 'center', lg: 'flex-end', xl: 'flex-end' }} gap={{ sm: '56px', xl: '118px' }}>
            <Flex direction='column' align={{ sm: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }} justify='center' width={{ xl: '80%' }} gap='32px'>
              <Text
                color='#001D20'
                fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
                fontWeight='700'
                textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}
              >
                {t('promotion.rear_windshield')}
              </Text>
              <Image display={{ sm: 'flex', lg: 'none' }} src={windshield} borderRadius='8px' />
              <Flex direction='column' gap={{ sm: '24px', md: '24px', lg: '32px', xl: '32px' }} align='flex-start'>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.exposure')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.duration_rear_windshield')}
                  </Text>
                </Flex>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.benefits')}
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.high_impact_visibility')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.continuous_passage_main_places')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.advertising_on_the_go')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.ideal_institutional_campaigns_brand_promo')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.ideal_consumer_products_services')}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text color='#282828' fontSize='14px' fontWeight='600'>
                        {t('promotion.target_audience_population_visitors')}
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </Flex>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.production')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.rear_windshield_dimensions')}
                  </Text>
                </Flex>
                <Flex direction='column' gap='8px' align='flex-start'>
                  <Text color='#00A5B5' fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='700'>
                    {t('promotion.implementation_costs')}
                  </Text>
                  <Text color='#282828' fontSize='14px' fontWeight='600'>
                    {t('promotion.implementation_costs_monitors')}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Image display={{ sm: 'none', lg: 'flex' }} src={windshield} h={{ lg: '592px', xl: '692px' }} />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  if (config.operator === 'proximo') {
    return (
      <Flex direction='column' flex='1'>
        <Flex display={{ sm: 'flex', lg: 'none' }}>
          <Image src={bgMobile} w={'100%'} />
        </Flex>
        <Flex
          direction='column'
          backgroundImage={{ lg: `url(${bgDesktop})` }}
          backgroundPosition={'center center'}
          backgroundSize={'cover'}
          backgroundRepeat={'no-repeat'}
          w='100%'
          h={{ lg: '427px' }}
        >
          <Flex
            direction='column'
            gap={{ sm: '32px', lg: '24px' }}
            padding={{ sm: '48px 24px', md: '48px 24px', lg: '0 130px', xl: '0 130px' }}
            maxW={{ lg: '60%' }}
            h='100%'
            w='100%'
            justify='center'
          >
            <Text color={{ sm: '#002239', lg: 'white' }} fontSize={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }} fontWeight='700' textAlign={{ sm: 'center', lg: 'left' }}>
              {t('promotion.title')}
            </Text>
            <Text
              color={{ sm: '#002239', lg: 'white' }}
              fontSize={{ sm: '14px', md: '14px', lg: '24px', xl: '24px' }}
              fontWeight={{ sm: '600', lg: '400' }}
              textAlign={{ sm: 'center', lg: 'left' }}
            >
              {t('promotion.tagline')}
            </Text>
          </Flex>
        </Flex>

        <Flex
          direction='column'
          flex='1'
          bg={{ sm: 'white', lg: '#F9F9FB' }}
          gap={{ sm: '56px', md: '56px', lg: '80px', xl: '80px' }}
          padding={{ sm: '32px 24px', md: '32px 24px', lg: '90px 70px', xl: '90px 130px' }}
          align='flex-start'
          justify='center'
        >
          <Text color='#101828' fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}>
            {t('promotion.format_description')}
          </Text>
        </Flex>
      </Flex>
    )
  }
}
