import { Column, Card, Text, Chip, Label, InlineNotification } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { ProfileDecision, ProfileStatus, ProfileStatusLabel, ProfileStatusType } from '../../../../consts/status'
import { Flex } from '@chakra-ui/react'

export const UserProfileInfo = ({ userProfile, showInline }) => {
  const { t } = useTranslation()

  const withinRenewalPeriod =
    userProfile.renewalDate && userProfile.expiryDate
      ? DateTime.now() >= DateTime.fromISO(userProfile.renewalDate, { zone: 'utc' }).toLocal() &&
        DateTime.now() <= DateTime.fromISO(userProfile.expiryDate, { zone: 'utc' }).toLocal()
      : false

  return (
    <Card css={{ padding: 24 }}>
      <Text type='textLG' weight='bold'>
        {userProfile.name}
      </Text>
      <Flex direction={{ sm: 'column', lg: 'row' }} gap={{ sm: '24px', lg: 0 }} justifyContent='space-between' marginTop='24px'>
        <Column css={{ gap: 24 }}>
          <Column css={{ gap: 4 }}>
            <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
              {t('ticket_fares.id_document_no')}
            </Text>
            <Text type='textMD' weight='semibold'>
              {userProfile.idDocument}
            </Text>
          </Column>
          {userProfile.status < 3 && (
            <Column css={{ gap: 4 }}>
              <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                {t('ticket_fares.current_profile')}
              </Text>
              <Chip boldLabel={userProfile.profileName} />
            </Column>
          )}
        </Column>

        <Column css={{ gap: 24 }}>
          <Column css={{ gap: 4 }}>
            <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
              {t('common.birthdate')}
            </Text>
            <Text type='textMD' weight='semibold'>
              {DateTime.fromISO(userProfile.birthdate).toFormat('dd/MM/yyyy')}
            </Text>
          </Column>
          {userProfile.status < ProfileStatus.DEACTIVATED && (
            <Column css={{ gap: 4 }}>
              <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                {userProfile.status === ProfileStatus.PENDING ? t('ticket_fares.request_date') : t('ticket_fares.validity')}
              </Text>
              <Text type='textMD' weight='semibold'>
                {userProfile.expiryDate
                  ? DateTime.fromISO(userProfile.expiryDate).toFormat('dd/MM/yyyy')
                  : userProfile.status === ProfileStatus.ACTIVE
                    ? t('ticket_fares.no_validity')
                    : DateTime.fromISO(userProfile.requestDate).toFormat('dd/MM/yyyy')}
              </Text>
            </Column>
          )}
        </Column>

        <Column css={{ gap: 24, justifyContent: 'flex-end', mt: userProfile.status === ProfileStatus.DEACTIVATED ? -24 : undefined }}>
          {userProfile.status < ProfileStatus.DEACTIVATED && (
            <Column css={{ gap: 4 }}>
              <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                {t('network_schedule.status')}
              </Text>
              <Label label={t(ProfileStatusLabel[userProfile.status])} type={ProfileStatusType[userProfile.status]} style={'outline'} />
            </Column>
          )}
        </Column>
      </Flex>

      {showInline && ((userProfile.status === ProfileStatus.ACTIVE && withinRenewalPeriod) || userProfile.status !== ProfileStatus.ACTIVE) && (
        <Flex mt='24px'>
          {userProfile.status === ProfileStatus.PENDING && <InlineNotification type={'warning'} message={t('ticket_fares.profile_pending_approval')} />}
          {userProfile.status === ProfileStatus.ACTIVE && withinRenewalPeriod && (
            <InlineNotification type={'neutralLight'} message={t('ticket_fares.profile_within_renewal_period')} />
          )}
          {userProfile.status === ProfileStatus.EXPIRED && <InlineNotification type={'warning'} message={t('ticket_fares.profile_has_expired_renew_or_change')} />}
          {userProfile.status === ProfileStatus.DEACTIVATED && userProfile?.decision === ProfileDecision.DECLINED && (
            <InlineNotification type={'warning'} message={t('ticket_fares.profile_request_declined')} />
          )}
        </Flex>
      )}
    </Card>
  )
}
