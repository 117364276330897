import { useTranslation } from 'react-i18next'
import { Flex, Text, Image } from '@chakra-ui/react'
import config from '../../../../config/configLoader'

import { Button } from '@ubirider/pick-component-library'
import { Link, useNavigate } from 'react-router-dom'

import bgDesktop from '../../../../assets/home_bg_desktop.png'
import bgMobile from '../../../../assets/home_bg_mobile.png'

export function MainHeader() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Flex
      direction='column'
      backgroundImage={{ lg: `url(${bgDesktop})` }}
      backgroundPosition={'center center'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      w='100%'
      h={{ lg: '427px' }}
    >
      <Flex display={{ sm: 'flex', lg: 'none' }}>
        <Image src={bgMobile} w={'100%'} />
      </Flex>
      <Flex
        direction='column'
        gap={{ sm: '32px', lg: '24px' }}
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '0 130px 0 60px', xl: '0 130px' }}
        maxW={{ md: '60%', xl: '60%' }}
        h='100%'
        w={'100%'}
        justifyContent='center'
        textAlign={{ sm: 'center', lg: 'left' }}
      >
        <Text color={{ sm: config.mainTitleColor.sm, lg: config.mainTitleColor.lg }} fontSize={{ sm: '36px', md: '36px', lg: '36px', xl: '36px' }} fontWeight='700'>
          {t('home.title')}
        </Text>
        <Text color={{ sm: config.mainTitleColor.sm, lg: config.mainTitleColor.lg }} fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }} fontWeight='600'>
          {t('home.subtitle')}
        </Text>
        <Flex display={{ sm: 'none', lg: 'flex' }} w='100%'>
          <Button
            linkComponent={<Link className='chakra-custom-link' to={'/network-and-schedules'} />}
            label={t('home.check_network_schedules')}
            type={config.name === 'Próximo' ? 'neutralLight' : 'primary'}
            style='solid'
          />
        </Flex>
        <Flex display={{ sm: 'flex', lg: 'none' }} w='100%'>
          <Button
            onClick={() => navigate('/network-and-schedules')}
            label={t('home.check_network_schedules')}
            type={config.name === 'Próximo' ? 'neutralLight' : 'primary'}
            style='solid'
            css={{ width: '100%' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
