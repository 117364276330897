import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Button, RouteLabel } from '@ubirider/pick-component-library'
import alertDefault from '../../../../../../assets/alert_red_full.svg'
import closeButton from '../../../../../../assets/cross.svg'
import busIcon from '../../../../../../assets/bus.svg'
import config from '../../../../../../config/configLoader'
import { ScheduleNumbers } from '../../../../../../consts/schedules'
import infoIcon from '../../../../../../assets/info_blue.svg'
import alertIcon from '../../../../../../assets/alert_yellow.svg'
import dangerIcon from '../../../../../../assets/alert_red.svg'
import arrowIcon from '../../../../../../assets/arrow_right_neutral.svg'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

function RouteCardHeader({
  network,
  selectedNetwork,
  alertValue,
  focus,
  selectedRoute,
  selectedStop,
  handleClickRoute,
  handleHoverRoute,
  name,
  loading,
  handleAlert,
  handleSchedules,
  closeDrawer,
}) {
  const { t } = useTranslation()

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const nAlerts = windowSize.current[0] >= 960 ? 3 : 2

  return (
    <>
      <Flex
        direction='column'
        mt={loading ? '-28px' : ''}
        gap='8px'
        w='100%'
        padding={selectedRoute ? { sm: '16px 0 0 0', lg: '16px 16px 0 16px' } : '16px'}
        as={selectedRoute ? undefined : 'button'}
        onClick={selectedRoute ? undefined : handleClickRoute}
        onMouseEnter={() => handleHoverRoute(focus ? selectedNetwork.route.id : network.id)}
        onMouseLeave={() => handleHoverRoute('')}
      >
        <Flex align='center' justify='space-between' w='100%'>
          <Flex gap='8px' align='center'>
            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
              {t('network_schedule.route')}
            </Text>
            <RouteLabel
              shortName={focus ? selectedNetwork.route.shortName : network.shortName}
              color={focus ? selectedNetwork.route.color : network.color}
              textColor={focus ? selectedNetwork.route.textColor : network.textColor}
            />
          </Flex>
          <Flex>{alertValue?.length > 0 && <Image src={alertDefault} />}</Flex>
          <Flex as='button' display={selectedRoute ? { sm: 'flex', lg: 'none' } : { sm: 'none', lg: 'none' }} align='flex-start' onClick={closeDrawer}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex gap='8px' align='flex-start' w={{ sm: 'calc(75vw)', md: 'calc(75vw)', lg: '100%', xl: '100%' }}>
          <Image src={busIcon} />
          <Text textAlign='left' fontSize='16px' fontWeight='700' color='#1D2939' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
            {name}
          </Text>
        </Flex>
      </Flex>

      {selectedRoute && !selectedStop && (
        <Flex direction='column' padding={selectedRoute ? { sm: '0', lg: '0 16px 0 16px' } : '0 16px 0 16px'} gap='16px' w='100%' align='center'>
          <Flex display={{ sm: 'none', lg: 'flex' }} gap='16px' align='center' w='100%'>
            {config.sections.schedules && (
              <a
                href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'none', flexBasis: 'calc(50% - 8px)' }}
              >
                <Button
                  disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                  type='neutralDark'
                  style='solid'
                  size='small'
                  label={t('network_schedule.route_schedules')}
                  css={{ width: '100%' }}
                />
              </a>
            )}

            <Button
              onClick={handleSchedules}
              type='neutralDark'
              style='outline'
              size='small'
              label={t('network_schedule.schedules')}
              css={{ flexBasis: 'calc(50% - 8px)', width: '100%' }}
            />
          </Flex>
          <Flex display={{ sm: 'flex', lg: 'none' }} direction='column' gap='8px' align='center' w='100%'>
            {config.sections.schedules && (
              <a
                href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <Button
                  disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                  type='neutralDark'
                  style='solid'
                  size='small'
                  label={t('network_schedule.route_schedules')}
                  css={{ width: '100%' }}
                />
              </a>
            )}

            <Button onClick={handleSchedules} type='neutralDark' style='outline' size='small' label={t('network_schedule.schedules')} css={{ width: '100%' }} />
          </Flex>

          {selectedNetwork.alerts.length > 0 && (
            <Flex direction='column' gap='8px' w='100%'>
              <Flex justify={'space-between'} align={'center'}>
                <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                  {t('home.notification_center_title')}
                </Text>

                <Button
                  linkComponent={<Link className='chakra-custom-link' to={'/notification-center'} />}
                  type='neutralDark'
                  style='typo'
                  size='small'
                  label={t('home.view_all')}
                />
              </Flex>

              {selectedNetwork.alerts.slice(0, nAlerts).map(alert => (
                <Flex
                  justify='space-between'
                  align='center'
                  bg='#F9F9FB'
                  w='100%'
                  borderRadius='4px'
                  padding='16px'
                  _hover={{ bg: '#eaecf0', transition: 'background-color 0.3s ease' }}
                  as='button'
                  onClick={() => handleAlert(alert)}
                >
                  <Flex direction='column' gap='4px' maxW={{ lg: '300px' }}>
                    <Flex gap='8px' align='center'>
                      <Image src={alert.alertLevel === 0 ? infoIcon : alert.alertLevel === 1 ? alertIcon : alert.alertLevel === 2 ? dangerIcon : null} w='20px' h='18px' />
                      <Text
                        fontSize='13px'
                        fontWeight='600'
                        color={alert.alertLevel === 0 ? '#444CE7' : alert.alertLevel === 1 ? '#FBC02D' : alert.alertLevel === 2 ? '#D23636' : null}
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                      >
                        {alert.header}
                      </Text>
                    </Flex>
                    <Flex>
                      <div className='multiline-ellipsis-text-route'>{alert.description}</div>
                    </Flex>
                  </Flex>
                  <Image src={arrowIcon} />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}

export default RouteCardHeader
