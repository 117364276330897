import { Column, Text } from '@ubirider/pick-component-library'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Flex, Image, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import config from '../../config/configLoader'
import { FindUserProfile } from './components/FindUserProfile'
import { ActionUserProfile } from './components/ActionUserProfile'
import { RenewUserProfile } from './components/RenewUserProfile'
import { ChangeUserProfile } from './components/ChangeUserProfile'
import closeButton from '../../assets/cross.svg'

export const ManageProfileModal = props => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [userProfile, setUserProfile] = useState(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true)
  const [isScrolledToTop, setIsScrolledToTop] = useState(true)

  const modalContentRef = useRef(null)

  const methods = useForm()

  useEffect(() => {
    setProgress(0)
    setIsScrolledToBottom(true)
    setIsScrolledToTop(true)
    methods.reset({ birthdate: null, idDocument: null })
  }, [props.isOpen])

  useEffect(() => {
    if (modalContentRef.current && props.isOpen) {
      modalContentRef.current.scrollTop = 0
    }
  }, [progress])

  const ModalTitle = {
    0: 'ticket_fares.request_profile',
    1: 'ticket_fares.your_profile',
    2: 'ticket_fares.renew_profile',
    3: 'ticket_fares.new_profile',
    4: 'ticket_fares.new_profile',
  }

  const ModalDescription = {
    0: 'ticket_fares.find_pass_profile',
    1: 'ticket_fares.current_pass_profile_details',
    2: 'ticket_fares.renew_profile_description',
    3: 'ticket_fares.change_profile_description',
    4: 'ticket_fares.profile_request_subtitle',
  }

  const handleScroll = event => {
    const element = event.target
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setIsScrolledToBottom(true)
    } else {
      setIsScrolledToBottom(false)
    }
    if (element.scrollTop === 0) setIsScrolledToTop(true)
    else {
      setIsScrolledToTop(false)
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={true}
      isCentered
      size={{ sm: 'full', lg: '' }}
    >
      <ModalOverlay />
      <ModalContent
        ref={modalContentRef}
        borderRadius={{ lg: '8px' }}
        border={{ lg: '1px solid ' + config.mainColor }}
        maxW={{ lg: '720px' }}
        maxH={{ lg: 'calc(100vh - 64px)' }}
        mt={{ lg: '32px' }}
        mb={{ lg: '32px' }}
        overflow={{ sm: 'auto', lg: 'none' }}
      >
        <FormProvider {...methods}>
          <Flex direction='column' w='100%' h={{ sm: 'calc(100vh - 87px)', lg: '100%' }} mt={{ sm: '87px', md: '87px', lg: '0px', xl: '0px' }}>
            <Flex
              padding={{ sm: '0 32px', lg: '32px 32px 12px 32px' }}
              zIndex='5'
              bg='white'
              gap='16px'
              align='flex-start'
              w='100%'
              borderRadius={{ lg: '8px 8px 0 0' }}
              boxShadow={isScrolledToTop ? 'none' : '0px 2px 20px 1px rgba(0, 0, 0, 0.1)'}
            >
              <Column css={{ gap: 16, width: '100%' }}>
                <Text type='displayXS' weight='bold'>
                  {t(ModalTitle[progress])}
                </Text>
                <Text type='textMD' css={{ color: '$neutral600' }}>
                  {t(ModalDescription[progress])}
                </Text>
              </Column>
              <Flex justify='flex-end' align='flex-end'>
                <Flex as='button' onClick={props.onClose}>
                  <Image src={closeButton} />
                </Flex>
              </Flex>
            </Flex>

            {progress === 0 && (
              <FindUserProfile
                prices={props?.prices}
                setLoading={setLoading}
                setProgress={setProgress}
                setUserProfile={setUserProfile}
                onClose={props.onClose}
                loading={loading}
                handleScroll={handleScroll}
              />
            )}

            {progress === 1 && (
              <ActionUserProfile
                userProfile={userProfile}
                handleRenewProfile={() => setProgress(2)}
                handleChangeProfile={() => setProgress(3)}
                handleNewProfile={() => setProgress(4)}
                handleScroll={handleScroll}
                onClose={props.onClose}
              />
            )}

            {progress === 2 && (
              <RenewUserProfile
                userProfile={userProfile}
                loading={loading}
                setLoading={setLoading}
                onClose={props.onClose}
                handleScroll={handleScroll}
                isScrolledToBottom={isScrolledToBottom}
              />
            )}

            {(progress === 3 || progress === 4) && (
              <ChangeUserProfile
                userProfile={userProfile}
                prices={props.prices}
                loading={loading}
                setLoading={setLoading}
                onClose={props.onClose}
                handleScroll={handleScroll}
                isScrolledToBottom={isScrolledToBottom}
              />
            )}
          </Flex>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}
