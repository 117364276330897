import { Button, DateInput, FormItem, InlineNotification, TextInput } from '@ubirider/pick-component-library'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { DateTime } from 'luxon'
import { Collapse, Flex } from '@chakra-ui/react'
import { getCustomerProfile } from '../../../../api/requests'

export const FindUserProfile = ({
  setLoading,
  setUserProfile,
  setProgress,
  prices,
  onClose,
  loading,
  handleScroll,
}) => {
  const { t } = useTranslation()

  const minDate = DateTime.fromISO('1900-01-01T00:00:00')
  const maxDate = DateTime.now()

  const [noUserProfile, setNoUserProfile] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const onSubmitFindProfile = async data => {
    setLoading(true)
    try {
      const userProfile = await getCustomerProfile(data.idDocument, data.birthdate)

      if (userProfile) {
        const requiredDocs =
          prices.find(ticket => ticket.profileID === userProfile.profiles[0].profileID)?.requiredDocuments ?? []
        userProfile.profiles[0] = { ...userProfile.profiles[0], requiredDocs }
        setUserProfile(userProfile)
        setProgress(1)
      } else {
        setNoUserProfile(true)
      }
    } catch (e) {
      console.log(e)
      setNoUserProfile(true)
    }
    setLoading(false)
  }

  return (
    <Flex
      as='form'
      onSubmit={handleSubmit(onSubmitFindProfile)}
      w='100%'
      direction='column'
      borderRadius={{ lg: '0 0 8px 8px' }}
      flex='1'
    >
      <Flex
        direction='column'
        gap='24px'
        align='center'
        padding={{ sm: '16px 32px 32px', lg: '0 32px 32px' }}
        bg='white'
        w='100%'
        h='100%'
        overflowY='none'
        onScroll={handleScroll}
      >
        <Controller
          name='idDocument'
          control={control}
          rules={{ required: t('authentication.required_field') }}
          render={({ field }) => (
            <FormItem required label={t('ticket_fares.id_document_no')} error={errors.idDocument}>
              <TextInput
                {...field}
                onChange={e => {
                  const value = e
                  if (value === null) {
                    field.onChange(null)
                  } else if (isNaN(parseInt(value.charAt(value.length - 1)))) {
                    field.onChange(field.value)
                  } else if (field.value === null || value?.length <= 9 || !value) {
                    field.onChange(value)
                  }
                }}
                placeholder={t('12345')}
              />
            </FormItem>
          )}
        />

        <Controller
          name='birthdate'
          control={control}
          rules={{ required: t('authentication.required_field') }}
          render={({ field }) => (
            <FormItem required label={t('common.birthdate')} error={errors.birthdate}>
              <DateInput {...field} minDate={minDate} maxDate={maxDate} />
            </FormItem>
          )}
        />

        <Collapse animateOpacity in={noUserProfile} width='100%'>
          <InlineNotification type={'danger'} message={t('ticket_fares.could_not_find_pass')} />
        </Collapse>
      </Flex>

      <Flex
        position='relative'
        align='flex-end'
        justify='flex-end'
        gap='20px'
        w='100%'
        padding='0 32px 32px'
        bg={'white'}
        borderRadius={{ lg: '0 0 8px 8px' }}
        transition='background-color 0.3s ease, box-shadow 0.3s ease'
      >
        <Button
          label={t('common.cancel')}
          type={'neutralLight'}
          style={'solid'}
          onClick={onClose}
          css={{ minWidth: 128 }}
        />
        <Button
          label={t('common.continue')}
          type={'primary'}
          style={'solid'}
          submit
          loading={loading}
          css={{ minWidth: 128 }}
        />
      </Flex>
    </Flex>
  )
}
