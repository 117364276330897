import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export function ScheduleTableHeader({ selectedStop, schedulesRoute, schedulesStop }) {
  const { t } = useTranslation()

  return (
    <>
      {!selectedStop && schedulesRoute.every(obj => obj?.schedule?.length > 0 || obj?.schedule !== undefined) && schedulesRoute.length > 0 && (
        <Flex display={{ sm: 'none', lg: 'flex' }} padding='0 24px' borderBottom='1px solid rgba(152, 162, 179, 0.20)' w='100%'>
          <Flex padding='8px 16px' mb='8px' w='268px'>
            <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
              {t('network_schedule.stops')}
            </Text>
          </Flex>
          <Flex padding='8px 16px' mb='8px' w={{ lg: '488px', xl: '708px' }}>
            <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
              {t('network_schedule.departures')}
            </Text>
          </Flex>
          <Flex padding='8px 16px' mb='8px' w='168px'>
            <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
              {t('network_schedule.connections')}
            </Text>
          </Flex>
        </Flex>
      )}

      {selectedStop && schedulesStop.every(obj => obj?.time?.length > 0 || obj?.time !== undefined) && schedulesStop.length > 0 && (
        <Flex display={{ sm: 'none', lg: 'flex' }} padding='0 24px' borderBottom='1px solid rgba(152, 162, 179, 0.20)' w='100%'>
          <Flex padding='8px 16px' mb='8px' w='268px'>
            <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
              {t('network_schedule.stops')}
            </Text>
          </Flex>
          <Flex padding='8px 16px' mb='8px' w={{ lg: '488px', xl: '708px' }}>
            <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
              {t('network_schedule.departures')}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  )
}
