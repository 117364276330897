import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

function PassRequestPolicy() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '100px 130px', xl: '100px 130px' }}
        width='100%'
      >
        <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20' align='center' justify='center'>
          {t('pass_request_policy.pass_request_policy_section_title')}
        </Text>

        <Flex
          w='100%'
          direction='column'
          gap='16px'
          padding={{ sm: '20px', md: '20px', lg: '40px', xl: '40px' }}
          align='flex-start'
          justify='center'
          bg='white'
          border='1px solid #D3D3D3'
          borderRadius='8px'
          flexWrap='wrap'
        >
          <Flex direction='column'>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('pass_request_policy.pass_request_policy_description_1')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('pass_request_policy.pass_request_policy_description_2')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('pass_request_policy.pass_request_policy_description_3')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('pass_request_policy.pass_request_policy_description_4')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('pass_request_policy.pass_request_policy_description_5')}
            </Text>
            <UnorderedList>
              <ListItem>
                <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
                  {t('pass_request_policy.pass_request_policy_description_6')}
                </Text>
              </ListItem>
              <ListItem>
                <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
                  {t('pass_request_policy.pass_request_policy_description_7')}
                </Text>
              </ListItem>
              <ListItem>
                <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
                  {t('pass_request_policy.pass_request_policy_description_8')}
                </Text>
              </ListItem>
            </UnorderedList>
          </Flex>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
            {t('pass_request_policy.pass_request_policy_description_9')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
            {t('pass_request_policy.pass_request_policy_description_10')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
            {t('pass_request_policy.pass_request_policy_description_11')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
            {t('pass_request_policy.pass_request_policy_description_12')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PassRequestPolicy
