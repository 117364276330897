import { Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormItem, PhoneNumberInput, TextInput, TransportIcon, validatePhoneNumber } from '@ubirider/pick-component-library'

import appStore from '../../assets/app_store.svg'
import playStore from '../../assets/google_play.svg'
import appGallery from '../../assets/app_gallery.svg'
import pinIcon from '../../assets/pin.svg'
import flagIcon from '../../assets/flag.svg'
import { Controller, useForm } from 'react-hook-form'

function PaymentDetails() {
  const { t } = useTranslation()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      promocode: null,
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [formStep, setStep] = useState(1)
  const [checked, setChecked] = useState(false)

  function validateTaxID(value) {
    return value?.length === 9 || !value
  }

  return (
    <Flex direction='column' align='center' padding='60px 130px' w='100%' gap='35px'>
      <Flex borderRadius='8px' gap='16px' justify='center' align='center' padding={{ sm: '0 0 16px 0', lg: '16px 48px' }} w='100%'>
        <Flex direction='column' gap='16px' align='center' justify='flex-start'>
          <Flex justify='center' align='center' padding='8px' w='32px' h='32px' bg={formStep === 1 ? '#8DC63F' : '#CACFD8'} borderRadius='20px'>
            <Text fontSize='20px' fontWeight='600' color={formStep === 1 ? '#1D2939' : '#667085'}>
              1
            </Text>
          </Flex>
          <Text fontSize='14px' fontWeight={formStep === 1 ? '700' : '400'} color='#282828' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
            {t('ticket_fares.enter_information')}
          </Text>
        </Flex>
        <Divider display={{ sm: 'none', lg: 'flex' }} maxW='318px' border='2px solid rgba(40, 40, 40, 0.25)' />
        <Flex direction='column' gap='16px' align='center' justify='center'>
          <Flex justify='center' align='center' padding='8px' w='32px' h='32px' bg={formStep === 2 ? '#8DC63F' : '#CACFD8'} borderRadius='20px'>
            <Text fontSize='20px' fontWeight='600' color={formStep === 2 ? '#1D2939' : '#667085'}>
              2
            </Text>
          </Flex>
          <Text fontSize='14px' fontWeight={formStep === 2 ? '700' : '400'} color='#282828' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
            {t('ticket_fares.select_pass_type')}
          </Text>
        </Flex>
        <Divider display={{ sm: 'none', lg: 'flex' }} maxW='318px' border='2px solid rgba(40, 40, 40, 0.25)' />
        <Flex direction='column' gap='16px' align='center' justify='center'>
          <Flex justify='center' align='center' padding='8px' w='32px' h='32px' bg={formStep === 3 ? '#8DC63F' : '#CACFD8'} borderRadius='20px'>
            <Text fontSize='20px' fontWeight='600' color={formStep === 3 ? '#1D2939' : '#667085'}>
              3
            </Text>
          </Flex>
          <Text fontSize='14px' fontWeight={formStep === 3 ? '700' : '400'} color='#282828' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
            {t('ticket_fares.confirm_information')}
          </Text>
        </Flex>
      </Flex>

      <Flex justify='space-between' gap='120px'>
        {formStep === 1 && (
          <Flex direction='column' gap='48px' w='580px'>
            <Flex direction='column' gap='8px' align='flex-start'>
              <Text fontSize='30px' fontWeight='700' color='#8DC63F'>
                ticket type
              </Text>
              <Text fontSize='14px' color='#282828'>
                tickets
              </Text>
            </Flex>
            <Flex gap='16px'></Flex>
            <Flex justify='flex-end'>
              <Button onClick={() => setStep(formStep + 1)} style='solid' type='neutralDark' label={t('common.next')} css={{ width: '160px' }} />
            </Flex>
            <Flex direction='column' gap='16px' padding='16px' w='100%' bg='#F2EEFA' border='1px solid #7537FA' borderRadius='8px'>
              <Flex direction='column' gap='8px'>
                <Text fontSize='20px' fontWeight='700' color='#7537FA'>
                  nuy
                </Text>
                <Text fontSize='18px' color='#7537FA'>
                  tickets
                </Text>
              </Flex>
              <Flex direction='column' gap='8px'>
                <Text fontSize='14px' fontWeight='700' color='#282828'>
                  download now
                </Text>
                <Flex gap='16px'>
                  <Image src={appStore} />
                  <Image src={playStore} />
                  <Image src={appGallery} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        {formStep === 2 && (
          <Flex direction='column' gap='32px' w='580px'>
            <Flex direction='column' gap='8px' align='flex-start'>
              <Text fontSize='30px' fontWeight='700' color='#8DC63F'>
                passenger info
              </Text>
              <Text fontSize='14px' color='#282828'>
                please fill this
              </Text>
            </Flex>
            <Flex direction='column' gap='16px'>
              <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap={{ sm: '16px', md: '16px', lg: '16px', xl: '16px' }}>
                <Controller
                  name='firstname'
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem required label={t('common.first_name')} error={errors.firstname}>
                      <TextInput placeholder={t('authentication.enter_first_name')} {...field} background={'white'} />
                    </FormItem>
                  )}
                />

                <Controller
                  name='lastname'
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem required label={t('common.last_name')} error={errors.lastname}>
                      <TextInput placeholder={t('authentication.enter_last_name')} {...field} background={'white'} />
                    </FormItem>
                  )}
                />
              </Flex>

              <Controller
                name='id_card'
                control={control}
                render={({ field }) => (
                  <FormItem required label={t('common.card_id')} error={errors.id_card}>
                    <TextInput placeholder={t('authentication.enter_card_id')} {...field} background={'white'} />
                  </FormItem>
                )}
              />

              <Controller
                name='email'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={t('common.email')} error={errors.email}>
                    <TextInput placeholder={t('authentication.enter_email')} {...field} background={'white'} />
                  </FormItem>
                )}
              />
            </Flex>
            <Flex gap='16px'>
              <Checkbox checked={checked} onChange={setChecked} />
              <Text fontSize='14px' color='#282828'>
                {t('common.yes')}
              </Text>
            </Flex>
            <Flex direction='column' gap='16px'>
              <Controller
                name='tax_id'
                control={control}
                rules={{ validate: value => validateTaxID(value) || t('authentication.invalid_tax_id') }}
                render={({ field }) => (
                  <FormItem required label={t('common.tax_id')} error={errors.tax_id}>
                    <TextInput disabled={!checked} css={{ backgroundColor: 'white' }} placeholder={t('authentication.enter_tax_id')} {...field} />
                  </FormItem>
                )}
              />
              <Controller
                name='address'
                control={control}
                render={({ field }) => (
                  <FormItem label={t('common.address')} error={errors.address}>
                    <TextInput disabled={!checked} placeholder={t('authentication.enter_address')} {...field} background={'white'} />
                  </FormItem>
                )}
              />
            </Flex>
            <Flex gap='20px' align='flex-start' justify='flex-end'>
              <Button
                onClick={() => {
                  setStep(formStep - 1)
                }}
                style='outline'
                type='neutralDark'
                label={t('common.back')}
                css={{ width: '160px' }}
              />
              <Button onClick={() => setStep(formStep + 1)} style='solid' type='neutralDark' label={t('common.next')} css={{ width: '160px' }} />
            </Flex>
          </Flex>
        )}
        {formStep === 3 && (
          <Flex direction='column' gap='48px' w='580px'>
            <Flex direction='column' gap='8px' align='flex-start'>
              <Text fontSize='30px' fontWeight='700' color='#8DC63F'>
                payment methods
              </Text>
              <Text fontSize='14px' color='#282828'>
                tickets
              </Text>
            </Flex>
            <Flex gap='16px'></Flex>
            <Flex gap='20px' align='flex-start' justify='flex-end'>
              <Button
                onClick={() => {
                  setStep(formStep - 1)
                }}
                style='outline'
                type='neutralDark'
                label={t('common.back')}
                css={{ width: '160px' }}
              />
              <Button submit style='solid' type='neutralDark' label={t('common.next')} css={{ width: '160px' }} />
            </Flex>
          </Flex>
        )}
        <Flex direction='column' gap='24px' padding='24px' bg='white' border='1px solid #E6E6E6' borderRadius='8px' minW='480px'>
          <Flex direction='column' gap='8px'>
            <Text fontSize='16px' fontWeight='600' color='#98A2B3'>
              summ
            </Text>
            <Text fontSize='24px' fontWeight='700' color='#1D2939'>
              date
            </Text>
          </Flex>
          <Flex direction='column' gap='8px'>
            <Flex align='center' gap='8px'>
              <Image src={pinIcon} />
              <Text fontSize='18px' fontWeight='700' color='#1D2939'>
                hour_start
              </Text>
              <Text fontSize='14px' fontWeight='600' color='#667085'>
                station_start
              </Text>
            </Flex>
            <Flex align='center' gap='8px'>
              <Image src={flagIcon} />
              <Text fontSize='18px' fontWeight='700' color='#1D2939'>
                hour_end
              </Text>
              <Text fontSize='14px' fontWeight='600' color='#667085'>
                station_end
              </Text>
            </Flex>
          </Flex>
          <Flex justify='space-between' align='center' gap='32px' w='100%'>
            <TransportIcon type={3} />
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                duration
              </Text>
              <Text fontSize='14px' fontWeight='600' color='#667085'>
                duration
              </Text>
            </Flex>
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                connection
              </Text>
              <Text fontSize='14px' fontWeight='600' color='#667085'>
                0
              </Text>
            </Flex>
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                passengers
              </Text>
              <Text fontSize='14px' fontWeight='600' color='#667085'>
                0
              </Text>
            </Flex>
          </Flex>
          <Flex justify='space-between' align='center' gap='32px' w='100%'>
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                passengers
              </Text>
              <Text fontSize='14px' fontWeight='600' color='#667085'>
                0
              </Text>
            </Flex>
            <Text fontSize='14px' fontWeight='600' color='#667085'>
              price
            </Text>
          </Flex>

          <Divider border='1px solid #CACFD8' />

          <Flex direction='column' gap='8px'>
            <Flex justify='space-between'>
              <Text fontSize='14px' color='#1D2939'>
                fees
              </Text>
              <Text fontSize='14px' color='#1D2939'>
                0
              </Text>
            </Flex>
            <Flex justify='space-between'>
              <Text fontSize='14px' color='#1D2939'>
                taxes
              </Text>
              <Text fontSize='14px' color='#1D2939'>
                0
              </Text>
            </Flex>
          </Flex>

          <Controller
            name='promocode'
            control={control}
            render={({ field }) => (
              <FormItem label={t('common.discount_code')} error={errors.promocode}>
                <TextInput placeholder={t('authentication.enter_code')} {...field} background={'white'} />
              </FormItem>
            )}
          />

          <Divider border='1px solid #CACFD8' />

          <Flex justify='space-between'>
            <Flex direction='column' gap='4px'>
              <Text fontSize='30px' color='#1D2939'>
                fees
              </Text>
              <Text fontSize='14px' color='#667085'>
                money money
              </Text>
            </Flex>
            <Text fontSize='30px' color='#1D2939'>
              0
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PaymentDetails
