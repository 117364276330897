import { Flex, Skeleton, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateInput, FormItem, Label, Select } from '@ubirider/pick-component-library'
import { Controller, useFormContext } from 'react-hook-form'
import { DateTime } from 'luxon'

export function CalendarInfo({ network, currentDay, exceptionDates, selectedStop, loading, maxDate }) {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const currentDayException = Object.values(exceptionDates.dates).some(dateString => {
    const fullCurrentDay = currentDay.toISO({ includeOffset: false }) + 'Z'
    const datePortion = dateString.slice(0, 10)
    return datePortion === fullCurrentDay.slice(0, 10)
  })

  const routePatterns = Object.values(network?.route.patterns?.entities || {}).map(entity => entity?.name)

  const patternOptions =
    routePatterns && routePatterns.length > 0
      ? routePatterns?.map((value, index) => ({
          label: value,
          value: index,
        }))
      : null

  if (loading) {
    return (
      <Flex direction='column' padding={{ sm: '16px', lg: '16px 24px 0 24px' }} gap={{ sm: '24px', lg: '32px' }} w='100%'>
        <Flex direction='column' gap='16px' w='100%'>
          <Flex direction={{ sm: 'column', lg: 'row' }} gap='8px' justify='space-between' align='center' w='100%'>
            <Flex w={{ sm: '100%', lg: '260px' }}>
              <Controller
                name='date'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={''} error={errors.date}>
                    <DateInput {...field} background={'white'} minDate={DateTime.now().startOf('day')} maxDate={maxDate} />
                  </FormItem>
                )}
              />
            </Flex>
            <Text fontSize='12px' color='#475467'>
              {t('network_schedule.schedules_disclaimer')}
            </Text>
          </Flex>
        </Flex>
        <Flex display={{ sm: 'none', lg: 'flex' }} direction='column' gap='16px'>
          {currentDayException && (
            <>
              <Skeleton w='50%' h='19.5px' />
              <Skeleton w='120px' h='24px' />
            </>
          )}
          {!currentDayException && (
            <>
              <Skeleton w='50%' h='19.5px' />
              <Skeleton w='80%' h='36px' />
            </>
          )}
        </Flex>
      </Flex>
    )
  } else {
    return (
      <Flex direction='column' padding={{ sm: '16px', lg: '16px 24px 0 24px' }} gap={{ sm: '24px', lg: '32px' }} w='100%'>
        <Flex direction='column' gap='16px'>
          <Flex direction={{ sm: 'column', lg: 'row' }} gap='8px' justify='space-between' align='center' w='100%'>
            <Flex w={{ sm: '100%', lg: '260px' }}>
              <Controller
                name='date'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={''} error={errors.date}>
                    <DateInput {...field} background={'white'} minDate={DateTime.now().startOf('day')} maxDate={maxDate} />
                  </FormItem>
                )}
              />
            </Flex>
            <Text fontSize='12px' color='#475467'>
              {t('network_schedule.schedules_disclaimer')}
            </Text>
          </Flex>

          <Flex direction='column' gap='8px' align='flex-start' w='100%'>
            {!selectedStop && (
              <Flex gap='8px' w={{ sm: '100%', lg: '440px' }} align='center'>
                <Text display={{ sm: 'none', lg: 'flex' }} fontSize='16px' fontWeight={'800'} color='#475467'>
                  {t('network_schedule.direction')}
                </Text>
                <Controller
                  name='pattern'
                  control={control}
                  render={({ field }) => (
                    <FormItem label={''}>
                      <Select background={'white'} style={'ghost'} options={patternOptions} {...field} css={{ textAlign: 'left' }} />
                    </FormItem>
                  )}
                />
              </Flex>
            )}
          </Flex>
          {currentDayException && (
            <Flex direction='column' gap='8px'>
              <Label label={t('network_schedule.exception_date')} style={'solid'} type={'secondary'} />
              <Text fontSize='13px' fontWeight='700' color='#475467'>
                {t('network_schedule.exceptional_services')}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  }
}
