import { MapLine } from '@ubirider/pick-component-library'
import React, { useEffect, useState } from 'react'

function RouteLine({ focus, network, allNetwork, selectedRoute, selectedPattern, selectedStop, hoveredRoute }) {
  const [shapeID, setShape] = useState(null)

  useEffect(() => {
    setShape(null)
    if (focus) {
      const pattern = Object.values(network.route.patterns.entities)[selectedPattern]
      if (pattern !== undefined) {
        const shapeValues = pattern.shapeID
        setShape(shapeValues)
      }
    }
  }, [selectedPattern, network])

  return (
    <>
      {!shapeID && (
        <MapLine shape={network.shape} color={hoveredRoute === network.id ? network.color : '#A3A3A3'} zIndex={hoveredRoute === network.id ? 1000 : 1} visible={!focus} />
      )}

      {shapeID && !network.alerts.some(item => 'affectedRoutes' in item) && (
        <MapLine shape={network.route.shapes[shapeID]} color={network.route.color} zIndex={2000} visible={focus} />
      )}

      {shapeID && network.alerts.some(item => 'affectedRoutes' in item) && (
        <MapLine shape={network.route.shapes[shapeID]} color={network.route.color} zIndex={2000} visible={focus} alertOutline />
      )}

      {selectedStop && hoveredRoute && (
        <MapLine
          shape={allNetwork.routes.find(route => route.id === hoveredRoute)?.shape}
          color={allNetwork.routes.find(route => route.id === hoveredRoute)?.color}
          zIndex={150000}
        />
      )}
    </>
  )
}

export default RouteLine
