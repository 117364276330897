import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { OrderPassModal } from '../../modals/OrderPassModal/OrderPassModal'
import { CancelModal } from '../../modals/CancelModal'
import { RedirectModal } from '../../modals/RedirectModal'
import React, { useEffect, useRef, useState } from 'react'
import { getPrices } from '../../api/requests'
import config from '../../config/configLoader'
import { retrieveFileFromDB } from '../../hooks/useIndexedDB'
import { LoadingRequestModal } from '../../modals/LoadingRequestModal'
import { TryAgainModal } from '../../modals/TryAgainModal'
import { useNavigate } from 'react-router-dom'
import { customError, customLog } from '../../helpers/log'
import { MainHeader } from './components/MainHeader'
import { TicketAgents } from './components/TicketAgents'
import { PassRequirements } from './components/PassRequirements'
import { TicketRates } from './components/TicketRates'
import Cookies from 'universal-cookie/es6'

import { ManageProfileModal } from '../../modals/ManageProfileModal'
import { useCustomToast } from '../../hooks/useToast'

function TicketFares() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const showCustomToast = useCustomToast()

  const agents = useRef()

  const cookies = new Cookies()

  const [transactionID, setTransactionID] = useState(null)
  const [files, setFiles] = useState(null)
  const [data, setData] = useState(null)
  const [errorID, setErrorID] = useState(null)
  const [orderCode, setOrderCode] = useState(null)
  const [fares, setFares] = useState(null)
  const [types, setTypes] = useState(null)
  const [requestFares, setRequestFares] = useState(null)
  const [loading, setLoading] = useState(null)

  const [expandedStates, setExpandedStates] = useState(Array(20).fill(false))
  const [expandedVariations, setExpandedVariations] = useState(Array(20).fill(false))
  const setExpandedState = (index, value) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates]
      newStates[index] = value
      return newStates
    })
  }
  const setExpandedVariation = (index, value) => {
    setExpandedVariations(prevStates => {
      const newStates = [...prevStates]
      newStates[index] = value
      return newStates
    })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isOpen: isOpenRenewal, onOpen: onOpenRenewal, onClose: onCloseRenewal } = useDisclosure()

  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure()

  const { isOpen: isOpenLoadingRequest, onOpen: onOpenLoadingRequest, onClose: onCloseLoadingRequest } = useDisclosure()

  const { isOpen: isOpenTryAgain, onOpen: onOpenTryAgain, onClose: onCloseTryAgain } = useDisclosure()

  const { isOpen: isOpenRedirect, onOpen: onOpenRedirect, onClose: onCloseRedirect } = useDisclosure()

  useEffect(() => {
    const getTicketInfo = async () => {
      setLoading(true)
      const { filteredFares, filteredPasses, filteredPrices } = await getPrices()
      customLog([filteredFares, filteredPasses, filteredPrices])

      setFares(filteredFares.sort((a, b) => a.fareName.localeCompare(b.fareName)))
      setTypes(filteredPrices)
      setRequestFares(filteredPasses)
      setLoading(false)
    }

    const handlePaymentReroute = async () => {
      //const savedData = localStorage.getItem('PassRequestData')
      const cookieData = cookies.get('PassRequestData')
      try {
        if (cookieData) {
          await getAllFilesData(cookieData.filesSize)
          setData(cookieData)
        } else {
          handleErrorToast()
        }
      } catch (error) {
        customError(error)
        handleErrorToast()
      }
    }

    const getAllFilesData = async nFiles => {
      try {
        const photo = await retrieveFileFromDB('photo')
        const fileDocs = []
        for (let i = 0; i < nFiles; i++) {
          const file = await retrieveFileFromDB('file_' + i)
          fileDocs.push(file)
        }
        const res = {
          photo: photo,
          files: fileDocs,
        }
        setFiles(res)
      } catch (error) {
        customError(error)
      }
    }

    const handleErrorToast = () => {
      showCustomToast('danger', t('ticket_fares.request_failed_subtitle'))
      navigate('/ticket-fares')
    }

    if (window.location.hash !== '#agents') {
      window.scrollTo(0, 0)
    } else {
      agents.current.scrollIntoView({ block: 'start', inline: 'center' })
    }

    const urlParams = new URLSearchParams(window.location.search)
    const eventId = urlParams.get('eventId') || null
    const id = urlParams.get('id') || null
    setTransactionID(id)
    if (!fares && config.sections.ticket_rates) getTicketInfo()
    if (window.location.pathname === '/ticket-fares/success') {
      handlePaymentReroute()
    } else if (window.location.pathname === '/ticket-fares/fail') {
      //const savedData = localStorage.getItem('PassRequestData')
      const cookieData = cookies.get('PassRequestData')

      setData(cookieData)
      getAllFilesData()
      setErrorID(eventId)
      if (eventId === '999') {
        onOpenTryAgain()
      } else {
        onOpen()
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (data !== null && files !== null) {
      onOpenLoadingRequest()
    }
  }, [data, files])

  return (
    <Flex direction='column' flex='1'>
      {config.sections.pass_requests && (
        <MainHeader openOrderPassModal={onOpen} openRenewPassModal={onOpenRenewal} disableRequests={!requestFares || !types || requestFares.length === 0 || types.length === 0} />
      )}

      {config.sections.ticket_rates && (
        <TicketRates
          loading={loading}
          prices={fares}
          expandedStates={expandedStates}
          setExpandedState={setExpandedState}
          expandedVariations={expandedVariations}
          setExpandedVariation={setExpandedVariation}
        />
      )}

      {config.sections.ticket_agents && <TicketAgents agents={agents} />}

      {config.sections.pass_requirements && <PassRequirements />}

      <OrderPassModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        onOpenCancel={onOpenCancel}
        onOpenRedirect={onOpenRedirect}
        setOrderCode={setOrderCode}
        errorID={errorID ? parseInt(errorID, 10) : null}
        dataLS={data}
        files={files}
        prices={requestFares}
        types={types}
        path={'/ticket-fares'}
      />
      <ManageProfileModal prices={requestFares} onOpen={onOpenRenewal} isOpen={isOpenRenewal} onClose={onCloseRenewal} onOpenCancel={onOpenCancel} />
      <CancelModal isOpen={isOpenCancel} onOpen={onOpenCancel} onClose={onCloseCancel} onCloseOrderPass={onClose} />
      <TryAgainModal isOpen={isOpenTryAgain} onOpen={onOpenTryAgain} onClose={onCloseTryAgain} onOpenOrderPass={onOpen} />
      <RedirectModal isOpen={isOpenRedirect} onOpen={onOpenRedirect} onClose={onCloseRedirect} orderCode={orderCode} />
      <LoadingRequestModal isOpen={isOpenLoadingRequest} onOpen={onOpenLoadingRequest} onClose={onCloseLoadingRequest} files={files} data={data} id={transactionID} />
    </Flex>
  )
}

export default TicketFares
