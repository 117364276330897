import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Link, Text } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import config from '../../../config/configLoader'
import { Trans } from 'react-i18next'
import React from 'react'

export const Faq = props => {
  return (
    <AccordionItem marginBlock='16px' rounded='8px' width='100%'>
      {({ isExpanded }) => (
        <Flex
          rounded='8px'
          border='1px'
          borderColor={isExpanded ? config.mainColor : '#D3D3D3'}
          _hover={{
            border: '1px',
            borderColor: config.mainColor,
          }}
          direction='column'
          css={'box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1)'}
          w='100%'
        >
          <AccordionButton
            padding='18px'
            bg={isExpanded ? config.themeTokens.colors.primary50 : 'white'}
            borderRadius={isExpanded ? '8px 8px 0px 0px' : '8px'}
            _hover={{}}
            w='100%'
          >
            <Flex justifyContent='space-between' align='center' w='100%'>
              <Text fontSize='14px' fontWeight='700' align='left'>
                {props.title}
              </Text>
              <AccordionIcon color='#344054' />
            </Flex>
          </AccordionButton>

          <AccordionPanel pb='16px'>
            {props.link && (
              <Flex direction='column' flex='1' mt='8px' gap='16px'>
                {props.description.map((line, index) => (
                  <Text key={index} fontSize={{ sm: '13px', lg: '16px' }} color='#1D2939'>
                    <Trans i18nKey={line}>
                      Random text
                      <Link className='chakra-custom-link' sx={{ fontWeight: '600', textDecoration: 'underline' }} as={ReactLink} to={props.link} isExternal>
                        here
                      </Link>
                      and here.
                    </Trans>
                  </Text>
                ))}
              </Flex>
            )}
            {!props.link && (
              <Flex direction='column' flex='1' mt='8px' gap='16px'>
                {props.description.map((line, index) => (
                  <Text key={index} fontSize={{ sm: '13px', lg: '16px' }} color='#1D2939'>
                    {line}
                  </Text>
                ))}
              </Flex>
            )}
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}
