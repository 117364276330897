import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex, Grid, Text } from '@chakra-ui/react'
import { NewsCard } from '../../../News/NewsCard'
import { newsCards } from '../../../../consts/news'
import { Button } from '@ubirider/pick-component-library'

import bgDesktop from '../../../../assets/news_bg_desktop.png'
import config from '../../../../config/configLoader'

export function NewsSection() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const filteredNewsCards = newsCards[config.operator].filter(newsCard => newsCard.display !== 1)

  return (
    <Flex
      direction='column'
      justify='center'
      align='center'
      bg='#F9F9FB'
      backgroundImage={{ sm: '', lg: bgDesktop }}
      backgroundRepeat='no-repeat'
      backgroundPosition='left bottom'
      padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px', xl: '90px 130px' }}
      gap={{ sm: '32px', md: '32px', lg: '60px', xl: '60px' }}
    >
      <Flex direction='column' gap={{ sm: '8px', lg: '16px' }} textAlign='center' justify='center'>
        <Text color='#001D20' fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700'>
          {t('home.news_title')}
        </Text>
        <Text color='#282828' fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600'>
          {t('home.news_subtitle')}
        </Text>
      </Flex>

      <Flex direction='column' overflow='hidden'>
        <Grid
          templateColumns={{
            sm: `repeat(${Math.min(3, filteredNewsCards.length)}, 280px)`,
            md: `repeat(${Math.min(3, filteredNewsCards.length)}, 1fr)`,
          }}
          gridAutoRows='1fr'
          gap='16px'
          overflowX='scroll'
          maxW='calc(100vw)'
          padding='16px'
          sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        >
          {filteredNewsCards.map((newsCard, index) => (
            <NewsCard
              key={index}
              image={newsCard.image}
              url={newsCard.url}
              title={newsCard.title}
              description={newsCard.description}
              source={newsCard.source}
              duration={newsCard.duration}
            />
          ))}
        </Grid>
      </Flex>

      <Button
        onClick={() => {
          navigate('/news')
        }}
        style='solid'
        type='neutralDark'
        label={t('home.read_more')}
        css={{ width: '152px' }}
      />
    </Flex>
  )
}
