import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Button } from '@ubirider/pick-component-library'
import alertDefault from '../../../../../../assets/alert_red_full.svg'
import closeButton from '../../../../../../assets/cross.svg'
import infoIcon from '../../../../../../assets/info_blue.svg'
import alertIcon from '../../../../../../assets/alert_yellow.svg'
import dangerIcon from '../../../../../../assets/alert_red.svg'
import arrowIcon from '../../../../../../assets/arrow_right_neutral.svg'
import React, { useRef } from 'react'
import stopIcon from '../../../../../../assets/stop_sign.svg'
import { Link } from 'react-router-dom'

function StopCardHeader({ selectedNetwork, alertValue, alertsExpanded, expandAlerts, handleAlert, handleSchedules, stop, closeDrawer, loading }) {
  const { t } = useTranslation()

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const nAlerts = windowSize.current[0] >= 960 ? 3 : 2

  return (
    <>
      <Flex justify='space-between' w='100%' mt={loading ? '-12px' : ''}>
        <Flex direction='column' gap='8px' w='100%'>
          <Flex align='center' justify='space-between' w='100%'>
            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
              {t('network_schedule.stop')}
            </Text>
            <Flex>{alertValue?.length > 0 && <Image src={alertDefault} />}</Flex>
          </Flex>
          <Flex gap='8px' align='center' w={{ sm: 'calc(75vw)', md: 'calc(75vw)', lg: '100%', xl: '100%' }}>
            <Image src={stopIcon} />
            <Text textAlign='left' fontSize='16px' fontWeight='700' color='#1D2939'>
              {stop?.name}
            </Text>
          </Flex>
        </Flex>
        <Flex as='button' display={{ sm: 'flex', lg: 'none' }} align='flex-start' onClick={closeDrawer}>
          <Image src={closeButton} />
        </Flex>
      </Flex>

      <Flex direction='column' gap='16px' w='100%' align='center'>
        <Button onClick={handleSchedules} type='neutralDark' style='outline' size='small' label={t('network_schedule.schedules')} css={{ width: '100%' }} />

        {alertValue.length > 0 && (
          <Flex direction='column' gap='8px' w='100%'>
            <Flex justify={'space-between'} align={'center'}>
              <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                {t('home.notification_center_title')}
              </Text>

              <Button linkComponent={<Link className='chakra-custom-link' to={'/notification-center'} />} type='neutralDark' style='typo' size='small' label={t('home.view_all')} />
            </Flex>

            {alertValue.slice(0, nAlerts).map(alert => (
              <Flex justify='space-between' align='center' bg='#F9F9FB' w='100%' borderRadius='4px' padding='16px' as='button' onClick={() => handleAlert(alert)}>
                <Flex direction='column' gap='4px' maxW={{ lg: '300px' }}>
                  <Flex gap='8px' align='center'>
                    <Image src={alert.alertLevel === 0 ? infoIcon : alert.alertLevel === 1 ? alertIcon : alert.alertLevel === 2 ? dangerIcon : null} w='20px' h='18px' />
                    <Text
                      fontSize='13px'
                      fontWeight='600'
                      color={alert.alertLevel === 0 ? '#444CE7' : alert.alertLevel === 1 ? '#FBC02D' : alert.alertLevel === 2 ? '#D23636' : null}
                      overflow='hidden'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                    >
                      {alert.header}
                    </Text>
                  </Flex>
                  <Flex>
                    <div className='multiline-ellipsis-text-route'>{alert.description}</div>
                  </Flex>
                </Flex>
                <Image src={arrowIcon} />
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default StopCardHeader
