import { useTranslation } from 'react-i18next'
import { Flex, Text, Image, GridItem, Grid } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Keyboard } from 'swiper'
import { passengerInfo } from '../../../../consts/passengers'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import config from '../../../../config/configLoader'

import arrowPrev from '../../../../assets/arrow_prev_pick.svg'
import arrowNext from '../../../../assets/arrow_next_pick.svg'
import React from 'react'

export function ReviewsSection() {
  const { t } = useTranslation()

  return (
    <Flex
      direction='column'
      bg='white'
      gap={{ sm: '32px', md: '32px', lg: '60px', xl: '60px' }}
      padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 0px', xl: '90px 0px' }}
      align='center'
      justify='center'
      h={{ lg: '678px' }}
    >
      <Text textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }} fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20'>
        {t('home.reviews_title')}
      </Text>
      <Flex display={{ sm: 'none', lg: 'flex' }} gap='32px' align='center' width='90%' pt='16px'>
        <Flex
          display={{ sm: 'none', md: 'flex' }}
          zIndex='50'
          className='swiper-button-prev-unique'
          cursor='pointer'
          w={{ sm: '24px', lg: '48px' }}
          h={{ sm: '24px', lg: '48px' }}
          align='center'
          justify='center'
        >
          <Image src={arrowPrev} w='7px' />
        </Flex>
        <Swiper
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          slidesPerView={3}
          navigation={{
            nextEl: '.swiper-button-next-unique',
            prevEl: '.swiper-button-prev-unique',
          }}
          keyboard={true}
          modules={[Navigation, Keyboard]}
          className='mySwiper'
        >
          {passengerInfo.map((card, index) => (
            <SwiperSlide key={index}>
              <Flex padding='16px'>
                <Flex
                  w={{ sm: '280px', md: '280px', lg: '320px', xl: '320px' }}
                  h={{ sm: '480px', md: '480px', lg: '420px', xl: '420px' }}
                  direction='column'
                  gap='24px'
                  padding='24px'
                  align='center'
                  justify='space-between'
                  bg='white'
                  border='1px solid #98A2B3'
                  borderRadius='16px'
                  boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.1)'
                >
                  <Flex justify='center' align='center'>
                    <Text textAlign='center' fontSize={{ sm: '13eu estoiu px', md: '13px', lg: '14px', xl: '14px' }} color='#282828' fontStyle='italic'>
                      {card.description}
                    </Text>
                  </Flex>
                  <Flex justify='flex-end' align='center' direction='column' gap='24px'>
                    <Image src={card.image} w='60px !important' h='60px !important' border='1px solid #98A2B3' borderRadius='50%' />
                    <Flex direction='column' align='center' gap='8px'>
                      <Text fontSize='16px' fontWeight='700' color='#282828'>
                        {card.author}
                      </Text>
                      <Text fontSize='16px' fontWeight='600' color={config.mainColor}>
                        {t('home.reviews_operator_passenger')}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
        <Flex
          display={{ sm: 'none', md: 'flex' }}
          zIndex='50'
          className='swiper-button-next-unique'
          cursor='pointer'
          w={{ sm: '24px', lg: '48px' }}
          h={{ sm: '24px', lg: '48px' }}
          align='center'
          justify='center'
        >
          <Image src={arrowNext} w='7px' />
        </Flex>
      </Flex>
      <Flex display={{ sm: 'flex', lg: 'none' }} gap='32px' align='center'>
        <Flex direction='column' overflow='hidden'>
          <Grid
            templateColumns={{
              sm: 'repeat(' + passengerInfo.length + ', 280px)',
              md: 'repeat(' + passengerInfo.length + ', 280px)',
              lg: 'repeat(3, 1fr)',
              xl: 'repeat(3, 1fr)',
              xxl: 'repeat(3, 1fr)',
            }}
            gap='16px'
            overflowX='scroll'
            maxW='calc(100vw)'
            padding='16px'
            sx={{ '::-webkit-scrollbar': { display: 'none' } }}
          >
            {passengerInfo.map(card => (
              <GridItem key={card.id} colSpan={1}>
                <Flex
                  w={{ sm: '280px', md: '280px', lg: '380px', xl: '360px' }}
                  h={{ sm: '420px', md: '420px', lg: '520px', xl: '520px' }}
                  direction='column'
                  gap='24px'
                  padding='24px'
                  align='center'
                  justify='space-between'
                  bg='white'
                  border='1px solid #E6E6E6'
                  borderRadius='8px'
                  boxShadow='0px 0px 10px rgba(0, 0, 0, 0.1)'
                >
                  <Flex justify='center' align='center'>
                    <Text textAlign='center' fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' fontStyle='italic'>
                      {card.description}
                    </Text>
                  </Flex>
                  <Flex justify='flex-end' align='center' direction='column' gap='24px'>
                    <Image src={card.image} w='60px !important' h='60px !important' border='1px solid #98A2B3' borderRadius='50%' />
                    <Flex direction='column' align='center'>
                      <Text fontSize='16px' fontWeight='700' color='#282828'>
                        {card.author},
                      </Text>
                      <Text fontSize='16px' fontWeight='600' color={config.mainColor}>
                        {t('home.reviews_operator_passenger')}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </Flex>
    </Flex>
  )
}
