import { useTranslation } from 'react-i18next'
import { Flex, Text, Image, Box } from '@chakra-ui/react'
import React from 'react'

import mastercard_bg from '../../../../../../assets/mastercard_bg.png'
import mastercard_bg_mobile from '../../../../../../assets/mastercard_bg_mobile.png'
import card from '../../../../../../assets/mastercard_card.png'
import mastercard_logo from '../../../../../../assets/mastercard_logo.svg'

export function MastercardAdPage() {
  const { t } = useTranslation()

  return (
    <>
      <Flex display={{ sm: 'none', lg: 'flex' }} bg={`url(${mastercard_bg})`} backgroundSize='cover' backgroundPosition='center' w='100%' h='100%'>
        <Flex
          h='100%'
          direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          justify='flex-start'
          align='center'
          padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px', xl: '90px 130px' }}
          gap={{ sm: '32px', md: '32px', lg: '17px', xl: '17px' }}
          position='relative'
          overflow='hidden'
        >
          <Flex direction='column' gap='24px' w={{ lg: '60%' }}>
            <Flex direction='column' gap='56px'>
              <Flex direction='column' gap='8px'>
                <Text lineHeight={{ sm: '38px', lg: '72px' }} textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '60px', xl: '60px' }} fontWeight='700' color='white'>
                  {t('promo.banner_contactless_title_1')}
                </Text>
                <Text lineHeight={{ sm: '38px', lg: '60px' }} textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '48px', xl: '48px' }} color='white'>
                  {t('promo.banner_contactless_title_2')}
                </Text>
              </Flex>
              <Flex direction='column' gap='8px'>
                <Text textAlign='left' fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} fontWeight='700' color='white'>
                  {t('promo.banner_contactless_body_1')}
                </Text>
                <Text textAlign='left' fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} color='white'>
                  {t('promo.banner_contactless_body_2')}
                </Text>
              </Flex>
            </Flex>
            <Image src={mastercard_logo} h='48px' w='76px' />
          </Flex>

          <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} w='548px'>
            <Image src={card} objectFit='contain' />
          </Flex>

          <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} position='absolute' bottom='0' w='303px'>
            <Image src={card} objectFit='contain' />
          </Flex>
        </Flex>
      </Flex>

      <Flex display={{ sm: 'flex', lg: 'none' }} direction='column' bg={`url(${mastercard_bg_mobile})`} backgroundSize='cover' backgroundPosition='center' w='100%' h='716px'>
        <Flex
          h='100%'
          direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          justify='flex-start'
          align='center'
          padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px', xl: '90px 130px' }}
          gap={{ sm: '32px', md: '32px', lg: '17px', xl: '17px' }}
          position='relative'
          overflow='hidden'
        >
          <Flex direction='column' gap='24px'>
            <Flex direction='column' gap='8px'>
              <Text lineHeight={{ sm: '38px', lg: '72px' }} textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '60px', xl: '60px' }} fontWeight='700' color='white'>
                {t('promo.banner_contactless_title_1')}
              </Text>
              <Text lineHeight={{ sm: '38px', lg: '60px' }} textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '48px', xl: '48px' }} color='white'>
                {t('promo.banner_contactless_title_2')}
              </Text>
            </Flex>
            <Flex direction='column' gap='8px'>
              <Text textAlign='left' fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} fontWeight='700' color='white'>
                {t('promo.banner_contactless_body_1')}
              </Text>
              <Text textAlign='left' fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} color='white'>
                {t('promo.banner_contactless_body_2')}
              </Text>
            </Flex>
            <Image src={mastercard_logo} h='48px' w='76px' />
          </Flex>

          <Flex w='303px' mt='16px'>
            <Image src={card} objectFit='contain' />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
