import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, RouteLabel } from '@ubirider/pick-component-library'
import closeButton from '../../../../assets/cross.svg'
import stopSign from '../../../../assets/stop_sign.svg'
import { ScheduleNumbers } from '../../../../consts/schedules'
import config from '../../../../config/configLoader'
import { useFormContext } from 'react-hook-form'
import arrowBack from '../../../../assets/arrow_left.svg'
import { DateTime } from 'luxon'

export function ScheduleHeader({ network, selectedRoute, selectedStop, selectedPattern, isException, loading, closeSchedules }) {
  const { t } = useTranslation()
  const { reset } = useFormContext()

  return (
    <Flex gap={{ sm: '16px', lg: '24px' }} direction='column' w='100%' boxShadow={{ lg: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' }}>
      <Flex gap={{ sm: '16px', lg: '8px' }} direction='column' padding={{ sm: '16px 16px 0 16px', lg: '16px 24px' }} align={{ sm: 'flex-start', lg: 'center' }} w='100%'>
        {isException && (
          <>
            {!selectedStop && (
              <Flex direction='column' gap={{ sm: '16px', lg: '8px' }} align={{ sm: 'flex-start', lg: 'center' }} w='100%'>
                <Flex justify='space-between' align='center' w='100%'>
                  <Button
                    onClick={() => reset({ date: DateTime.now(), pattern: selectedPattern })}
                    type={'neutralLight'}
                    style={'typo'}
                    size={'small'}
                    label={t('common.back')}
                    leftIconProps={{ src: arrowBack }}
                    css={{ justifyContent: 'flex-start' }}
                  />
                  <Flex as='button' align='flex-start' onClick={closeSchedules}>
                    <Image src={closeButton} />
                  </Flex>
                </Flex>
                <Flex gap='24px' align='center' justify='space-between' w='100%'>
                  <Flex gap='24px' align='center' justify='flex-start'>
                    <RouteLabel shortName={network?.route.shortName} color={network?.route.color} textColor={network?.route.textColor} />
                    <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                      {network?.route.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                    </Text>
                  </Flex>
                  <Flex display={{ sm: 'none', lg: 'flex' }}>
                    {config.sections.schedules && (
                      <a
                        href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                        download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                        target='_blank'
                        display={{ sm: 'none', lg: 'flex' }}
                        rel='noreferrer'
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                          style='solid'
                          size='small'
                          type='neutralDark'
                          label={t('network_schedule.route_schedules')}
                        />
                      </a>
                    )}
                  </Flex>
                </Flex>
                <Flex w={'100%'} display={{ sm: 'flex', lg: 'none' }}>
                  {config.sections.schedules && (
                    <a
                      href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                      download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                      target='_blank'
                      rel='noreferrer'
                      style={{ textDecoration: 'none', width: '100%', pointerEvents: loading || !ScheduleNumbers[config.operator][selectedRoute]?.link ? 'none' : 'auto' }}
                    >
                      <Button
                        disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                        style='solid'
                        type='neutralDark'
                        label={t('network_schedule.route_schedules')}
                        css={{ width: '100%' }}
                      />
                    </a>
                  )}
                </Flex>
              </Flex>
            )}
            {selectedStop && (
              <Flex direction='column' gap={{ sm: '16px', lg: '8px' }} align={{ sm: 'flex-start', lg: 'center' }} w='100%'>
                <Flex justify='space-between' align='center' w='100%'>
                  <Button
                    onClick={() => reset({ date: DateTime.now() })}
                    type={'neutralLight'}
                    style={'typo'}
                    size={'small'}
                    label={t('common.back')}
                    leftIconProps={{ src: arrowBack }}
                    css={{ justifyContent: 'flex-start' }}
                  />
                  <Flex as='button' align='flex-start' onClick={closeSchedules}>
                    <Image src={closeButton} />
                  </Flex>
                </Flex>
                <Flex align='center' justify='space-between' w='100%'>
                  <Flex gap={{ sm: '8px', lg: '24px' }} align='center'>
                    <Image src={stopSign} />
                    <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                      {network?.stops?.find(item => item.id === selectedStop)?.name}
                    </Text>
                  </Flex>
                  <Flex display={{ sm: 'none', lg: 'flex' }}>
                    {config.sections.schedules && (
                      <a
                        href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                        download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                        target='_blank'
                        display={{ sm: 'none', lg: 'flex' }}
                        rel='noreferrer'
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                          style='solid'
                          size='small'
                          type='neutralDark'
                          label={t('network_schedule.route_schedules')}
                        />
                      </a>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </>
        )}
        {!isException && (
          <>
            {!selectedStop && (
              <Flex w='100%' direction='column' gap={{ sm: '16px', lg: '8px' }}>
                <Flex justify='space-between' align='center' w='100%'>
                  <Flex gap={{ sm: '8px', lg: '24px' }} maxW='90%' align='center' justify='flex-start'>
                    <RouteLabel shortName={network?.route.shortName} color={network?.route.color} textColor={network?.route.textColor} />
                    <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                      {network?.route.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
                    </Text>
                  </Flex>
                  <Flex gap='24px' align='center' justify='flex-end'>
                    <Flex gap='24px' align='center' justify='flex-end' display={{ sm: 'none', lg: 'flex' }}>
                      {config.sections.schedules && (
                        <a
                          href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                          download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                          target='_blank'
                          rel='noreferrer'
                          style={{ textDecoration: 'none' }}
                        >
                          <Button
                            disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                            style='solid'
                            size='small'
                            type='neutralDark'
                            label={t('network_schedule.route_schedules')}
                          />
                        </a>
                      )}
                    </Flex>
                    <Flex as='button' onClick={closeSchedules}>
                      <Image src={closeButton} />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex w={'100%'} display={{ sm: 'flex', lg: 'none' }}>
                  {config.sections.schedules && (
                    <a
                      href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                      download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                      target='_blank'
                      rel='noreferrer'
                      style={{ textDecoration: 'none', width: '100%' }}
                    >
                      <Button
                        disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                        style='solid'
                        type='neutralDark'
                        label={t('network_schedule.route_schedules')}
                        css={{ width: '100%' }}
                      />
                    </a>
                  )}
                </Flex>
              </Flex>
            )}
            {selectedStop && (
              <Flex align='center' justify='space-between' w='100%'>
                <Flex gap={{ sm: '8px', lg: '24px' }} maxW='90%' align='center'>
                  <Image src={stopSign} />
                  <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                    {network?.stops?.find(item => item.id === selectedStop)?.name}
                  </Text>
                </Flex>
                <Flex as='button' onClick={closeSchedules}>
                  <Image src={closeButton} />
                </Flex>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
