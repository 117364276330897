import { Flex, Link, Text } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

function CookieTable() {
  const { t } = useTranslation()

  return (
    <Flex padding='8px' justify='center' bg='neutral.25' border='1px solid #EAECF0'>
      <Flex direction='column'>
        <Flex padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_cookie_table_cookies')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_1')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_6')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_11')}
          </Text>
        </Flex>
      </Flex>
      <Flex direction='column'>
        <Flex padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_cookie_table_cookies')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_2')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_7')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_12')}
          </Text>
        </Flex>
      </Flex>
      <Flex direction='column'>
        <Flex padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_cookie_table_duration')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_3')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_8')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_13')}
          </Text>
        </Flex>
      </Flex>
      <Flex direction='column'>
        <Flex padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_cookie_table_provider')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_4')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_9')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_14')}
          </Text>
        </Flex>
      </Flex>
      <Flex direction='column'>
        <Flex padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_cookie_table_use')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_5')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_10')}
          </Text>
        </Flex>
        <Flex h='88px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_cookie_table_15')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CookieTable
