import { Flex, Image, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'

import stopSign from '../../../../assets/stop_sign.svg'
import { TransportIcon } from '@ubirider/pick-component-library'

export function AffectedTransportsComponent(props) {
  const { t } = useTranslation()

  return (
    <>
      <Text fontSize='14px' fontWeight='600' color='#1D2939'>
        {t('network_schedule.transport_type')}
      </Text>
      <Wrap spacing={2} align='center'>
        {props.alert?.affectedTransports?.map((transport, i) => (
          <WrapItem key={i} align='center'>
            <Flex border='1.5px solid #1D2939' rounded='4px' padding='2px'>
              <TransportIcon type={transport} />
            </Flex>
          </WrapItem>
        ))}
      </Wrap>
    </>
  )
}
