import templateImage from '../assets/passengers/me.png'
export const passengerInfo = [
  {
    id: 1,
    author: 'John Silva Lopes',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
  {
    id: 2,
    author: 'Francisco Gonçalves',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
  {
    id: 3,
    author: 'Bruce Wayne',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
  {
    id: 4,
    author: 'Kachow',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
  {
    id: 5,
    author: 'James Bond',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
  {
    id: 6,
    author: 'Harry Potter',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
  {
    id: 7,
    author: 'Naruto',
    image: templateImage,
    description:
      'Travelling in the city of Évora with Trevo is very simple, comfortable and safe. Trevo always has priority number 1 passenger satisfaction. They are constantly innovating their services in order to be useful to their clients and always have in mind sustainability. Travel has never been easier and more comfortable, as it is with Trevo.',
  },
]
