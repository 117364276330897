export const DB_NAME = 'fileDB'
export const DB_VERSION = 1
export const FILE_STORE_NAME = 'files'

let db

const request = indexedDB.open(DB_NAME, DB_VERSION)

request.onupgradeneeded = function (event) {
  db = event.target.result
  db.createObjectStore(FILE_STORE_NAME, { keyPath: 'id', autoIncrement: true })
}

request.onsuccess = function (event) {
  db = event.target.result
}

request.onerror = function (event) {
  console.error('Error opening indexedDB', event.target.error)
}

export function storeFileInDB(file, fileName) {
  const transaction = db.transaction([FILE_STORE_NAME], 'readwrite')
  const fileStore = transaction.objectStore(FILE_STORE_NAME)

  const fileData = {
    id: fileName,
    file: file,
  }

  const request = fileStore.add(fileData)

  request.onsuccess = function (event) {
    console.log(`File ${fileName} stored successfully`)
  }

  request.onerror = function (event) {
    updateFileInDB(file, fileName)
    //console.error(`Error storing ${fileName}`, event.target.error)
  }
}

export function updateFileInDB(updatedFile, fileName) {
  const transaction = db.transaction([FILE_STORE_NAME], 'readwrite')
  const fileStore = transaction.objectStore(FILE_STORE_NAME)

  const fileData = {
    id: fileName,
    file: updatedFile,
  }

  const request = fileStore.put(fileData)

  request.onsuccess = function (event) {
    console.log(`File ${fileName} updated successfully`)
  }

  request.onerror = function (event) {
    console.error(`Error updating ${fileName}`, event.target.error)
  }
}

export function retrieveFileFromDB(fileName) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([FILE_STORE_NAME], 'readonly')
    const fileStore = transaction.objectStore(FILE_STORE_NAME)
    const request = fileStore.get(fileName)

    request.onsuccess = function (event) {
      const fileData = event.target.result
      if (fileData) {
        resolve(fileData.file)
      } else {
        reject(`File ${fileName} not found`)
      }
    }

    request.onerror = function (event) {
      reject(`Error retrieving ${fileName}`)
    }
  })
}

export function deleteFileFromDB(fileName) {
  const transaction = db.transaction([FILE_STORE_NAME], 'readwrite')
  const fileStore = transaction.objectStore(FILE_STORE_NAME)
  const request = fileStore.delete(fileName)

  request.onsuccess = function (event) {
    console.log(`File ${fileName} deleted successfully`)
  }

  request.onerror = function (event) {
    console.error(`Error deleting ${fileName}`, event.target.error)
  }
}
