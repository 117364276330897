export function customLog(message) {
  if (process.env.REACT_APP_MY_ENV !== 'prod') {
    if (Array.isArray(message)) {
      message.forEach(string => {
        console.log(string)
      })
    } else {
      console.log(message)
    }
  }
}

export function customError(message) {
  if (process.env.REACT_APP_MY_ENV !== 'prod') {
    console.error(message)
  }
}
