import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import info from '../../../../../../assets/info_blue.svg'

function TicketInfo() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' gap='24px'>
      <Text fontSize={{ sm: '24px', md: '24px', lg: '30px', xl: '30px' }} fontWeight='700' color='#0875BC' textAlign='center'>
        {t('ticket_fares.ticket_info_title')}
      </Text>
      <Flex direction='column'>
        <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828'>
          {t('ticket_fares.ticket_info_description_1')}
        </Text>
        <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828'>
          {t('ticket_fares.ticket_info_description_2')}
        </Text>
      </Flex>

      <Flex gap='16px' padding='8px 16px' align='center' border='1px solid #C7D7FE' bg='#E0EAFF' rounded='4px'>
        <Image src={info} w='24px' />
        <Flex direction='column' align='flex-start'>
          <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#444CE7'>
            {t('ticket_fares.ticket_info_inline_question')}
          </Text>
          <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} color='#444CE7' pl='8px'>
            &bull; {t('ticket_fares.ticket_info_inline_answer_1')}
          </Text>
        </Flex>
      </Flex>

      <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='700' color='#1D2939'>
        {t('ticket_fares.ticket_info_description_2')}
      </Text>
    </Flex>
  )
}

export default TicketInfo
