export const getStopsForRoute = (stops, routeID) => {
  return stops.filter(function (obj) {
    const routeIDs = JSON.parse(obj.routeIDs)
    return routeIDs.includes(routeID)
  })
}

export const getAlertsForRoute = (alerts, routeID, stops) => {
  return alerts.filter(function (obj) {
    if (obj?.affectedRoutes) {
      return obj?.affectedRoutes.some(function (route) {
        return route.routeID === routeID
      })
    } else if (obj?.affectedStops) {
      return obj?.affectedStops.some(function (stop) {
        const res = stops.filter(function (obj) {
          return stop.stopID === obj.id
        })
        return res.length > 0
      })
    }
    return false
  })
}
export const getAlertForStops = route => {
  return route?.alerts.flatMap(alert => {
    if (alert.affectedStops) {
      return alert.affectedStops.map(stop => ({
        id: stop.stopID,
        header: alert.header,
        description: alert.description,
        alertLevel: alert.alertLevel,
        startDate: alert.startDate,
        endDate: alert.endDate,
      }))
    }
    return []
  })
}
