import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export function FooterLink(props) {
  const { t } = useTranslation()

  return (
    <Link className='chakra-custom-link' to={props.path} target={props?.newTab ? '_blank' : null}>
      <Text textAlign='left' fontSize='14px'>
        {t(props.text)}
      </Text>
    </Link>
  )
}
