import { Box, Divider, Flex, Link, Text } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { Button, DateInput, FormItem, PhoneNumberInput, TextAreaInput, TextInput, validatePhoneNumber } from '@ubirider/pick-component-library'
import { Link as ReactLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { sendEmail } from '../../api/requests'
import { DateTime } from 'luxon'
import config from '../../config/configLoader'
import { customError } from '../../helpers/log'
import { useCustomToast } from '../../hooks/useToast'

function Support() {
  const { t } = useTranslation()
  const showCustomToast = useCustomToast()

  const minDate = DateTime.fromISO('1900-01-01T00:00:00')
  const maxDate = DateTime.now()

  const [loading, setLoading] = useState(false)
  const [checkedYesNo, setCheckedYesNo] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: null,
      email: null,
      phone: null,
      birthdate: null,
      card_id: null,
      origin: null,
      destination: null,
      message: null,
      check: null,
    },
  })

  const onSubmit = async data => {
    setLoading(true)
    data['birthdate'] =
      data?.birthdate?.c.day !== undefined
        ? data?.birthdate?.c.day + '/' + (data?.birthdate?.c.month > 9 ? data?.birthdate?.c.month : '0' + data?.birthdate?.c.month) + '/' + data?.birthdate?.c.year
        : null
    data['saveData'] = checkedYesNo === 'yes'
    try {
      await sendEmail('support', data)
      reset({
        name: null,
        email: null,
        phone: null,
        birthdate: null,
        card_id: null,
        origin: null,
        destination: null,
        message: null,
        check: null,
      })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      setCheckedYesNo(null)
      showCustomToast('success', t('support.message_sent_toaster'))
      setLoading(false)
    } catch (error) {
      customError(error)
      showCustomToast('danger', t('support.message_failed_toaster'))
      setLoading(false)
    }
  }

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        align='center'
        pt={{ sm: '32px', md: '32px', lg: '98px', xl: '98px' }}
        as='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex direction='column' gap='24px' justify='center' align='center' padding={{ sm: '0 24px', md: '0 24px', lg: '0 130px', xl: '0 230px' }}>
          <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20' textAlign='center'>
            {t('support.support')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828' textAlign='center'>
            {t('support.support_description')}
          </Text>
        </Flex>

        <Flex direction='column' gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }} padding={{ sm: '0 24px', md: '0 24px', lg: '0 130px', xl: '0 230px' }} width='100%'>
          <Flex direction='column' gap='24px'>
            <Controller
              name='name'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.name')} error={errors.name}>
                  <TextInput {...field} background={'white'} />
                </FormItem>
              )}
            />

            <Controller
              name='email'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.email')} error={errors.email}>
                  <TextInput {...field} background={'white'} />
                </FormItem>
              )}
            />

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}>
              <Controller
                name='phone'
                control={control}
                rules={{
                  required: t('authentication.required_field'),
                  validate: { phone_number: v => (v !== null ? validatePhoneNumber(v) : true) || t('authentication.invalid_phone') },
                }}
                render={({ field }) => (
                  <FormItem required label={t('common.phone')} error={errors.phone}>
                    <PhoneNumberInput {...field} background={'white'} />
                  </FormItem>
                )}
              />

              <Controller
                name='birthdate'
                control={control}
                render={({ field }) => (
                  <FormItem label={t('common.birthdate')} error={errors.birthdate}>
                    <DateInput {...field} background={'white'} minDate={minDate} maxDate={maxDate} />
                  </FormItem>
                )}
              />
            </Flex>

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}>
              <Controller
                name='card_id'
                control={control}
                render={({ field }) => (
                  <FormItem label={t('common.card_id')} error={errors.card_id}>
                    <TextInput {...field} background={'white'} />
                  </FormItem>
                )}
              />
              <Box display={{ sm: 'none', lg: 'flex' }} w='100%'></Box>
            </Flex>

            <Divider border='1px solid rgba(40, 40, 40, 0.25)' />

            <Text fontSize='16px' fontWeight='600' color='#1D2939'>
              {t('support.support_origin_destination_description')}
            </Text>

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}>
              <Controller
                name='origin'
                control={control}
                render={({ field }) => (
                  <FormItem label={t('common.origin')} error={errors.origin}>
                    <TextInput placeholder={t('authentication.enter_origin')} {...field} background={'white'} />
                  </FormItem>
                )}
              />

              <Controller
                name='destination'
                control={control}
                render={({ field }) => (
                  <FormItem label={t('common.destination')} error={errors.destination}>
                    <TextInput placeholder={t('authentication.enter_destination')} {...field} background={'white'} />
                  </FormItem>
                )}
              />
            </Flex>

            <Controller
              name='message'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.message')} error={errors.message}>
                  <TextAreaInput {...field} placeholder={t('authentication.enter_message')} background={'white'} />
                </FormItem>
              )}
            />
          </Flex>

          <Divider border='1px solid #98A2B366' />

          <Flex direction='column' gap='8px'>
            <Text fontSize='14px' fontWeight='700' color='#1D2939'>
              {t('common.disclaimer')}
            </Text>
            <Text fontSize='14px' color='#475467'>
              <Trans i18nKey='support.data_collected_1'>
                The data collected is intended to be included in our pool of candidates. To find out more about our Privacy Policy click
                <Link className='chakra-custom-link' sx={{ fontWeight: '600', textDecoration: 'underline' }} href='/privacy-policy' target='_blank'>
                  <Text textDecoration='underline' fontWeight='600'>
                    here
                  </Text>
                </Link>
                .
              </Trans>
            </Text>
          </Flex>
        </Flex>

        <Flex display={{ sm: 'none', lg: 'flex' }} justify='flex-end' align='flex-end' width='100%' padding={{ sm: '0 24px', md: '0 24px', lg: '0 230px', xl: '0 230px' }}>
          <Button submit loading={loading} style='solid' type='primary' label={t('common.submit')} css={{ width: '152px' }} />
        </Flex>

        <Flex display={{ sm: 'flex', lg: 'none' }} justify='flex-end' align='flex-end' width='100%' padding={{ sm: '0 24px', md: '0 24px', lg: '0 230px', xl: '0 230px' }}>
          <Button submit style='solid' type='primary' label={t('common.submit')} css={{ width: '100%' }} />
        </Flex>
      </Flex>

      <Flex
        direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
        mt='98px'
        justify='space-between'
        align='flex-start'
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '56px 130px', xl: '56px 130px' }}
        gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}
        borderTop='1px solid #98A2B366'
        flex='1'
      >
        <Flex direction='column' align='flex-start' gap={{ sm: '8px', lg: '24px' }} flex='4'>
          <Text fontSize='16px' fontWeight='700' color='#282828'>
            {t('support.office_title')}
          </Text>
          <Text fontSize='14px' color='#282828'>
            {t('support.office_description')}
          </Text>
        </Flex>
        <Flex direction='column' align='flex-start' gap={{ sm: '8px', lg: '24px' }} flex='3'>
          <Text fontSize='16px' fontWeight='700' color='#282828'>
            {t('support.office_hours_title')}
          </Text>
          <Flex direction='column' align='flex-start' gap='8px'>
            <Text fontSize='14px' color='#282828'>
              {t('support.weekdays_office')}
            </Text>
            <Text fontSize='14px' color='#282828'>
              {t('support.saturdays_office')}
            </Text>
            <Text fontSize='14px' color='#282828'>
              {t('support.sundays_office')}
            </Text>
          </Flex>
        </Flex>
        <Flex direction='column' align='flex-start' gap={{ sm: '8px', lg: '24px' }} flex='2'>
          <Text fontSize='16px' fontWeight='700' color='#282828'>
            {t('support.support')}
          </Text>
          <Flex direction='column' align='flex-start' gap='8px'>
            <ReactLink className='chakra-custom-link' to={`mailto:${config.info.support_email}`}>
              <Text textAlign='left' fontSize='14px'>
                {config.info.support_email}
              </Text>
            </ReactLink>
            <Flex direction='column'>
              <ReactLink className='chakra-custom-link' to={`tel:${config.info.support_phone}`}>
                <Text textAlign='left' fontSize='14px'>
                  {config.info.support_phone}
                </Text>
              </ReactLink>
              <Text fontSize='13px' color='#1D2939'>
                {t('home.call_cost')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Support
