import { Flex, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FundingLinks } from '../../../../consts/funding'
import config from '../../../../config/configLoader'

import logos from '../../../../assets/funding_logos.svg'

export function FooterFunding() {
  return (
    <Link target='_blank' to={FundingLinks[config.operator]}>
      <Flex w='100%' bg='white' padding='24px 48px'>
        <Image src={logos} />
      </Flex>
    </Link>
  )
}
