import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'

import wheelchairNoIcon from '../../../../../../assets/no_wheelchair.svg'
import wheelchairYesIcon from '../../../../../../assets/wheelchair_yes.svg'

function StopUtilitiesInfo({ stop }) {
  const { t } = useTranslation()

  if (stop?.wheelchairAccessible === 1) {
    return (
      <Flex gap='8px'>
        <Image src={wheelchairYesIcon} />
        <Text fontSize='14px' fontWeight='600' color='#475467'>
          {t('network_schedule.wheelchair_allowed')}
        </Text>
      </Flex>
    )
  } else if (stop?.wheelchairAccessible === 2) {
    return (
      <Flex gap='8px'>
        <Image src={wheelchairNoIcon} />
        <Text fontSize='14px' fontWeight='600' color='#475467'>
          {t('network_schedule.wheelchair_not_allowed')}
        </Text>
      </Flex>
    )
  }
  return null
}

export default StopUtilitiesInfo
