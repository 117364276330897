function SVGDot(color, selected) {
  const svgContent = selected
    ? `
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle cx="7.95117" cy="8.48584" r="5.8125" fill="${color}" stroke="white" stroke-width="4"/>
            <circle cx="7.95117" cy="8.48584" r="7.759" fill="none" stroke="${color}" stroke-width="0.2"/>
        </g>
    </svg>
    `
    : `
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7.95117" cy="8.48584" r="5.8125" fill="white" stroke="${color}" stroke-width="4"/>
    </svg>
  `

  const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' })
  return URL.createObjectURL(svgBlob)
}

export default SVGDot
