import { useTranslation } from 'react-i18next'
import { ButtonDropdown, setLanguage } from '@ubirider/pick-component-library'
import globeIcon from '../../../../assets/globe.svg'
import i18next from 'i18next'

export function LanguageSelector() {
  const { t } = useTranslation()

  function handleLanguage(lang) {
    localStorage.setItem('Language', lang)
    i18next.changeLanguage(lang)
    setLanguage(lang.toLowerCase())
  }

  const langOptions = [
    {
      label: t('common.english'),
      value: 'EN',
      selected: 'EN' === i18next.language,
      onClick: () => handleLanguage('EN'),
    },
    {
      label: t('common.portuguese'),
      value: 'PT',
      selected: 'PT' === i18next.language,
      onClick: () => handleLanguage('PT'),
    },
  ]

  return (
    <ButtonDropdown
      usePortal={false}
      buttonProps={{ type: 'neutralDark', style: 'ghost', leftIconProps: { src: globeIcon } }}
      cells={langOptions}
      placement={'bottom-end'}
      dropdownWidth={200}
      background={'white'}
      css={{ borderRadius: 4, boxShadow: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' }}
    />
  )
}
