import { Divider, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { RouteLabel } from '@ubirider/pick-component-library'
import React from 'react'

function StopRoutesList({ routesInStop, handleClick, handleHoverRoute }) {
  const { t } = useTranslation()

  return (
    <Flex direction='column' gap='16px'>
      <Text fontSize='14px' fontWeight='600' color='#1D2939'>
        {t('network_schedule.routes_on_this_stop')}
      </Text>
      <Flex direction='column'>
        {routesInStop.map((route, i) => (
          <Flex direction='column' key={i}>
            <Flex
              padding='8px 4px'
              rounded='4px'
              gap='16px'
              cursor='pointer'
              align='center'
              _hover={{ bg: '#edeff3' }}
              as='button'
              onClick={() => {
                handleClick(
                  route.id,
                  route.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())
                )
              }}
              onMouseEnter={() => handleHoverRoute(route.id)}
              onMouseLeave={() => handleHoverRoute('')}
            >
              <RouteLabel shortName={route?.shortName} color={route?.color} textColor={route?.textColor} />
              <Text fontSize='14px' color='#475467' textAlign='left' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                {route.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())}
              </Text>
            </Flex>
            {i !== routesInStop.length - 1 && <Divider width='auto' maxW='500px' ml='3px' mr='3px' border='1px solid #D0D5DD' />}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default StopRoutesList
