import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper'
import { Flex, Image } from '@chakra-ui/react'
import config from '../../../../config/configLoader'

import { PickAdPage } from './AdSlides/PickAdPage'
import { PassMigrationAdPage } from './AdSlides/PassMigrationAdPage'
import { MastercardAdPage } from './AdSlides/MastercardAdPage'
import { TrevoFareUpdatePage } from './AdSlides/TrevoFareUpdatePage'
import { ProximoSatisfactionSurvey } from './AdSlides/ProximoSatisfactionSurvey'
import { TrevoRouteChange } from './AdSlides/TrevoRouteChange'
import { TrevoFairSchedule } from './AdSlides/TrevoFairSchedule'
import { TrevoStopChanges } from './AdSlides/TrevoStopChanges'

import arrowPrev from '../../../../assets/arrow_prev_pick.svg'
import arrowNext from '../../../../assets/arrow_next_pick.svg'

export function AdSection() {
  return (
    <Flex direction='column' w='100%' align='center' h='716px'>
      <Flex align='center' w='100%' h='100%'>
        {Object.values(config.sections.ads).filter(value => value === true).length > 1 && (
          <Flex
            display={{ sm: 'none', md: 'flex' }}
            position='absolute'
            zIndex='50'
            className='swiper-button-prev-unique'
            cursor='pointer'
            left={{ sm: '0px', lg: '0px' }}
            w={{ sm: '24px', lg: '48px' }}
            h={{ sm: '24px', lg: '48px' }}
            align='center'
            justify='center'
          >
            <Image src={arrowPrev} w='7px' />
          </Flex>
        )}

        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 50000000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: '.swiper-button-next-unique',
            prevEl: '.swiper-button-prev-unique',
          }}
          pagination={{
            clickable: true,
          }}
          keyboard={true}
          modules={[Autoplay, Navigation, Pagination, Keyboard]}
          className='mySwiper'
        >
          {config.sections.ads.trevo_stop_changes && (
            <SwiperSlide>
              <TrevoStopChanges />
            </SwiperSlide>
          )}
          {config.sections.ads.trevo_fair_schedules && (
            <SwiperSlide>
              <TrevoFairSchedule />
            </SwiperSlide>
          )}
          {config.sections.ads.trevo_route_change && (
            <SwiperSlide>
              <TrevoRouteChange />
            </SwiperSlide>
          )}
          {config.sections.ads.proximo_satisfaction_survey && (
            <SwiperSlide>
              <ProximoSatisfactionSurvey />
            </SwiperSlide>
          )}
          {config.sections.ads.pick && (
            <SwiperSlide>
              <PickAdPage />
            </SwiperSlide>
          )}
          {config.sections.ads.pass_migration && (
            <SwiperSlide>
              <PassMigrationAdPage />
            </SwiperSlide>
          )}
          {config.sections.ads.mastercard && (
            <SwiperSlide>
              <MastercardAdPage />
            </SwiperSlide>
          )}
          {config.sections.ads.trevo_fare_update && (
            <SwiperSlide>
              <TrevoFareUpdatePage />
            </SwiperSlide>
          )}
        </Swiper>

        {Object.values(config.sections.ads).filter(value => value === true).length > 1 && (
          <Flex
            display={{ sm: 'none', md: 'flex' }}
            position='absolute'
            zIndex='50'
            className='swiper-button-next-unique'
            cursor='pointer'
            right={{ sm: '0px', lg: '0px' }}
            w={{ sm: '24px', lg: '48px' }}
            h={{ sm: '24px', lg: '48px' }}
            align='center'
            justify='center'
          >
            <Image src={arrowNext} w='7px' />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
