export const ProfileStatus = {
  PENDING: 0,
  ACTIVE: 1,
  EXPIRED: 2,
  DEACTIVATED: 3,
}

export const ProfileStatusLabel = {
  0: 'ticket_fares.pending_approval',
  1: 'ticket_fares.active',
  2: 'ticket_fares.expired',
  3: 'ticket_fares.deactivated',
}

export const ProfileStatusType = {
  0: 'warning',
  1: 'success',
  2: 'danger',
  3: 'neutralLight',
}

export const ProfileDecision = {
  APPROVED: 'approved',
  DECLINED: 'declined',
}
