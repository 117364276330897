import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import { Button } from '@ubirider/pick-component-library'

import bgDesktop from '../../../../assets/tickets_fares_bg_desktop.png'
import bgMobile from '../../../../assets/tickets_fares_bg_mobile.png'

function MainHeader({ openOrderPassModal, openRenewPassModal, disableRequests }) {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      direction='column'
      backgroundImage={{ lg: `url(${bgDesktop})` }}
      backgroundPosition={'center center'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      minH={{ lg: '427px' }}
      justifyContent='center'
    >
      <Flex display={{ sm: 'flex', lg: 'none' }}>
        <Image src={bgMobile} w={'100%'} />
      </Flex>
      <Flex
        direction='column'
        gap={{ sm: '32px', lg: '24px' }}
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '0 65px', xl: '0 130px' }}
        maxW={{ lg: '55%' }}
        h='100%'
        justify='center'
        alignSelf='flex-end'
      >
        <Flex direction='column' justify={{ sm: 'center', lg: 'flex-start' }} w='100%' gap={{ sm: '8px', lg: '16px' }}>
          <Text color='#1D2939' fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
            {t('ticket_fares.request_pass_title')}
          </Text>
          <Flex direction='column'>
            <Text color='#1D2939' fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} fontWeight='600' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
              {t('ticket_fares.request_pass_subtitle')}
            </Text>
            <Text color='#1D2939' fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} fontWeight='600' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
              {t('ticket_fares.request_pass_subtitle_2')}
            </Text>
          </Flex>
        </Flex>
        <Flex display={{ sm: 'none', lg: 'flex' }} gap='24px'>
          <Button onClick={openOrderPassModal} disabled={disableRequests} style='solid' type='primary' label={t('ticket_fares.order_pass')} />
          <Button onClick={openRenewPassModal} disabled={disableRequests} style='outline' type='primary' label={t('ticket_fares.request_profile')} />
        </Flex>
        <Flex display={{ sm: 'flex', lg: 'none' }} w='100%' direction='column' gap='16px'>
          <Button onClick={openOrderPassModal} disabled={disableRequests} style='solid' type='primary' label={t('ticket_fares.order_pass')} css={{ width: '100%' }} />
          <Button onClick={openRenewPassModal} disabled={disableRequests} style='outline' type='primary' label={t('ticket_fares.request_profile')} css={{ width: '100%' }} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MainHeader
