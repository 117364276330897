import { Flex, Image, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconWithinCircle } from '../../pages/Common/IconWithinCircle'
import config from '../../config/configLoader'

import closeButton from '../../assets/cross.svg'
import alert from '../../assets/alert_dark_yellow.svg'

export const ErrorModal = props => {
  const { t } = useTranslation()

  const cardNumber = props.number

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={{ sm: 'xs', md: 'xs', lg: '', xl: '' }} closeOnOverlayClick={false} autoFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent maxW='600px' padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='row' justifyContent='flex-end' alignItems='center'>
          <Flex as='button' onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction='column' gap='48px' align='center' flex='1'>
          <Flex direction='column' gap='32px' align='center' flex='1'>
            <IconWithinCircle color='warning.400' icon={alert} />
            <Flex direction='column' gap='8px' align='center' w='100%'>
              {props.type === 1 && (
                <>
                  <Text fontWeight='700' fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '24px' }} color='neutral.800' align='center'>
                    {t('pass_migration.change_to_digital_not_possible_title')}
                  </Text>
                  <Text fontWeight='600' fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='neutral.600' align='center'>
                    {t('pass_migration.change_to_digital_not_possible_subtitle')}
                  </Text>
                </>
              )}
              {props.type === 3 && (
                <>
                  <Text fontWeight='700' fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '24px' }} color='neutral.800' align='center'>
                    {t('pass_migration.card_already_migrated_title', { cardNumber })}
                  </Text>
                  <Text fontWeight='600' fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='neutral.600' align='center'>
                    {t('pass_migration.card_already_migrated_subtitle', { operatorEmail: config.info.email })}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>

          <Flex gap='16px' display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}>
            <UbiButton onClick={props.onClose} style='outline' type='neutralLight' label={t('common.ok')} css={{ width: '153px' }} />
          </Flex>
          <Flex gap='16px' alignSelf='stretch' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
            <UbiButton onClick={props.onClose} style='outline' type='neutralLight' label={t('common.ok')} css={{ width: '100%' }} />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
