import { Accordion, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Faq } from './Faq'

import { useEffect } from 'react'
import config from '../../config/configLoader'

export const Faqs = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '100px 130px', xl: '100px 130px' }}
        align='center'
        w='100%'
      >
        <Flex direction='column' gap='20px' align='center' justify='center'>
          <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20'>
            {t('faqs.faqs_title')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
            {t('faqs.faqs_subtitle')}
          </Text>
        </Flex>

        {config.operator === 'trevo' && (
          <Accordion w='100%' allowToggle>
            <Faq
              title={t('faqs.faq_trevo_question_1')}
              description={[
                t('faqs.faq_trevo_answer_1_body_1'),
                t('faqs.faq_trevo_answer_1_body_2'),
                t('faqs.faq_trevo_answer_1_body_3'),
                t('faqs.faq_trevo_answer_1_body_4'),
                t('faqs.faq_trevo_answer_1_body_5'),
                t('faqs.faq_trevo_answer_1_body_6'),
                t('faqs.faq_trevo_answer_1_body_7'),
                t('faqs.faq_trevo_answer_1_body_8'),
                t('faqs.faq_trevo_answer_1_body_9'),
                t('faqs.faq_trevo_answer_1_body_10'),
              ]}
            />
            <Faq title={t('faqs.faq_trevo_question_2')} description={[t('faqs.faq_trevo_answer_2_body_1')]} />
            <Faq title={t('faqs.faq_trevo_question_3')} description={[t('faqs.faq_trevo_answer_3_body_1')]} />
            <Faq title={t('faqs.faq_trevo_question_4')} description={[t('faqs.faq_trevo_answer_4_body_1')]} />
            <Faq title={t('faqs.faq_trevo_question_5')} description={[t('faqs.faq_trevo_answer_5_body_1'), t('faqs.faq_trevo_answer_5_body_2')]} />
            <Faq
              title={t('faqs.faq_trevo_question_6')}
              description={[t('faqs.faq_trevo_answer_6_body_1'), t('faqs.faq_trevo_answer_6_body_2'), t('faqs.faq_trevo_answer_6_body_3')]}
            />
            <Faq title={t('faqs.faq_trevo_question_7')} description={[t('faqs.faq_trevo_answer_7_body_1')]} />
            <Faq title={t('faqs.faq_trevo_question_8')} description={['faqs.faq_trevo_answer_8_body_1']} link='/passenger-rights' />
            <Faq title={t('faqs.faq_trevo_question_9')} description={[t('faqs.faq_trevo_answer_9_body_1')]} />
          </Accordion>
        )}

        {config.operator === 'proximo' && (
          <Accordion w='100%' allowToggle>
            <Faq title={t('faqs.faq_proximo_question_1')} description={[t('faqs.faq_proximo_answer_1_body_1'), t('faqs.faq_proximo_answer_1_body_2')]} />
            <Faq title={t('faqs.faq_proximo_question_2')} description={[t('faqs.faq_proximo_answer_2_body_1')]} />
            <Faq title={t('faqs.faq_proximo_question_3')} description={[t('faqs.faq_proximo_answer_3_body_1')]} />
            <Faq title={t('faqs.faq_proximo_question_4')} description={[t('faqs.faq_proximo_answer_4_body_1')]} />
            <Faq title={t('faqs.faq_proximo_question_5')} description={['faqs.faq_proximo_answer_5_body_1']} link='https://www.cm-faro.pt/43/transporte-escolar.aspx' />
            <Faq title={t('faqs.faq_proximo_question_6')} description={[t('faqs.faq_proximo_answer_6_body_1')]} />
            <Faq title={t('faqs.faq_proximo_question_7')} description={[t('faqs.faq_proximo_answer_7_body_1')]} />
            <Faq title={t('faqs.faq_proximo_question_8')} description={[t('faqs.faq_proximo_answer_8_body_1')]} />
            <Faq title={t('faqs.faq_proximo_question_9')} description={[t('faqs.faq_proximo_answer_9_body_1')]} />
            <Faq
              title={t('faqs.faq_proximo_question_10')}
              description={[t('faqs.faq_proximo_answer_10_body_1'), t('faqs.faq_proximo_answer_10_body_2'), t('faqs.faq_proximo_answer_10_body_3')]}
            />
          </Accordion>
        )}

        {config.operator === 'BEJA' && (
          <Accordion w='100%' allowToggle>
            <Faq
              title={t('faqs.faq_ubeja_question_1')}
              description={[
                t('faqs.faq_ubeja_answer_1_body_1'),
                t('faqs.faq_ubeja_answer_1_body_2'),
                t('faqs.faq_ubeja_answer_1_body_3'),
                t('faqs.faq_ubeja_answer_1_body_4'),
                t('faqs.faq_ubeja_answer_1_body_5'),
                t('faqs.faq_ubeja_answer_1_body_6'),
                t('faqs.faq_ubeja_answer_1_body_7'),
                t('faqs.faq_ubeja_answer_1_body_8'),
              ]}
            />
            <Faq title={t('faqs.faq_ubeja_question_2')} description={[t('faqs.faq_ubeja_answer_2_body_1')]} />
            <Faq title={t('faqs.faq_ubeja_question_3')} description={[t('faqs.faq_ubeja_answer_3_body_1')]} />
            <Faq title={t('faqs.faq_ubeja_question_4')} description={[t('faqs.faq_ubeja_answer_4_body_1')]} />
            <Faq title={t('faqs.faq_ubeja_question_5')} description={[t('faqs.faq_ubeja_answer_5_body_1'), t('faqs.faq_ubeja_answer_5_body_2')]} />
            <Faq
              title={t('faqs.faq_ubeja_question_6')}
              description={[t('faqs.faq_ubeja_answer_6_body_1'), t('faqs.faq_ubeja_answer_6_body_2'), t('faqs.faq_ubeja_answer_6_body_3')]}
            />
            <Faq title={t('faqs.faq_ubeja_question_7')} description={[t('faqs.faq_ubeja_answer_7_body_1')]} />
            <Faq title={t('faqs.faq_ubeja_question_8')} description={['faqs.faq_ubeja_answer_8_body_1']} link='/passenger-rights' />
            <Faq title={t('faqs.faq_ubeja_question_9')} description={[t('faqs.faq_ubeja_answer_9_body_1')]} />
          </Accordion>
        )}

        {config.operator === 'MOBIAZORES' && (
          <Accordion w='100%' allowToggle>
            <Faq
              title={t('faqs.faq_azores_question_1')}
              description={[
                t('faqs.faq_azores_answer_1_body_1'),
                t('faqs.faq_azores_answer_1_body_2'),
                `${t('faqs.faq_azores_answer_1_body_3')} ${t('faqs.faq_azores_answer_1_body_4')}`,
                t('faqs.faq_azores_answer_1_body_5'),
                `${t('faqs.faq_azores_answer_1_body_6')} ${t('faqs.faq_azores_answer_1_body_7')}`,
                t('faqs.faq_azores_answer_1_body_8'),
              ]}
            />
            <Faq
              title={t('faqs.faq_azores_question_2')}
              description={[
                t('faqs.faq_azores_answer_2_body_1'),
                t('faqs.faq_azores_answer_2_body_2'),
                t('faqs.faq_azores_answer_2_body_3'),
                t('faqs.faq_azores_answer_2_body_4'),
                t('faqs.faq_azores_answer_2_body_5'),
              ]}
            />
            <Faq title={t('faqs.faq_azores_question_3')} description={[t('faqs.faq_azores_answer_3_body_1')]} />
            <Faq title={t('faqs.faq_azores_question_4')} description={[t('faqs.faq_azores_answer_4_body_1')]} />
            <Faq title={t('faqs.faq_azores_question_5')} description={[t('faqs.faq_azores_answer_5_body_1')]} />
            <Faq title={t('faqs.faq_azores_question_6')} description={[t('faqs.faq_azores_answer_6_body_1')]} />
            <Faq title={t('faqs.faq_azores_question_7')} description={[t('faqs.faq_azores_answer_7_body_1')]} />
          </Accordion>
        )}
      </Flex>
    </Flex>
  )
}
