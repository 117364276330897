export const AlertEffects = {
  0: 'no_service',
  1: 'reduced_service',
  2: 'significant_delays',
  3: 'detour',
  4: 'additional_service',
  5: 'modified_service',
  6: 'other_effect',
  7: 'unknown_effect',
  8: 'stop_moved',
}

export const AlertCauses = {
  0: 'unknown_cause',
  1: 'other_cause',
  2: 'technical_problem',
  3: 'strike',
  4: 'demonstration',
  5: 'accident',
  6: 'holiday',
  7: 'weather',
  8: 'maintenance',
  9: 'construction',
  10: 'police_activity',
  11: 'medical_emergency',
}

export const AlertLevelMap = {
  0: 2,
  1: 1,
  2: 2,
  3: 1,
  4: 0,
  5: 1,
  6: 0,
  7: 0,
  8: 1,
}
