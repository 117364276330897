import image1 from '../assets/news_image_1.png'
import image2 from '../assets/news_image_2.png'
import image3 from '../assets/news_image_3.png'

/*
Data Structure:
url: News Link
title: News title
description: News short text
source: News publisher or journal / newspaper
duration: Time taken to read news in minutes
image: Image source for the News card
display: 0 - displays in homepage | 1 - displays in news page | 2 - displays in both
*/

export const newsCards = {
  trevo: [
    {
      url: 'https://www.facebook.com/100067397011958/posts/pfbid02QKX8GU12HUpCtZzziiutrwZHqnQ1dZ5abAGxn1gmwzjehNkdcPphKNYWc7qy2mUGl/?sfnsn=mo',
      title: 'Frota totalmente elétrica é um “marco histórico para Évora“',
      description:
        'A entrada em funcionamento da nova frota totalmente elétrica da rede pública de transportes urbanos de Évora é “um marco histórico para Évora e para o país” considerou, hoje, o Vice-Presidente da edilidade eborense.',
      source: 'Évora Notícias',
      duration: 3,
      image: image1,
      display: 2,
    },
    {
      url: 'https://www.facebook.com/100067397011958/posts/pfbid0Z5Zzb9QTNPpVRXyP14HC6yhgLTRJq4qrjyzMfZjj9pkDSssjMv6Z1cNWwZBqCExwl/?sfnsn=mo',
      title: 'Centenas de passageiros já usaram os transportes gratuitos para a Feira de S. João',
      description:
        'O reforço da rede de transportes gratuitos para a Feira de S. João é um dos destaques da edição deste ano, com carreiras de ligação aos parques de estacionamento periféricos.',
      source: 'Évora Notícias',
      duration: 4,
      image: image2,
      display: 2,
    },
    {
      url: 'https://www.facebook.com/watch/?v=924681401945757&extid=NS-UNK-UNK-UNK-AN_GK0T-GK1C&mibextid=2Rb1fB&ref=sharing',
      title: 'Apresentação da frota de Autocarros Elétricos de Évora',
      description: 'A partir de 1 de agosto Évora será a primeira cidade do País a usufruir de uma frota de transportes públicos coletivos, integralmente elétrica.',
      source: 'Évora Notícias',
      duration: 3,
      image: image3,
      display: 2,
    },
  ],
  BEJA: [
    {
      url: 'https://cm-beja.pt/pt/menu/521/transportes-urbanos-e-transportes-a-pedido--taxis-coletivos.aspx',
      title: 'Transportes Urbanos',
      description: 'As freguesias urbanas na cidade de Beja são servidas por seis circuitos de transportes públicos, conhecidos por URBANAS 1, 2, 3, 4, 5A e 5B.',
      source: 'CM Beja',
      duration: 3,
      image: image1,
      display: 2,
    },
    {
      url: 'https://cm-beja.pt/pt/menu/521/transportes-urbanos-e-transportes-a-pedido--taxis-coletivos.aspx',
      title: 'Transportes Urbanos',
      description: 'As freguesias urbanas na cidade de Beja são servidas por seis circuitos de transportes públicos, conhecidos por URBANAS 1, 2, 3, 4, 5A e 5B.',
      source: 'CM Beja',
      duration: 3,
      image: image1,
      display: 1,
    },
  ],
}
