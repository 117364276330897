import { Button, MapMarker, RouteLabel, TransportIcon } from '@ubirider/pick-component-library'
import React, { useEffect, useState } from 'react'
import { Flex, Image, Text, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'

import enlargeIcon from '../../../../../../assets/proportion.svg'
import alertIcon from '../../../../../../assets/map_markers/alert_marker.svg'
import stopMarker from '../../../../../../assets/map_markers/stop_marker.svg'
import alertMarker from '../../../../../../assets/map_markers/stop_marker_alert.svg'
import { AlertModal } from '../../../../../../modals/AlertModal'
import infoIcon from '../../../../../../assets/alert_blue_full.svg'
import alertYellowIcon from '../../../../../../assets/alert_yellow_full.svg'
import dangerIcon from '../../../../../../assets/alert_red_full.svg'
import { useTranslation } from 'react-i18next'
import { SVGDot } from '../../../../../Common/SVGDot'

function StopMarker({ focus, allNetwork, network, alerts, selectedStop, stopSequence, hoveredStop, hoveredRoute }) {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [expanded, expandAlert] = useState(false)
  const [selectedAlert, selectAlert] = useState(null)

  function handleAlert(alert) {
    expandAlert(true)
    selectAlert(alert)
    onOpen()
  }

  useEffect(() => {
    if (!isOpen && expanded) {
      expandAlert(false)
    }
  }, [isOpen])

  return (
    <>
      {focus &&
        stopSequence?.map((stop, i) => (
          <Flex key={`stop${i}`}>
            {stop && network.alerts.some(item => item?.id === stop?.id) && (
              <MapMarker
                position={{ lat: stop.lat, lng: stop.lng }}
                iconUrl={alertIcon}
                size={stop.id === hoveredStop ? { width: 38, height: 38 } : { width: 30, height: 30 }}
                anchor={stop.id === hoveredStop ? { x: 20, y: 45 } : { x: 15, y: 35 }}
                zIndex={stop.id === hoveredStop ? 10000 : 7500}
                popover={
                  <Flex direction='column' borderRadius='8px' gap='16px' zIndex='5000' padding='16px'>
                    {!expanded && (
                      <Flex justify='space-between' gap='16px' align='center'>
                        <Flex gap='16px' align='center' maxW='300px'>
                          <RouteLabel shortName={network.route.shortName} color={network.route.color} textColor={network.route.textColor} />
                          <div className='multiline-ellipsis-header'>{network.alerts.find(item => item.id === stop.id)?.header || null}</div>
                        </Flex>
                        <Button
                          onClick={() => handleAlert(network.alerts.find(item => item.id === stop.id))}
                          type='neutralLight'
                          style='ghost'
                          size='small'
                          leftIconProps={{ src: enlargeIcon }}
                        />
                      </Flex>
                    )}
                  </Flex>
                }
              />
            )}

            {stop?.id === hoveredStop && (
              <MapMarker
                position={{ lat: stop.lat, lng: stop.lng }}
                iconUrl={network.alerts.some(item => item.id === stop.id) ? alertMarker : SVGDot(network.route.color, false)}
                size={{ width: 24, height: 24 }}
                zoomVisibility={12.5}
                zIndex={7500}
                hoverIconUrl={network.alerts.some(item => item.id === stop.id) ? alertMarker : SVGDot(network.route.color, false)}
                popover={
                  <Flex direction='column' borderRadius='8px' gap='16px' zIndex='5000' padding='16px'>
                    <Flex gap='8px'>
                      <TransportIcon type={3} />
                      <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                        {stop.name}
                      </Text>
                    </Flex>
                  </Flex>
                }
              />
            )}

            {alerts.some(item => 'affectedRoutes' in item) && i === 0 && (
              <MapMarker
                position={{ lat: stop.lat, lng: stop.lng }}
                iconUrl={alertIcon}
                size={stop.id === hoveredStop ? { width: 42, height: 42 } : { width: 34, height: 34 }}
                anchor={stop.id === hoveredStop ? { x: 20, y: 45 } : { x: 15, y: 35 }}
                zIndex={stop.id === hoveredStop ? 10000 : 7500}
                zoomVisibility={12.5}
                visible={selectedStop && hoveredRoute !== network.route.id && hoveredRoute !== ''}
                hoverIconUrl={alertIcon}
                popover={
                  <Flex
                    direction='column'
                    padding='16px'
                    borderRadius='8px'
                    maxW={{ sm: 'calc(100vw - 32px)', lg: '600px' }}
                    minW={{ sm: '300px', lg: '300px' }}
                    box-shadow='0px 0px 20px rgba(0, 0, 0, 0.1)'
                    gap='16px'
                  >
                    <Flex direction='row' justify='space-between' align='flex-start' gap='8px'>
                      <Flex gap='16px' maxW={{ sm: '85%', lg: '95%' }} align='flex-start'>
                        <Image
                          src={
                            alerts.find(item => 'affectedRoutes' in item)?.alertLevel === 0
                              ? infoIcon
                              : alerts.find(item => 'affectedRoutes' in item)?.alertLevel === 1
                                ? alertYellowIcon
                                : alerts.find(item => 'affectedRoutes' in item)?.alertLevel === 2
                                  ? dangerIcon
                                  : null
                          }
                        />
                        <Text fontSize='16px' fontWeight='700' color='#1D2939'>
                          {alerts.find(item => 'affectedRoutes' in item)?.header}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex direction='column' gap='16px' borderRadius='8px'>
                      <Text fontSize='14px' color='#1D2939'>
                        {alerts.find(item => 'affectedRoutes' in item)?.description}
                      </Text>
                      <Flex direction='column' gap='16px' align='flex-start' justify='flex-end'>
                        <Wrap spacing={2}>
                          {alerts
                            .find(item => 'affectedRoutes' in item)
                            ?.affectedRoutes?.map((route, i) => (
                              <WrapItem key={i}>
                                <RouteLabel
                                  shortName={allNetwork?.routes[route?.routeID - 1].shortName}
                                  color={allNetwork?.routes?.find(obj => obj.id === route.routeID)?.color || '#1D2939'}
                                  textColor={allNetwork?.routes.find(obj => obj.id === route.routeID)?.textColor || 'white'}
                                />
                              </WrapItem>
                            ))}
                        </Wrap>
                        <Flex direction='column'>
                          <Flex gap='8px'>
                            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                              {t('home.effective_from')}
                            </Text>
                            <Text fontSize='13px' color='#1D2939'>
                              {new Date(alerts.find(item => 'affectedRoutes' in item)?.startDate).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              })}
                            </Text>
                          </Flex>
                          {alerts.find(item => 'affectedRoutes' in item)?.endDate && (
                            <Flex gap='8px'>
                              <Text fontSize='14px' fontWeight='600' color='#1D2939'>
                                {t('network_schedule.to')}
                              </Text>
                              <Text fontSize='13px' color='#1D2939'>
                                {new Date(alerts.find(item => 'affectedRoutes' in item)?.endDate).toLocaleString('en-US', {
                                  month: 'short',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                })}
                              </Text>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                }
              />
            )}
          </Flex>
        ))}

      {/* focus && selectedStop && hoveredStops?.map((stop, i) => (
                <MapMarker
                    position={{lat: stop.lat, lng: stop.lng}}
                    iconUrl={SVGDot(allNetwork.routes.find(route => route.id === hoveredRoute)?.color, false)}
                    size={stop.id === hoveredStop ? { width: 24, height: 24 } : { width: 16, height: 16 }}
                    zoomVisibility={12.5}
                    visible={selectedStop && hoveredRoute !== network.route.id && hoveredRoute !== ''}
                    zIndex={stop.id === hoveredStop ? 10000 : 5000}
                    hoverIconUrl={SVGDot(allNetwork.routes.find(route => route.id === hoveredRoute)?.color, true)}
                    popover={
                        <Flex direction='column' borderRadius='8px' gap='16px' zIndex='5000' padding='16px'>
                            <Flex gap='8px'>
                                <TransportIcon type={3} />
                                <Text fontSize='14px' fontWeight='700' color='#1D2939'>{stop.name}</Text>
                            </Flex>
                        </Flex>
                    }
                />
            ))*/}

      {!focus &&
        stopSequence?.map(stop => (
          <>
            {stop?.id === hoveredStop && (
              <MapMarker
                position={{ lat: stop.lat, lng: stop.lng }}
                iconUrl={stopMarker}
                size={stop.id === hoveredStop ? { width: 24, height: 24 } : { width: 16, height: 16 }}
                zoomVisibility={12.5}
                zIndex={stop.id === hoveredStop ? 7500 : 5000}
                popover={
                  <Flex direction='column' borderRadius='8px' gap='16px' zIndex='5000' padding='16px'>
                    <Flex gap='8px'>
                      <TransportIcon type={3} />
                      <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                        {stop.name}
                      </Text>
                    </Flex>
                  </Flex>
                }
              />
            )}
          </>
        ))}

      {/* !focus && Object.values(alerts.entities).some(item => item.id === network.id) &&
                <MapMarker
                    position={{lat: network.lat, lng: network.lng}}
                    iconUrl={alertIcon}
                    size={network.id === hoveredStop ? { width: 42, height: 38 } : { width: 34, height: 30 }}
                    anchor={network.id === hoveredStop ? {x: 20, y: 45} : {x: 15, y: 35}}
                    zIndex={network.id === hoveredStop ? 10000 : 5000}
                    zoomVisibility={15}
                    hoverIconUrl={alertIcon}
                    tooltip={
                        <Flex direction='column' borderRadius='8px' gap='16px' zIndex='5000' padding='16px'>
                            { !expanded &&
                                <Flex justify='space-between' gap='16px' align='center'>
                                    <Flex gap='16px' align='center' maxW='300px'>
                                        <RouteLabel shortName={network.shortName} color={network.color} textColor={network.textColor} />
                                        <div className='multiline-ellipsis-header'>
                                            { Object.values(alerts.entities).find(item => item.id === network.id)?.header || null}
                                        </div>
                                    </Flex>
                                    <Button
                                        onClick={() => handleAlert(Object.values(alerts.entities).find(item => item.id === network.id))}
                                        type='neutralLight'
                                        style='ghost'
                                        size='small'
                                        leftIconSrc={enlargeIcon}
                                    />
                                </Flex>
                            }
                        </Flex>
                    }
                />
            */}

      <AlertModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} alert={selectedAlert} network={network} type={0} />
    </>
  )
}

export default StopMarker
