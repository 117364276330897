export const RequirementTemplates = {
  trevo: {
    'Requerimento de Passe de Antigo Combatente': {
      name: 'Requerimento Passe Antigo Combatente IMT',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/templates/Requerimento+Passe+Antigo+Combatente+IMT.pdf',
    },
    'Requerimento de adesão ao Passe Gratuito Jovem': {
      name: 'Requiremento Passe Gratuito Jovem',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/trevo/templates/Requiremento+Passe+Gratuito+Jovem.pdf',
    },
  },
  proximo: {
    'Requerimento de Passe de Antigo Combatente': {
      name: 'Requerimento Passe Antigo Combatente IMT',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/templates/Requerimento+Passe+Antigo+Combatente+IMT.pdf',
    },
    'Requerimento de adesão ao Passe Gratuito Jovem': {
      name: 'Requerimento Passe Gratuito Jovem',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/templates/Requiremento+Passe+Gratuito+Jovem.pdf',
    },
  },
  BEJA: {
    'Requerimento de Passe de Antigo Combatente': {
      name: 'Requerimento Passe Antigo Combatente IMT',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/templates/Requerimento+Passe+Antigo+Combatente+IMT.pdf',
    },
    'Requerimento de adesão ao Passe Gratuito Jovem': {
      name: 'Requerimento Passe Gratuito Jovem',
      link: 'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/ubeja/templates/Requiremento+Passe+Gratuito+Jovem.pdf',
    },
  },
}
