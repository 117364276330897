import { Flex, Image } from '@chakra-ui/react'
import { useEffect } from 'react'
import config from '../../config/configLoader'

import bgDesktop from '../../assets/about_bg_desktop.png'
import bgMobile from '../../assets/about_bg_mobile.png'
import { AboutMainSection } from './components/AboutMainSection'

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' flex='1' w='100%'>
      <Image display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} src={bgMobile} />
      <Flex
        flex='1'
        bg={{
          sm: config.themeTokens.colors.primary400,
          lg: `linear-gradient(61deg, ${config.themeTokens.colors.primary400} 0%, ${config.themeTokens.colors.primary200} 100%)`,
        }}
      >
        <AboutMainSection />
        <Image display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} src={bgDesktop} w='720px' />
      </Flex>
    </Flex>
  )
}

export default About
