import { Flex, Image } from '@chakra-ui/react'
import React from 'react'

import trevoFairScheduleDesktop from '../../../../../../assets/fair_schedules_trevo_desktop.png'
import trevoFairScheduleMobile from '../../../../../../assets/fair_schedules_trevo_mobile.png'

export function TrevoFairSchedule() {
  return (
    <Flex h={{ sm: '716px' }} bg='#fcb227' w='100%' align='center' justify='center' position='relative' overflow='hidden'>
      <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} w={'100%'} justify='center' align='center'>
        <Image src={trevoFairScheduleDesktop} objectFit='cover' objectPosition={'left center'} w={'100%'} />
      </Flex>

      <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} w={'100%'} justify='center' align='center'>
        <Image src={trevoFairScheduleMobile} h={'716px'} objectFit={'fill !important'} objectPosition={'center!important'} />
      </Flex>
    </Flex>
  )
}
