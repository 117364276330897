import { Divider, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import config from '../../../../config/configLoader'
import { Button } from '@ubirider/pick-component-library'
import { TicketInfo } from './components/TicketInfo'
import { PickPromoBanner } from './components/PickPromoBanner'

import download from '../../../../assets/download.svg'
import logoMini from '../../../../assets/main_logo_mini.svg'
import minus from '../../../../assets/arrow_up.svg'
import plus from '../../../../assets/arrow_down.svg'

function TicketRates({ loading, prices, expandedStates, setExpandedState, expandedVariations, setExpandedVariation }) {
  const { t } = useTranslation()

  return (
    <Flex
      direction='column'
      gap={{ sm: '32px', md: '32px', lg: '48px', xl: '48px' }}
      padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 70px', xl: '90px 130px' }}
      bg='#F9F9FB'
      align='center'
      justify='center'
    >
      {config.sections.ticket_info && <TicketInfo />}

      <Flex direction='column' gap='16px' align='center'>
        <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#101828' textAlign='center'>
          {t('ticket_fares.ticket_rates_title')}
        </Text>
        <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828' textAlign='center'>
          {t('ticket_fares.ticket_rates_description')}
        </Text>
      </Flex>

      {config.sections.pick && <PickPromoBanner />}

      {loading && (
        <Flex align='center' justify='center' minW='100%' h='290px'>
          <Spinner thickness='5px' color={config.mainColor} width='50px' height='50px' />
        </Flex>
      )}

      {!loading && (
        <Flex direction='column' gap='24px' align='flex-start' justify={{ sm: 'center', md: 'center', lg: 'space-around', xl: 'space-around' }} w='100%'>
          {prices && config.sections.zone_map && (
            <>
              <Flex display={{ sm: 'none', lg: 'flex' }} w='100%' justify='flex-end' align='center'>
                <Button
                  onClick={() => window.open(config.info.zone_map, '_blank')}
                  label={t('ticket_fares.download_zoning_mapa')}
                  size={'small'}
                  type={'neutralLight'}
                  style={'outline'}
                  leftIconProps={{ src: download }}
                  css={{ height: '36px' }}
                />
              </Flex>
              <Flex display={{ sm: 'flex', lg: 'none' }} w='100%' justify='flex-end' align='center'>
                <Button
                  onClick={() => window.open(config.info.zone_map, '_blank')}
                  label={t('ticket_fares.download_zoning_mapa')}
                  size={'small'}
                  type={'neutralLight'}
                  style={'outline'}
                  leftIconProps={{ src: download }}
                  css={{ height: '48px', width: '100%' }}
                />
              </Flex>
            </>
          )}

          {prices &&
            prices.map((fare, i) => (
              <Flex
                key={`fare_${i}_${fare.fareID}`}
                direction='column'
                padding='16px 24px'
                gap='8px'
                align={{ sm: 'center', md: 'center', lg: 'center', xl: 'flex-start' }}
                filter='drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1))'
                w='100%'
                border='1px solid #CACFD8'
                borderRadius='8px'
                bg='white'
              >
                <Flex direction='column' w='100%' gap='8px'>
                  <Flex w='100%' justify='space-between' align='center' gap='16px'>
                    <Flex gap='16px' align='center'>
                      <Image src={logoMini} w='24px' h='20px' />
                      <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color={config.mainColor}>
                        {fare.fareName}
                      </Text>
                    </Flex>
                    {fare.tickets.length > 3 && (
                      <Button
                        onClick={() => setExpandedState(i, !expandedStates[i])}
                        label={expandedStates[i] ? t('common.view_less') : t('home.view_all')}
                        size={'small'}
                        type={'secondary'}
                        style={'ghost'}
                        css={{ height: '36px' }}
                      />
                    )}
                  </Flex>
                  <Divider border='1px solid #D0D5DD' />
                </Flex>

                {prices &&
                  !expandedStates[i] &&
                  fare.tickets?.slice(0, 3).map((ticket, index) => (
                    <>
                      {ticket.variations.length > 1 && (
                        <>
                          <Flex key={`ticket_${i}_${index}`} justify='space-between' align='center' w='100%'>
                            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                              {
                                ticket.variations
                                  .filter(ticket => ticket.price !== 0)
                                  .sort((a, b) => a.price - b.price)[0]
                                  ?.compoundName.split(' - ')[0]
                              }
                            </Text>
                            <Flex gap='16px' align='center'>
                              <Flex gap='8px' align='center'>
                                <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
                                  {t('widget_fare_search.from_price')}
                                </Text>
                                <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
                                  €
                                  {(ticket.variations.filter(ticket => ticket.price !== 0).sort((a, b) => a.price - b.price)[0]?.price / 100).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Text>
                              </Flex>
                              <Flex as='button' onClick={() => setExpandedVariation(index, !expandedVariations[index])}>
                                <Image src={expandedVariations[index] ? minus : plus} w='24px' />
                              </Flex>
                            </Flex>
                          </Flex>
                          {expandedVariations[index] && (
                            <Flex direction='column' w='100%' gap='8px'>
                              {ticket.variations
                                .sort((a, b) => a.price - b.price)
                                .map(info => (
                                  <>
                                    {(info.salesChannels === null || !info.salesChannels.every(channel => channel === 5)) && info.price !== 0 && (
                                      <>
                                        <Divider border='1px solid #D0D5DD' />
                                        <Flex justify='space-between' align='center' w='100%'>
                                          <Flex justify='space-between' align='center' pl='32px' w='100%'>
                                            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} color='#282828'>
                                              {info?.variationName || info?.compoundName.split(' - ')[0]}
                                            </Text>
                                            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                                              €
                                              {(info.price / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              })}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </>
                                    )}
                                  </>
                                ))}
                            </Flex>
                          )}
                        </>
                      )}
                      {ticket.variations.length === 1 && (
                        <Flex justify='space-between' align='center' w='100%'>
                          <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                            {ticket.variations[0]?.compoundName}
                          </Text>
                          <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
                            €
                            {(ticket.variations[0]?.price / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Text>
                        </Flex>
                      )}
                      {ticket.variations.length === 0 && (
                        <Flex justify='space-between' align='center' w='100%'>
                          <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                            {ticket.variations[0]?.compoundName}
                          </Text>
                          <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
                            €
                            {(ticket.variations[0]?.price / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Text>
                        </Flex>
                      )}
                      {((expandedStates[i] && index < fare.tickets.length - 1) || (!expandedStates[i] && index < Math.min(2, fare.tickets.length - 1))) && (
                        <Divider border='1px solid #D0D5DD' />
                      )}
                    </>
                  ))}
                {prices &&
                  expandedStates[i] &&
                  fare.tickets?.map((ticket, index) => (
                    <>
                      {ticket.variations.length > 1 && (
                        <>
                          <Flex key={`fare_expanded${i}_${index}`} justify='space-between' align='center' w='100%'>
                            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                              {
                                Object.values(ticket.variations)
                                  .filter(ticket => ticket.price !== 0)
                                  .sort((a, b) => a.price - b.price)[0]
                                  ?.compoundName.split(' - ')[0]
                              }
                            </Text>
                            <Flex gap='16px' align='center'>
                              <Flex gap='8px' align='center'>
                                <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
                                  {t('widget_fare_search.from_price')}
                                </Text>
                                <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
                                  €
                                  {(
                                    Object.values(ticket.variations)
                                      .filter(ticket => ticket.price !== 0)
                                      .sort((a, b) => a.price - b.price)[0]?.price / 100
                                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                </Text>
                              </Flex>
                              <Flex as='button' onClick={() => setExpandedVariation(index, !expandedVariations[index])}>
                                <Image src={expandedVariations[index] ? minus : plus} w='24px' />
                              </Flex>
                            </Flex>
                          </Flex>
                          {expandedVariations[index] && (
                            <Flex direction='column' w='100%' gap='8px'>
                              {ticket.variations
                                .sort((a, b) => a.price - b.price)
                                .map(info => (
                                  <>
                                    {(info.salesChannels === null || !info.salesChannels.every(channel => channel === 5)) && info.price !== 0 && (
                                      <>
                                        <Divider border='1px solid #D0D5DD' />
                                        <Flex justify='space-between' align='center' w='100%'>
                                          <Flex justify='space-between' align='center' pl='32px' w='100%'>
                                            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} color='#282828'>
                                              {info?.variationName || info?.compoundName.split(' - ')[0]}
                                            </Text>
                                            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                                              €
                                              {(info.price / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              })}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </>
                                    )}
                                  </>
                                ))}
                            </Flex>
                          )}
                        </>
                      )}
                      {ticket.variations.length === 1 && (
                        <Flex justify='space-between' align='center' w='100%'>
                          <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='600' color='#282828'>
                            {ticket.ticketName}
                          </Text>
                          <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
                            €{(ticket.variations[0].price / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                          </Text>
                        </Flex>
                      )}
                      {((expandedStates[i] && index < fare.tickets.length - 1) || (!expandedStates[i] && index < Math.min(2, fare.tickets.length - 1))) && (
                        <Divider border='1px solid #D0D5DD' />
                      )}
                    </>
                  ))}
                {fare.description && (
                  <Flex gap='8px' padding='16px' mt='8px' bg='#F2F4F7' rounded='4px' w='100%'>
                    <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#1D2939'>
                      {fare?.description}
                    </Text>
                  </Flex>
                )}
              </Flex>
            ))}
        </Flex>
      )}

      {config.sections.pick && <PickPromoBanner mobile />}
    </Flex>
  )
}

export default TicketRates
