import { useTranslation } from 'react-i18next'
import { Flex, Text, Image } from '@chakra-ui/react'

import greenCheck from '../../../../../../assets/check_circle_purple.svg'
import phone from '../../../../../../assets/pick_phone_routes.png'
import appStore from '../../../../../../assets/app_store.svg'
import playStore from '../../../../../../assets/google_play.svg'
import appGallery from '../../../../../../assets/app_gallery.svg'
import google from '../../../../../../assets/google_play_black.svg'
import apple from '../../../../../../assets/apple_black.svg'
import huawei from '../../../../../../assets/huawei_black.svg'
import React from 'react'
import { Link } from 'react-router-dom'

export function PickAdPage() {
  const { t } = useTranslation()

  return (
    <Flex
      h={{ sm: '716px', lg: '100%' }}
      w='100%'
      direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      justify='end'
      align='center'
      padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px', xl: '90px 130px' }}
      gap={{ sm: '32px', md: '32px', lg: '17px', xl: '17px' }}
      bg='#F2EEFA'
      position='relative'
      overflow='hidden'
    >
      <Flex direction='column' gap='32px' height={{ sm: '716px', lg: '100%' }}>
        <Text textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '36px', xl: '36px' }} fontWeight='700' color='#7537FA' lineHeight={{ sm: '38px', lg: '44px' }}>
          {t('home.pick_title')}
        </Text>
        <Text textAlign='left' fontSize={{ sm: '14px', md: '14px', lg: '18px', xl: '18px' }} fontWeight='600' color='#7537FA'>
          {t('home.pick_subtitle')}
        </Text>
        <Flex direction='column' align='flex-start' gap='8px' maxW={{ lg: '80%', xl: '100%' }}>
          <Text fontSize='14px' fontWeight='600' color='#282828'>
            {t('home.pick_features_included')}
          </Text>
          <Flex gap='16px' align='center'>
            <Image src={greenCheck} w='24px !important' h='24px' />
            <Text textAlign='left' fontSize='14px' fontWeight='600' color='#282828'>
              {t('home.pick_feature_list_1')}
            </Text>
          </Flex>
          <Flex gap='16px' align='center'>
            <Image src={greenCheck} w='24px !important' h='24px' />
            <Text textAlign='left' fontSize='14px' fontWeight='600' color='#282828'>
              {t('home.pick_feature_list_2')}
            </Text>
          </Flex>
          <Flex gap='16px' align='center'>
            <Image src={greenCheck} w='24px !important' />
            <Text textAlign='left' fontSize='14px' fontWeight='600' color='#282828'>
              {t('home.pick_feature_list_3')}
            </Text>
          </Flex>
        </Flex>
        <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} direction='column' gap='16px' align='flex-start'>
          <Text fontSize='20px' fontWeight='700' color='#282828'>
            {t('home.download_now')}
          </Text>
          <Flex gap='16px'>
            <Link to={'https://apps.apple.com/pt/app/pick-hub/id1343817323'} target={'_blank'}>
              <Image src={appStore} />
            </Link>
            <Link to={'https://play.google.com/store/apps/details?id=ubirider.ubirider'} target={'_blank'}>
              <Image src={playStore} />
            </Link>
            <Link to={'https://appgallery.huawei.com/app/C102997355'} target={'_blank'}>
              <Image src={appGallery} />
            </Link>
          </Flex>
        </Flex>
      </Flex>

      <Flex display={{ sm: 'none', md: 'none', lg: 'none', xl: 'flex' }}>
        <Image src={phone} w='850px !important' />
      </Flex>

      <Flex
        display={{ sm: 'flex', md: 'flex', lg: 'flex', xl: 'none' }}
        position='absolute'
        bottom='-77px'
        right={{ sm: '-57px', lg: '-32px' }}
        w={{ sm: '270px !important', lg: '470px !important' }}
      >
        <Image src={phone} objectFit='contain' />
      </Flex>

      <Flex display={{ sm: 'flex', lg: 'none' }} direction='column' gap='16px' align='center' justify='center' zIndex='5'>
        <Text fontSize='18px' fontWeight='700' color='#282828'>
          {t('home.download_now')}
        </Text>
        <Flex direction='row' gap='8px'>
          <Flex
            align='center'
            justify='center'
            bg='white'
            border='1px solid #D0D5DD'
            borderRadius='4px'
            padding='8px 32px'
            as='button'
            onClick={() => window.open('https://play.google.com/store/apps/details?id=ubirider.ubirider', '_blank')}
          >
            <Image src={google} minW='28px !important' />
          </Flex>
          <Flex
            align='center'
            justify='center'
            bg='white'
            border='1px solid #D0D5DD'
            borderRadius='4px'
            padding='8px 32px'
            as='button'
            onClick={() => window.open('https://apps.apple.com/pt/app/pick-hub/id1343817323', '_blank')}
          >
            <Image src={apple} minW='28px !important' />
          </Flex>
          <Flex
            align='center'
            justify='center'
            bg='white'
            border='1px solid #D0D5DD'
            borderRadius='4px'
            padding='8px 32px'
            as='button'
            onClick={() => window.open('https://appgallery.huawei.com/app/C102997355', '_blank')}
          >
            <Image src={huawei} minW='36px !important' />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
