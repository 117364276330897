import { Accordion, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { RequirementCard } from './components/RequirementCard'
import config from '../../../../config/configLoader'

export const PassRequirements = () => {
  const { t } = useTranslation()

  return (
    <Flex direction='column' padding={{ sm: '32px 24px', md: '32px 24px', lg: '90px 70px', xl: '90px 130px' }} gap='48px' bg='#F9F9FB' align='center' justify='center'>
      <Flex direction='column' gap='16px' align='center'>
        <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#101828' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
          {t('ticket_fares.pass_requirements_title')}
        </Text>
        <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#282828' textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}>
          {t('ticket_fares.pass_requirements_description')}
        </Text>
      </Flex>

      <Flex direction='column' gap={{ sm: '24px', md: '24px', lg: '16px', xl: '16px' }} w='100%' align='flex-start'>
        <Accordion w='100%' allowToggle>
          {Array.from({ length: config.sections.pass_requirements }, (_, index) => (
            <RequirementCard
              key={index}
              title={t(`ticket_fares.pass_name_${index + 1}`)}
              info={t(`ticket_fares.additional_pass_info_${index + 1}`)}
              proof={t(`ticket_fares.proof_documents_${index + 1}`)}
            />
          ))}
        </Accordion>
      </Flex>
    </Flex>
  )
}
