import config from '../config/configLoader'
import { customLog } from '../helpers/log'
const { REACT_APP_MY_ENV, REACT_APP_CONFIG } = process.env

export const getBFFUrl = () => {
  customLog([REACT_APP_MY_ENV, REACT_APP_CONFIG])
  if (REACT_APP_CONFIG === 'local') {
    return 'http://localhost:4206/' + REACT_APP_MY_ENV
  }

  switch (REACT_APP_MY_ENV) {
    case 'dev':
      return 'https://external-website-bff-dev.api.ubirider.com'
    case 'tests':
      return 'https://external-website-bff-tests.api.ubirider.com'
    case 'prod':
      return 'https://external-website-bff.api.ubirider.com'
    default:
      return ''
  }
}

export const getVivaWalletSourceCode = () => {
  if (config.operator === 'trevo') {
    switch (REACT_APP_MY_ENV) {
      case 'dev':
        return '4308'
      case 'tests':
        return '3824'
      case 'prod':
        return '8664'
      default:
        return ''
    }
  }
  if (config.operator === 'proximo') {
    switch (REACT_APP_MY_ENV) {
      case 'dev':
        return '4481'
      case 'tests':
        return '8133'
      case 'prod':
        return '7854'
      default:
        return ''
    }
  }

  if (config.operator === 'BEJA') {
    switch (REACT_APP_MY_ENV) {
      case 'dev':
        return '2275'
      case 'tests':
        return '5312'
      case 'prod':
        return '5592'
      default:
        return ''
    }
  }

  if (config.operator === 'MOBIAZORES') {
    switch (REACT_APP_MY_ENV) {
      case 'dev':
        return '4014'
      case 'tests':
        return '5941'
      case 'prod':
        return '1147'
      default:
        return ''
    }
  }
}

export const getCheckoutURL = () => {
  switch (REACT_APP_MY_ENV) {
    case 'dev':
      return 'https://demo.vivapayments.com/web/checkout'
    case 'tests':
      return 'https://demo.vivapayments.com/web/checkout'
    case 'prod':
      return 'https://www.vivapayments.com/web/checkout'
    default:
      return 'https://www.vivapayments.com/web/checkout'
  }
}
