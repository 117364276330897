import { Divider, Flex, Image, Link, Text, useDisclosure } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Button, DateInput, FileInput, FormItem, PhoneNumberInput, TextInput, validatePhoneNumber } from '@ubirider/pick-component-library'
import { Controller, useForm } from 'react-hook-form'
import { getPrices, updateTicketingInfo, validatePass } from '../../api/requests'
import { ErrorModal } from '../../modals/ErrorModal'
import { OrderPassModal } from '../../modals/OrderPassModal/OrderPassModal'
import { CancelModal } from '../../modals/CancelModal'
import { RedirectModal } from '../../modals/RedirectModal'

import bgLogo from '../../assets/pass_migration_phone.png'
import camera from '../../assets/camera_avatar.svg'
import greenCheck from '../../assets/check_circle_green.svg'
import appStore from '../../assets/app_store.svg'
import playStore from '../../assets/google_play.svg'
import appGallery from '../../assets/app_gallery.svg'
import google from '../../assets/google_color.svg'
import apple from '../../assets/apple_color.svg'
import huawei from '../../assets/huawei_color.svg'
import infoIconBlue from '../../assets/info_blue.svg'
import infoIcon from '../../assets/information_grey.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { customError, customLog } from '../../helpers/log'
import { DateTime } from 'luxon'
import { useCustomToast } from '../../hooks/useToast'

function PassMigration() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const showCustomToast = useCustomToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isOpen: isOpenOrderPass, onOpen: onOpenOrderPass, onClose: onCloseOrderPass } = useDisclosure()

  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure()

  const { isOpen: isOpenRedirect, onOpen: onOpenRedirect, onClose: onCloseRedirect } = useDisclosure()

  const {
    control,
    watch,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pass_name: null,
      pass_number: null,
      name: null,
      email: null,
      address: null,
      postal_code: null,
      city: null,
      phone: null,
      birthdate: null,
      card_id: null,
      tax_id: null,
    },
  })

  const email = watch('email')
  const pass_number = watch('pass_number')
  const pass_type = watch('pass_type')

  const fileInputRef = useRef(null)

  const [formStep, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [photoFile, setPhotoFile] = useState(null)
  const [profilePhoto, setProfilePhoto] = useState(null)
  const [prices, setPrices] = useState(null)
  const [typePrices, setTypePrices] = useState(null)
  const [requiredDocs, setRequiredDocs] = useState(null)
  const [repeatedCardID, setRepeatedCardID] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [orderCode, setOrderCode] = useState(null)
  const [errorType, setErrorType] = useState(null)

  const { name, number } = useParams()

  const minDate = DateTime.fromISO('1900-01-01T00:00:00')
  const maxDate = DateTime.now()

  useEffect(() => {
    window.scrollTo(0, 0)

    const handleTicketInfo = async () => {
      const prices = await getPrices()
      customLog(prices)

      const sortedFares = Object.values(prices.fares)
        .slice()
        .sort((a, b) => {
          const fareNameA = a.fareName.toUpperCase()
          const fareNameB = b.fareName.toUpperCase()
          if (fareNameA < fareNameB) {
            return -1
          }
          if (fareNameA > fareNameB) {
            return 1
          }
          return 0
        })

      const priceData = sortedFares.find(fare => fare.fareName === 'Monthly passes' || fare.fareName === 'Passes' || fare.fareName === 'Passes mensais' || null)
      const filteredVariations = Object.values(priceData.tickets).flatMap(fare =>
        Object.values(fare.variations).filter(variation => variation.salesChannels === null || variation.salesChannels.includes(3))
      )

      const sortedVariations = filteredVariations.slice().sort((a, b) => {
        const compoundNameA = a.compoundName.toUpperCase()
        const compoundNameB = b.compoundName.toUpperCase()
        if (compoundNameA < compoundNameB) {
          return -1
        }
        if (compoundNameA > compoundNameB) {
          return 1
        }
        return 0
      })

      const resPrices = {}
      priceData.travelCardIDs.forEach(id => {
        resPrices[id] = {
          digital: prices.travelCards[id].digitalPrice,
          physical: prices.travelCards[id].physicalPrice,
        }
      })

      setTypePrices(resPrices)
      setPrices(sortedVariations)
    }

    if (!prices) {
      handleTicketInfo()
    }
    if (window.location.pathname === '/pass-migration') {
      setStep(1)
    } else if (
      decodeURIComponent(window.location.pathname) === `/pass-migration/${decodeURIComponent(name)}/${number}` &&
      data?.pass_name !== decodeURIComponent(name) &&
      data?.pass_number !== number
    ) {
      handlePassValidation({ pass_name: decodeURIComponent(name), pass_number: number })
    } else if (window.location.pathname === '/pass-migration/success') {
      setStep(3)
    }
  }, [window.location.pathname])

  const handleProfilePhotoUpload = () => {
    setLoadingUpload(true)
    fileInputRef.current.click()
    setLoadingUpload(false)
  }

  const handleSelectedProfilePhoto = event => {
    setLoadingUpload(true)
    const file = event.target.files[0]
    if (file) {
      setPhotoFile(file)
      setProfilePhoto(URL.createObjectURL(file))
    }
    setLoadingUpload(false)
  }

  const handleClearPhoto = () => {
    setPhotoFile(null)
    setProfilePhoto(null)
    fileInputRef.current.value = null
  }

  const handlePassValidation = async values => {
    setLoading(true)
    try {
      const res = await validatePass(values.pass_name, values.pass_number)
      customLog(res)
      if (res.code === 0) {
        setRequiredDocs(res?.data?.requiredDocuments || [])
        setData({
          pass_name: values.pass_name,
          pass_number: values.pass_number,
          travelCardRequestID: res?.data?.travelCardRequestID,
          profileID: res?.data?.profileID,
          profileName: res?.data?.profileName,
        })
        setStep(formStep + 1)
        navigate(`/pass-migration/${values.pass_name}/${values.pass_number}`)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        showCustomToast('success', t('pass_migration.card_details_validation_success'))
      } else {
        setErrorType(res.code)
        onOpen()
      }
    } catch (error) {
      customError(error)
    }
    setLoading(false)
  }

  const handlePassMigration = async values => {
    setRepeatedCardID(false)
    setInvalidEmail(false)
    setLoading(true)
    try {
      const docFiles = []
      requiredDocs.forEach(doc => docFiles.push(values[doc]))
      const info = {
        ...data,
        name: values.name,
        email: values.email,
        address: values.address,
        postal_code: values.postal_code,
        city: values.city,
        phone: values.phone,
        birthdate: values.birthdate.toISO(),
        card_id: values.card_id,
        tax_id: values?.tax_id,
        passType: pass_type,
        photo: photoFile,
      }
      setData(info)
      const res = await updateTicketingInfo(info, docFiles)
      if (res.code === 0) {
        setStep(formStep + 1)
        navigate('/pass-migration/success')
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        reset({
          pass_name: null,
          pass_number: null,
        })
      } else if (res?.message === 'user_exists') {
        if (res?.data?.repeatedFields.includes('idDocument')) {
          setError('card_id', { message: t('pass_migration.id_no_not_associated_with_card_hint') })
          setRepeatedCardID(true)
        }
      } else if (res?.message === 'pick_account_not_found') {
        setError('email', { message: t('pass_migration.enter_same_registration_email_hint') })
        setInvalidEmail(true)
      }
      setLoading(false)
    } catch (error) {
      customError(error)
    }
    setLoading(false)
  }

  return (
    <Flex direction='column'>
      {invalidEmail && (
        <Flex
          direction='column'
          gap='8px'
          padding='16px'
          align='flex-start'
          justify='center'
          bg='#FEECAA'
          boxShadow='0px 4px 10px 1px rgba(0, 0, 0, 0.1)'
          w='100%'
          zIndex='50'
          position='sticky'
          top='70px'
        >
          <Text fontSize='14px' fontWeight='700' color='#91600E'>
            {t('ticket_fares.digital_pass_pick_hub_email_not_found_title')}
          </Text>
          <Text fontSize='13px' color='#1D2939'>
            {t('pass_migration.digital_pass_migration_pick_hub_email_not_found_subtitle')}
          </Text>
        </Flex>
      )}

      {repeatedCardID && (
        <Flex
          direction='column'
          gap='8px'
          padding='16px'
          align='flex-start'
          justify='center'
          bg='#FEECAA'
          boxShadow='0px 4px 10px 1px rgba(0, 0, 0, 0.1)'
          w='100%'
          zIndex='50'
          position='sticky'
          top='70px'
        >
          <Text fontSize='14px' fontWeight='700' color='#91600E'>
            {t('pass_migration.id_no_not_associated_with_card_title')}
          </Text>
          <Text fontSize='13px' color='#1D2939'>
            {t('pass_migration.id_no_not_associated_with_card_subtitle')}
          </Text>
        </Flex>
      )}

      {formStep === 1 && (
        <>
          <Flex
            direction={{ sm: 'column', lg: 'row' }}
            align='center'
            justify='space-between'
            gap={{ sm: '48px', lg: '8px' }}
            bg={{ sm: 'linear-gradient(193deg, #444CE7 0%, rgba(128, 152, 249, 0.20) 100%)', lg: 'linear-gradient(140deg, #444CE7 0%, rgba(128, 152, 249, 0.20) 100%)' }}
            width='100%'
            padding={{ sm: '48px 24px 8px 24px', md: '48px 24px 8px 24px', lg: '0 0 100px 100px', xl: '0 0 100px 130px' }}
            position='relative'
            overflow='hidden'
          >
            <Flex
              display={{ sm: 'none', md: 'flex' }}
              direction='column'
              gap={{ sm: '32px', lg: '24px' }}
              w={{ sm: '100%', lg: '95%', xl: '50%' }}
              h='100%'
              pt={{ lg: '100px' }}
              align='flex-start'
              justify='center'
            >
              <Text color='white' fontSize='48px' fontWeight='600'>
                <Trans i18nKey='pass_migration.banner_digital_pass_migration' />
              </Text>
            </Flex>
            <Flex w='800px' h={{ sm: '200px', md: undefined }}>
              <Image
                src={bgLogo}
                w={{ sm: '325px', md: '405px', lg: '585px', xl: '600px' }}
                position='absolute'
                bottom={{ sm: '-10px', lg: '-10px' }}
                right={{ sm: '25px', lg: '-135px', xl: '0' }}
                css={{ WebkitTransform: 'translate3d(0, 0, 0)' }}
              />
            </Flex>
          </Flex>

          <Flex display={{ sm: 'flex', md: 'none' }} padding='48px 54px' justify='center' align='center' bg='white' w='100%'>
            <Text color='#002239' fontSize='20px' fontWeight='600' textAlign='center' alignSelf='stretch'>
              <Trans i18nKey='pass_migration.banner_digital_pass_migration' />
            </Text>
          </Flex>

          <Flex
            direction='column'
            align='center'
            justify='center'
            gap={{ sm: '56px', lg: '90px' }}
            bg='#F9F9FB'
            width='100%'
            padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px 90px 130px', xl: '90px 130px 90px 130px' }}
            position='relative'
            overflow='hidden'
          >
            <Flex direction='column' gap='16px'>
              <Text color='#1D2939' fontSize={{ sm: '18px', lg: '20px' }} fontWeight='600'>
                {t('pass_migration.online_digital_pass_request_1')}
              </Text>
              <Text color='#1D2939' fontSize={{ sm: '18px', lg: '20px' }} fontWeight='600' lineHeight='30px'>
                <Trans i18nKey='pass_migration.online_digital_pass_request_2' />
              </Text>
              <Text color='#1D2939' fontSize={{ sm: '18px', lg: '20px' }} fontWeight='600'>
                {t('pass_migration.online_digital_pass_request_5')}
              </Text>
            </Flex>

            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} direction='column' gap='16px' align='center'>
              <Text fontSize='20px' fontWeight='700' color='#282828' textAlign='center'>
                {t('network_schedule.download_mobile')}
              </Text>
              <Flex gap='16px'>
                <Flex as='button' onClick={() => window.open('https://apps.apple.com/pt/app/pick-hub/id1343817323', '_blank')}>
                  <Image src={appStore} />
                </Flex>
                <Flex as='button' onClick={() => window.open('https://play.google.com/store/apps/details?id=ubirider.ubirider', '_blank')}>
                  <Image src={playStore} />
                </Flex>
                <Flex as='button' onClick={() => window.open('https://appgallery.huawei.com/app/C102997355', '_blank')}>
                  <Image src={appGallery} />
                </Flex>
              </Flex>
            </Flex>

            <Flex display={{ sm: 'flex', lg: 'none' }} direction='column' gap='16px' align='center' justify='center' zIndex='5'>
              <Text fontSize='18px' fontWeight='700' color='#282828' textAlign='center'>
                {t('network_schedule.download_mobile')}
              </Text>
              <Flex direction='row' gap='8px'>
                <Flex
                  align='center'
                  justify='center'
                  bg='#282828'
                  border='1px solid #D0D5DD'
                  borderRadius='4px'
                  padding='8px 32px'
                  as='button'
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=ubirider.ubirider', '_blank')}
                >
                  <Image src={google} minW='28px !important' />
                </Flex>
                <Flex
                  align='center'
                  justify='center'
                  bg='#282828'
                  border='1px solid #D0D5DD'
                  borderRadius='4px'
                  padding='8px 32px'
                  as='button'
                  onClick={() => window.open('https://apps.apple.com/pt/app/pick-hub/id1343817323', '_blank')}
                >
                  <Image src={apple} minW='28px !important' />
                </Flex>
                <Flex
                  align='center'
                  justify='center'
                  bg='#282828'
                  border='1px solid #D0D5DD'
                  borderRadius='4px'
                  padding='8px 32px'
                  as='button'
                  onClick={() => window.open('https://appgallery.huawei.com/app/C102997355', '_blank')}
                >
                  <Image src={huawei} minW='36px !important' />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={{ sm: 'column', lg: 'row' }}
              gap={{ sm: '24px', lg: '8px' }}
              padding='16px'
              align='center'
              justify='space-between'
              bg='#E0EAFF'
              border='1px solid #C7D7FE'
              borderRadius='8px'
              w='100%'
            >
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='600' color='#282828' textAlign='center'>
                {t('pass_migration.dont_have_pass_card')}
              </Text>
              <Flex display={{ sm: 'none', lg: 'flex' }}>
                <Button onClick={onOpenOrderPass} style='solid' type='secondary' label={t('pass_migration.dont_have_pass_card_request_button')} css={{ width: '210px' }} />
              </Flex>
              <Flex display={{ sm: 'flex', lg: 'none' }} w='100%'>
                <Button onClick={onOpenOrderPass} style='solid' type='secondary' label={t('pass_migration.dont_have_pass_card_request_button')} css={{ width: '100%' }} />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            direction='column'
            align='center'
            justify='center'
            gap={{ sm: '56px', lg: '90px' }}
            bg='white'
            width='100%'
            padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px 90px 130px', xl: '90px 130px 90px 130px' }}
            position='relative'
            overflow='hidden'
            as='form'
            onSubmit={handleSubmit(handlePassValidation)}
          >
            <Text color='#0875BC' fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' textAlign='center'>
              {t('pass_migration.how_to_choose_digital_pass')}
            </Text>

            <Flex direction={{ sm: 'column', lg: 'row' }} gap={{ sm: '24px', lg: '48px' }} align={{ sm: 'center', lg: 'flex-start' }}>
              <Flex flex='25' direction='column' gap='8px' align='center' justify='center' textAlign='center'>
                <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700'>
                  {t('pass_migration.digital_pass_step_1')}
                </Text>
                <Text color='#1D2939' fontSize='16px' fontWeight='600'>
                  {t('pass_migration.digital_pass_step_1_description')}
                </Text>
              </Flex>
              <Flex flex='25' direction='column' gap='8px' align='center' justify='center' textAlign='center'>
                <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700'>
                  {t('pass_migration.digital_pass_step_2')}
                </Text>
                <Text color='#1D2939' fontSize='16px' fontWeight='600'>
                  {t('pass_migration.digital_pass_step_2_description')}
                </Text>
              </Flex>
              <Flex flex='25' direction='column' gap='8px' align='center' justify='center' textAlign='center'>
                <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700'>
                  {t('pass_migration.digital_pass_step_3')}
                </Text>
                <Text color='#1D2939' fontSize='16px' fontWeight='600'>
                  {t('pass_migration.digital_pass_step_3_description')}
                </Text>
              </Flex>

              <Flex flex='25' direction='column' gap='8px' align='center' justify='center' textAlign='center'>
                <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700'>
                  {t('pass_migration.digital_pass_step_4')}
                </Text>
                <Text color='#1D2939' fontSize='16px' fontWeight='600'>
                  {t('pass_migration.digital_pass_step_4_description')}
                </Text>
              </Flex>
            </Flex>

            <Flex padding='8px 16px' gap='16px' align='flex-start' bg='#F2F4F7' border='1px solid #EAECF0' rounded='4px'>
              <Image src={infoIcon} w='24px' />
              <Text color='#1D2939' fontSize='14px'>
                <Trans i18nKey='pass_migration.digital_pass_recharge_date_inline' />
              </Text>
            </Flex>

            <Flex direction='column' gap={{ sm: '8px', lg: '24px' }} w='100%' justify='center' align='center'>
              <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700' textAlign='center'>
                {t('pass_migration.validate_current_card_details_title')}
              </Text>
              <Text color='#1D2939' fontSize={{ sm: '14px', lg: '16px' }} fontWeight='600' textAlign='center'>
                {t('pass_migration.validate_current_card_details_description')}
              </Text>
            </Flex>

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap='24px' width='100%'>
              <Controller
                name='pass_name'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={t('pass_migration.name_on_current_card')} error={errors.pass_name} hint={t('pass_migration.name_on_current_card_hint')}>
                    <TextInput {...field} placeholder={name} background={'white'} />
                  </FormItem>
                )}
              />

              <Controller
                name='pass_number'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={t('pass_migration.number_on_current_card')} error={errors.pass_number} hint={t('pass_migration.number_on_current_card_hint')}>
                    <TextInput {...field} placeholder={number} background={'white'} />
                  </FormItem>
                )}
              />
            </Flex>

            <Flex w='100%' position='relative' align='flex-end' justify='flex-end'>
              <Button submit loading={loading} style='solid' type='primary' label={t('pass_migration.validate')} css={{ width: '160px' }} />
            </Flex>

            <Text fontSize='14px' color='#1D2939' fontWeight='600'>
              <Trans i18nKey='pass_migration.pass_migration_customer_support'>
                Do you have any question about the migration to a digital card? Please email us at
                <Link className='chakra-custom-link' sx={{ fontWeight: '600', textDecoration: 'underline' }} href='mailto:susana.deus@eva-bus.com'>
                  proximo@proximo.pt
                </Link>
              </Trans>
            </Text>
          </Flex>
        </>
      )}
      {formStep === 2 && (
        <Flex
          direction='column'
          align='center'
          justify='center'
          gap={{ sm: '32px', lg: '90px' }}
          bg='#F9F9FB'
          width='100%'
          padding={{ sm: '48px 24px 160px 24px', md: '48px 24px 160px 24px', lg: '90px 130px 160px 130px', xl: '90px 130px 160px 130px' }}
          position='relative'
          overflow='hidden'
          as='form'
          onSubmit={handleSubmit(handlePassMigration)}
        >
          <Flex direction='column' gap={{ sm: '8px', lg: '24px' }} w='100%' justify='center' align='center'>
            <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700' textAlign='center'>
              {t('pass_migration.complete_personal_details_title')}
            </Text>
            <Text color='#1D2939' fontSize={{ sm: '14px', lg: '16px' }} fontWeight='600' textAlign='center'>
              {t('pass_migration.complete_personal_details_subtitle')}
            </Text>
          </Flex>

          <Flex direction='column' gap={{ sm: '16px', lg: '24px' }} w='100%'>
            <Flex gap='16px' padding='8px 16px' bg='#E0EAFF' border='1px solid #C7D7FE' rounded='4px' align='center' h='56px'>
              <Image w='24px' src={infoIconBlue} />
              <Text fontSize='14px' color='#444CE7'>
                <Trans i18nKey='pass_migration.inline_pick_hub_registration_and_download'>
                  If you choose a digital pass, you must already have a Pick Hub account created. Download Pick Hub app
                  <Link className='chakra-custom-link' sx={{ fontWeight: '600', textDecoration: 'underline' }} href='https://pickbyubirider.page.link/downloadpick' target='_blank'>
                    <Text textDecoration='underline' fontWeight='600'>
                      here
                    </Text>
                  </Link>
                  .
                </Trans>
              </Text>
            </Flex>

            <Text color='#1D2939' fontSize={{ sm: '14px', lg: '16px' }} fontWeight='600'>
              {t('pass_migration.pick_hub_register_email')}
            </Text>

            <Controller
              name='email'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.email')} error={errors.email}>
                  <TextInput background={'white'} {...field} />
                </FormItem>
              )}
            />
          </Flex>

          <Flex direction='column' gap='24px' w='100%'>
            <Controller
              name='name'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('pass_migration.full_name')} error={errors.name} hint={t('pass_migration.full_name_hint')}>
                  <TextInput background={'white'} {...field} />
                </FormItem>
              )}
            />

            <Controller
              name='address'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={t('common.address')} error={errors.address}>
                  <TextInput background={'white'} {...field} />
                </FormItem>
              )}
            />

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap='24px' width='100%'>
              <Flex flex='3'>
                <Controller
                  name='postal_code'
                  control={control}
                  rules={{
                    required: t('authentication.required_field'),
                    pattern: {
                      value: /^\d{4}-\d{3}$/,
                      message: t('authentication.invalid_postal_code'),
                    },
                  }}
                  render={({ field }) => (
                    <FormItem required label={t('common.postal_code')} error={errors.postal_code}>
                      <TextInput
                        background={'white'}
                        placeholder={t('authentication.enter_postal_code')}
                        {...field}
                        onChange={e => {
                          const value = e
                          if (value === null) {
                            field.onChange(null)
                          } else if (isNaN(parseInt(value.charAt(value.length - 1))) && value.charAt(value.length - 1) !== '-') {
                            field.onChange(field.value)
                          } else if (field.value === null) {
                            field.onChange(value)
                          } else if (value?.length <= 8 || !value) {
                            if (value?.length < field.value.length && field.value.charAt(field.value.length - 1) === '-') {
                              field.onChange(value.slice(0, -1))
                            } else if (value?.length === 4 && value?.indexOf('-') === -1) {
                              field.onChange(value + '-')
                            } else {
                              field.onChange(value)
                            }
                          }
                        }}
                      />
                    </FormItem>
                  )}
                />
              </Flex>
              <Flex flex='7'>
                <Controller
                  name='city'
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem required label={t('common.city')} error={errors.city}>
                      <TextInput background={'white'} {...field} />
                    </FormItem>
                  )}
                />
              </Flex>
            </Flex>

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap='24px' width='100%'>
              <Controller
                name='phone'
                control={control}
                rules={{
                  required: t('authentication.required_field'),
                  validate: { phone_number: v => (v !== null ? validatePhoneNumber(v) : true) || t('authentication.invalid_phone') },
                }}
                render={({ field }) => (
                  <FormItem required label={t('common.phone')} error={errors.phone}>
                    <PhoneNumberInput {...field} background={'white'} />
                  </FormItem>
                )}
              />

              <Controller
                name='birthdate'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={t('common.birthdate')} error={errors.birthdate}>
                    <DateInput {...field} background={'white'} minDate={minDate} maxDate={maxDate} />
                  </FormItem>
                )}
              />
            </Flex>

            <Flex direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap='24px' width='100%'>
              <Controller
                name='card_id'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={t('common.card_id')} error={errors.card_id}>
                    <TextInput css={{ backgroundColor: 'white' }} {...field} />
                  </FormItem>
                )}
              />

              <Controller
                name='tax_id'
                control={control}
                rules={{
                  validate: value => {
                    if (!value || /^(?:\d{8}|\d{9})$/.test(value)) {
                      return true
                    }
                    return t('authentication.invalid_tax_id')
                  },
                }}
                render={({ field }) => (
                  <FormItem label={t('common.taxpayer_id')} error={errors.tax_id}>
                    <TextInput
                      css={{ backgroundColor: 'white' }}
                      {...field}
                      onChange={e => {
                        const value = e
                        if (value === null) {
                          field.onChange(null)
                        } else if (isNaN(parseInt(value.charAt(value.length - 1)))) {
                          field.onChange(field.value)
                        } else if (field.value === null || value?.length <= 9 || !value) {
                          field.onChange(value)
                        }
                      }}
                    />
                  </FormItem>
                )}
              />
            </Flex>
          </Flex>

          <Divider border='1px solid #98A2B366' />

          <Flex direction='column' gap='48px' w='100%'>
            <Flex direction='column' gap='10px'>
              <Flex gap='16px' align='center'>
                <Text color='#1D2939' fontSize={{ sm: '14px', lg: '16px' }} fontWeight='600'>
                  {t('pass_migration.pass_associated_profile')}
                </Text>
                <Text color='#1D2939' fontSize={{ sm: '16px', lg: '18px' }} fontWeight='700'>
                  {data.profileName}
                </Text>
              </Flex>
              <Text color='#475467' fontSize={{ sm: '12px', lg: '14px' }}>
                {t('pass_migration.digital_pass_select_profile')}
              </Text>
            </Flex>

            <Controller
              name='profile_photo'
              control={control}
              rules={{ required: profilePhoto ? null : t('authentication.required_field') }}
              render={() => (
                <FormItem required label={t('common.profile_photo')} error={errors.profile_photo}>
                  <Flex direction={{ sm: 'column', lg: 'row' }} mt='16px' gap='32px' align='center' justify={{ sm: 'center', lg: 'flex-start' }} w='100%'>
                    <input type='file' accept='image/jpeg, image/jpg, image/png' ref={fileInputRef} style={{ display: 'none' }} onChange={handleSelectedProfilePhoto} />
                    <Flex as='button' onClick={handleProfilePhotoUpload}>
                      <Avatar size='large' name={null} src={profilePhoto || camera} type='circle' />
                    </Flex>
                    <Flex display={{ sm: 'none', lg: 'flex' }} gap='8px'>
                      <Button
                        onClick={handleProfilePhotoUpload}
                        loading={loadingUpload}
                        type='neutralDark'
                        style='solid'
                        size='small'
                        label={t('common.upload')}
                        css={{ width: '120px' }}
                      />
                      <Button onClick={handleClearPhoto} disabled={!profilePhoto} type='danger' style='ghost' size='small' label={t('common.cancel')} css={{ width: '120px' }} />
                      <Button type='neutralDark' style='outline' size='small' label={t('ticket_fares.take_photo')} css={{ width: '120px', display: 'none' }} />
                    </Flex>
                    <Flex display={{ sm: 'flex', lg: 'none' }} direction='column' gap='8px' w='100%'>
                      <Button
                        onClick={handleProfilePhotoUpload}
                        loading={loadingUpload}
                        type='neutralDark'
                        style='solid'
                        size='small'
                        label={t('common.upload')}
                        css={{ width: '100%' }}
                      />
                      <Button onClick={handleClearPhoto} disabled={!profilePhoto} type='danger' style='ghost' size='small' label={t('common.cancel')} css={{ width: '100%' }} />
                      <Button type='neutralDark' style='outline' size='small' label={t('ticket_fares.take_photo')} css={{ width: '120px', display: 'none' }} />
                    </Flex>
                  </Flex>
                </FormItem>
              )}
            />

            <Flex direction='column' gap='24px' width='100%'>
              {requiredDocs.map(document => (
                <Controller
                  name={document}
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem required label={document} error={errors.document}>
                      <FileInput {...field} maxSize={2} expanded={false} multiple={false} allowedExtensions={['pdf', 'png', 'jpeg', 'jpg']} />
                    </FormItem>
                  )}
                />
              ))}

              {requiredDocs.includes(1) && (
                <Controller
                  name='proof_residence'
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem required label={t('ticket_fares.proof_residence')} error={errors.proof_residence} hint={t('ticket_fares.proof_residence_example')}>
                      <FileInput {...field} maxSize={2} expanded={false} multiple={false} allowedExtensions={['pdf', 'png', 'jpeg', 'jpg']} />
                    </FormItem>
                  )}
                />
              )}

              {requiredDocs.includes(2) && (
                <Controller
                  name='proof_school'
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem required label={t('ticket_fares.proof_school_enrolment')} error={errors.proof_school}>
                      <FileInput {...field} maxSize={2} expanded={false} multiple={false} allowedExtensions={['pdf', 'png', 'jpeg', 'jpg']} />
                    </FormItem>
                  )}
                />
              )}
            </Flex>

            <Flex position='relative' align='flex-end' justify='flex-end'>
              <Button submit loading={loading} style='solid' type='primary' label={t('pass_migration.switch_to_digital')} css={{ width: '180px' }} />
            </Flex>
          </Flex>

          <Text fontSize='14px' color='#1D2939' fontWeight='600'>
            <Trans i18nKey='pass_migration.pass_migration_customer_support'>
              Do you have any question about the migration to a digital card? Please email us at
              <Link className='chakra-custom-link' sx={{ fontWeight: '600', textDecoration: 'underline' }} href='mailto:susana.deus@eva-bus.com'>
                proximo@proximo.pt
              </Link>
            </Trans>
          </Text>
        </Flex>
      )}
      {formStep === 3 && (
        <>
          <Flex
            direction={{ sm: 'column', lg: 'row' }}
            align='center'
            justify='space-between'
            gap={{ sm: '48px', lg: '8px' }}
            bg={{ sm: 'linear-gradient(193deg, #444CE7 0%, rgba(128, 152, 249, 0.20) 100%)', lg: 'linear-gradient(140deg, #444CE7 0%, rgba(128, 152, 249, 0.20) 100%)' }}
            width='100%'
            padding={{ sm: '48px 24px 8px 24px', md: '48px 24px 8px 24px', lg: '0 0 100px 100px', xl: '0 0 100px 130px' }}
            position='relative'
            overflow='hidden'
          >
            <Flex
              display={{ sm: 'none', md: 'flex' }}
              direction='column'
              gap={{ sm: '32px', lg: '24px' }}
              w={{ sm: '100%', lg: '95%', xl: '50%' }}
              h='100%'
              pt={{ lg: '100px' }}
              align='flex-start'
              justify='center'
            >
              <Text color='white' fontSize='48px'>
                <Trans i18nKey='pass_migration.banner_digital_pass_migration' />
              </Text>
            </Flex>
            <Flex w='800px' h={{ sm: '200px', md: undefined }}>
              <Image
                src={bgLogo}
                w={{ sm: '325px', md: '405px', lg: '585px', xl: '600px' }}
                position='absolute'
                bottom={{ sm: '-10px', lg: '-10px' }}
                right={{ sm: '25px', lg: '-135px', xl: '0' }}
              />
            </Flex>
          </Flex>

          <Flex
            direction='column'
            align='center'
            justify='center'
            gap={{ sm: '24px', lg: '24px' }}
            bg='white'
            width='100%'
            padding={{ sm: '48px 24px 24px 24px', md: '48px 24px 24px 24px', lg: '90px 130px 45px 130px', xl: '90px 130px 45px 130px' }}
            position='relative'
            overflow='hidden'
          >
            <Flex direction={{ sm: 'column', lg: 'row' }} gap='24px' align='center'>
              <Image w='48px' src={greenCheck} />
              <Text color='#002239' fontSize={{ sm: '24px', lg: '36px' }} fontWeight='700' textAlign={{ sm: 'center', lg: 'left' }}>
                {t('pass_migration.changed_confirmed_title')}
              </Text>
            </Flex>
            <Text color='#1D2939' fontSize={{ sm: '14px', lg: '16px' }} fontWeight='600' textAlign={{ sm: 'center', lg: 'left' }}>
              {t('pass_migration.changed_confirmed_subtitle', { email })}
            </Text>
            <Button onClick={() => navigate('/pass-migration')} style='solid' type='primary' label={t('pass_migration.go_to_homepage_button')} css={{ width: '210px' }} />
          </Flex>
        </>
      )}
      <ErrorModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} type={errorType} number={pass_number} />
      <OrderPassModal
        isOpen={isOpenOrderPass}
        onOpen={onOpenOrderPass}
        onClose={onCloseOrderPass}
        onOpenCancel={onOpenCancel}
        onOpenRedirect={onOpenRedirect}
        setOrderCode={setOrderCode}
        prices={prices}
        types={typePrices}
        path={'/pass-migration'}
      />
      <CancelModal isOpen={isOpenCancel} onOpen={onOpenCancel} onClose={onCloseCancel} onCloseOrderPass={onCloseOrderPass} />
      <RedirectModal isOpen={isOpenRedirect} onOpen={onOpenRedirect} onClose={onCloseRedirect} orderCode={orderCode} />
    </Flex>
  )
}

export default PassMigration
