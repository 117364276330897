import { AccordionButton, AccordionItem, AccordionPanel, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import config from '../../../../../../config/configLoader'

import greenCheck from '../../../../../../assets/check_circle_green.svg'
import { RichText } from '@ubirider/pick-component-library'

export const RequirementCard = ({ title, info, proof }) => {
  const { t } = useTranslation()

  const infoArray = info.split('\\n')
  const proofArray = proof.split('\\n')

  return (
    <AccordionItem marginBlock='16px' rounded='8px' width='100%'>
      {({ isExpanded }) => (
        <Flex rounded='8px' border='1px' borderColor='#D3D3D3' direction='column' css={'box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1)'} w='100%'>
          <AccordionButton padding='18px' bg='white' borderRadius={isExpanded ? '8px 8px 0px 0px' : '8px'} _hover={{}} w='100%'>
            <Flex justifyContent='space-between' align='center' w='100%' gap='8px'>
              <Text fontSize={{ sm: '14px', lg: '18px' }} fontWeight='700' color='#1D2939' textAlign='left'>
                {title}
              </Text>
              {isExpanded ? <MinusIcon fontSize='12px' /> : <AddIcon fontSize='12px' />}
            </Flex>
          </AccordionButton>
          <AccordionPanel bg='white' padding='0 24px 16px 24px' borderRadius='0 0 8px 8px'>
            <Divider border='1px solid #D0D5DD' />
            <Flex flex='1' direction='column' pt='16px' gap='16px'>
              <Flex direction='column' w='100%' bg={config.themeTokens.colors.primary100} borderRadius='4px' gap='8px' padding='16px'>
                {infoArray.map(info => (
                  <RichText type='textMD' weight='semibold'>
                    {info}
                  </RichText>
                ))}
              </Flex>
              <Text fontSize='16px' fontWeight='600' color='#1D2939'>
                {t('ticket_fares.required_documents')}
              </Text>
              <Flex direction='column' gap='8px'>
                {proofArray.map(proof => (
                  <Flex gap='8px' align='center'>
                    <Image src={greenCheck} w='16px' />
                    <Text fontSize='14px' color='#282828'>
                      {proof}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}
