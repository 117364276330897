import { Flex, Text, useDisclosure, Progress } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormItem, Select } from '@ubirider/pick-component-library'
import { Controller, useFormContext } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { AlertModal } from '../../../../modals/AlertModal'
import { getAlertsForRoute, getStopsForRoute } from '../../../../hooks/getMapInfo'
import config from '../../../../config/configLoader'

import { useNavigate } from 'react-router-dom'
import { RouteCardHeader } from './components/RouteCardHeader'
import { RouteRealTimeInfo } from './components/RouteRealTimeInfo'
import { RouteStopsList } from './components/RouteStopsList'

function RouteCard({
  focus,
  network,
  selectedNetwork,
  stops,
  alerts,
  selectedRoute,
  selectedStop,
  selectedPattern,
  stopSequence,
  hoveredRoute,
  handleHoverRoute,
  hoveredStop,
  handleHoverStop,
  setSearchValue,
  openSchedules,
  closeDrawer,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { control } = useFormContext()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [loading, setLoading] = useState(false)
  const [alertsExpanded, expandAlerts] = useState(false)
  const [selectedAlert, selectAlert] = useState(null)

  const stopValue = !focus ? getStopsForRoute(stops, network.id) : null
  const alertValue = !focus ? getAlertsForRoute(Object.values(alerts.entities), network.id, stopValue) : null

  const name = focus
    ? selectedNetwork?.route?.name.replace(
        /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
        match => match.charAt(0) + match.slice(1).toLowerCase()
      )
    : network.name.replace(/([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g, match => match.charAt(0) + match.slice(1).toLowerCase())

  const routePatterns = focus
    ? Object.values(selectedNetwork.route.patterns?.entities || {}).map(entity => entity?.name)
    : null

  const patternOptions = focus
    ? routePatterns?.map((value, index) => ({
        label: value,
        value: index,
      }))
    : null

  useEffect(() => {
    expandAlerts(false)
  }, [selectedRoute])

  useEffect(() => {
    if (selectedNetwork && selectedPattern !== 0) {
      navigate(`/network-and-schedules/route/${selectedRoute}/${selectedPattern}`)
    }
  }, [selectedPattern])

  function handleClickRoute() {
    setLoading(true)
    navigate(`/network-and-schedules/route/${focus ? selectedNetwork.route.id : network.id}/0`)
  }

  function handleClickStop(stop) {
    navigate(`/network-and-schedules/stop/${stop.id}`)
    setSearchValue(stop.name)
  }

  function handleAlert(alert) {
    selectAlert(alert)
    onOpen()
  }

  function handleSchedules() {
    openSchedules()
    closeDrawer()
  }

  return (
    <Flex
      direction='column'
      gap='24px'
      border={selectedRoute ? { lg: '1px solid #CACFD8' } : '1px solid #CACFD8'}
      bg={!focus && hoveredRoute === selectedNetwork?.id ? config.hoverColor : 'white'}
      boxShadow={selectedRoute ? { lg: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' } : '0px 2px 20px 1px rgba(0, 0, 0, 0.1)'}
      borderRadius={selectedRoute ? { lg: '8px' } : '8px'}
      p='0 1px'
      _hover={selectedRoute ? null : { bg: config.hoverColor, border: '1px solid ' + config.mainColor }}
    >
      {loading && <Progress size='xs' isIndeterminate borderRadius='64px 64px 0 0' mr='0.5px' />}

      <RouteCardHeader
        focus={focus}
        network={network}
        selectedNetwork={selectedNetwork}
        alertValue={alertValue}
        alertsExpanded={alertsExpanded}
        expandAlerts={expandAlerts}
        name={name}
        selectedRoute={selectedRoute}
        selectedStop={selectedStop}
        handleHoverRoute={handleHoverRoute}
        handleClickRoute={handleClickRoute}
        loading={loading}
        handleAlert={handleAlert}
        handleSchedules={handleSchedules}
        closeDrawer={closeDrawer}
      />

      {selectedRoute && !selectedStop && (
        <Flex
          direction='column'
          padding={selectedRoute ? { sm: '0 0 16px 0', lg: '0 16px 16px 16px' } : '0 16px 16px 16px'}
          gap='24px'
          w='100%'
        >
          <Controller
            name='pattern'
            control={control}
            render={({ field }) => (
              <FormItem label={t('network_schedule.direction')}>
                <Select options={patternOptions} {...field} css={{ textAlign: 'left', width: '100%' }} />
              </FormItem>
            )}
          />

          {/*<RouteRealTimeInfo selectedNetwork={selectedNetwork} selectedPattern={selectedPattern} />*/}

          <RouteStopsList
            selectedNetwork={selectedNetwork}
            selectedPattern={selectedPattern}
            stopSequence={stopSequence}
            hoveredStop={hoveredStop}
            handleHoverStop={handleHoverStop}
            handleClickStop={handleClickStop}
          />
        </Flex>
      )}

      <AlertModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        alert={selectedAlert}
        network={selectedNetwork}
        allNetwork={network}
        type={0}
      />
    </Flex>
  )
}

export default RouteCard
