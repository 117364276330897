import { useTranslation } from 'react-i18next'
import { Flex, Text, Image } from '@chakra-ui/react'

import phone from '../../../../../../assets/pass_migration_phone.svg'
import phone_mobile from '../../../../../../assets/pass_migration_phone_mobile.svg'
import appStore from '../../../../../../assets/app_store.svg'
import playStore from '../../../../../../assets/google_play.svg'
import appGallery from '../../../../../../assets/app_gallery.svg'
import google from '../../../../../../assets/google_play_black.svg'
import apple from '../../../../../../assets/apple_black.svg'
import huawei from '../../../../../../assets/huawei_black.svg'
import React from 'react'
import { Link } from 'react-router-dom'

export function PassMigrationAdPage() {
  const { t } = useTranslation()

  return (
    <Flex
      h={{ sm: '716px', lg: '100%' }}
      w='100%'
      direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      justify='flex-start'
      align='center'
      padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px', xl: '90px 130px' }}
      gap={{ sm: '32px', md: '32px', lg: '17px', xl: '17px' }}
      bg='linear-gradient(140deg, #444CE7 0%, rgba(128, 152, 249, 0.20) 100%)'
      position='relative'
      overflow='hidden'
    >
      <Flex direction='column' gap={{ sm: '16px', lg: '24px' }} w={{ lg: '50%' }} h={{ sm: '716px', lg: '100%' }}>
        <Text
          textAlign='left'
          fontSize={{ sm: '18px', md: '18px', lg: '20px', xl: '20px' }}
          fontWeight='700'
          color='white'
          fontFamily='Times'
          fontStyle='italic'
          lineHeight={{ sm: '38px', lg: '44px' }}
        >
          {t('pass_migration.promo_catchphrase')}
        </Text>
        <Flex direction='column' gap='8px'>
          <Text textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '36px', xl: '36px' }} fontWeight='700' color='white'>
            {t('pass_migration.promo_title')}
          </Text>
          <Text textAlign='left' fontSize={{ sm: '30px', md: '30px', lg: '36px', xl: '36px' }} color='white'>
            {t('pass_migration.promo_subtitle')}
          </Text>
        </Flex>
        <Text textAlign='left' fontSize={{ sm: '14px', md: '14px', lg: '18px', xl: '18px' }} fontWeight='600' color='white'>
          {t('pass_migration.promo_description_1')}
        </Text>
        <Text display={{ sm: 'none', lg: 'flex' }} textAlign='left' fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='600' color='#1D2939'>
          {t('pass_migration.promo_description_2')}
        </Text>
        <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} direction='column' gap='16px' align='flex-start'>
          <Text fontSize='20px' fontWeight='700' color='#282828'>
            {t('home.download_now')}
          </Text>
          <Flex gap='16px'>
            <Link to={'https://apps.apple.com/pt/app/pick-hub/id1343817323'} target={'_blank'}>
              <Image src={appStore} />
            </Link>
            <Link to={'https://play.google.com/store/apps/details?id=ubirider.ubirider'} target={'_blank'}>
              <Image src={playStore} />
            </Link>
            <Link to={'https://appgallery.huawei.com/app/C102997355'} target={'_blank'}>
              <Image src={appGallery} />
            </Link>
          </Flex>
        </Flex>
      </Flex>

      <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} position='absolute' w='655px' right='-75px' bottom='-200px'>
        <Image src={phone} objectFit='contain' />
      </Flex>

      <Flex display={{ sm: 'flex', lg: 'none' }} direction='column' gap='16px' align='center' justify='center' zIndex='5'>
        <Text fontSize='18px' fontWeight='700' color='#282828'>
          {t('home.download_now')}
        </Text>
        <Flex direction='row' gap='8px'>
          <Flex
            align='center'
            justify='center'
            bg='white'
            border='1px solid #D0D5DD'
            borderRadius='4px'
            padding='8px 32px'
            as='button'
            onClick={() => window.open('https://play.google.com/store/apps/details?id=ubirider.ubirider', '_blank')}
          >
            <Image src={google} minW='28px !important' />
          </Flex>
          <Flex
            align='center'
            justify='center'
            bg='white'
            border='1px solid #D0D5DD'
            borderRadius='4px'
            padding='8px 32px'
            as='button'
            onClick={() => window.open('https://apps.apple.com/pt/app/pick-hub/id1343817323', '_blank')}
          >
            <Image src={apple} minW='28px !important' />
          </Flex>
          <Flex
            align='center'
            justify='center'
            bg='white'
            border='1px solid #D0D5DD'
            borderRadius='4px'
            padding='8px 32px'
            as='button'
            onClick={() => window.open('https://appgallery.huawei.com/app/C102997355', '_blank')}
          >
            <Image src={huawei} minW='36px !important' />
          </Flex>
        </Flex>
        <Text textAlign='left' fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='600' color='#1D2939'>
          {t('pass_migration.promo_description_2')}
        </Text>
      </Flex>

      <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} mb='-50px' w='375px'>
        <Image src={phone_mobile} objectFit='contain' />
      </Flex>
    </Flex>
  )
}
