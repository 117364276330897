import { Flex } from '@chakra-ui/react'
import { MainHeader } from './components/MainHeader'
import { AlertSection } from './components/AlertSection'
import { NewsSection } from './components/NewsSection'
import { AdSection } from './components/AdSection'
import { ReviewsSection } from './components/ReviewsSection'
import { useEffect } from 'react'
import config from '../../config/configLoader'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' flex='1'>
      <MainHeader />
      <AlertSection />
      <AdSection />
      {config.sections.news && <NewsSection />}
      {config.sections.reviews && <ReviewsSection />}
    </Flex>
  )
}

export default Home
