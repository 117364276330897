import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import config from '../../../../config/configLoader'

import complaintsBook from '../../../../assets/complaints_book.svg'

export function FooterContacts() {
  const { t } = useTranslation()

  return (
    <Flex direction='column' gap={{ sm: '24px', md: '24px', lg: '40px', xl: '40px' }} align='flex-start'>
      <Flex direction='column' gap='24px'>
        <Text textAlign='left' fontSize='24px' fontWeight='700' color='#212721'>
          {t('home.contact')}
        </Text>
        <Flex direction='column' gap={{ sm: '8px', md: '8px', lg: '24px', xl: '24px' }}>
          <Flex direction='column'>
            <Text textAlign='left' fontSize='14px'>
              {t('home.operator_address_1')}
            </Text>
            <Text textAlign='left' fontSize='14px'>
              {t('home.operator_address_2')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Link className='chakra-custom-link' to={`tel:${config.info.phone}`}>
              <Text textAlign='left' fontSize='14px'>
                {config.info.phone}
              </Text>
            </Link>
            <Text fontSize='13px' color='#1D2939'>
              {t('home.call_cost')}
            </Text>
          </Flex>
          <Link className='chakra-custom-link' to={`mailto:${config.info.email}`}>
            <Text textAlign='left' fontSize='14px'>
              {config.info.email}
            </Text>
          </Link>
        </Flex>
      </Flex>

      <Link to='https://www.livroreclamacoes.pt/Inicio/' target='_blank'>
        <Image src={complaintsBook} w='140px' />
      </Link>
    </Flex>
  )
}
