import { useTranslation } from 'react-i18next'
import { Flex, Grid, Text } from '@chakra-ui/react'

import { AlertCard } from './AlertCard'
import { Button } from '@ubirider/pick-component-library'
import React, { useEffect, useState } from 'react'
import { getNetworkInfo, agencyID } from '../../../../api/requests'
import { customLog } from '../../../../helpers/log'
import { useNavigate } from 'react-router-dom'

export function AlertSection() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [alerts, setAlerts] = useState(null)
  const [network, setNetwork] = useState(null)
  const [done, setDone] = useState(false)

  useEffect(() => {
    const getInfo = async () => {
      const alertsData = await getNetworkInfo('alerts/getAlerts', { agencyID: agencyID })
      const networkData = await getNetworkInfo('network/getNetwork', { agencyID: agencyID })
      const sortedArray = Object.values(alertsData.entities).sort((a, b) => b.id - a.id)
      setAlerts(sortedArray)
      setNetwork(networkData)
      setDone(true)
      customLog(sortedArray)
    }

    if (!done) {
      getInfo()
    }
  }, [])

  if (!done || alerts.length === 0) {
    return null
  } else {
    return (
      <Flex
        direction='column'
        align='center'
        width='100%'
        minH={done && alerts.length > 0 ? { lg: '678px' } : '273px'}
        bg='#F9F9FB'
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 130px', xl: '90px 130px' }}
      >
        <Flex direction='column' align='center' justify='center' gap={{ sm: '32px', md: '32px', lg: '60px', xl: '60px' }} w='100%' h='100%'>
          <Flex direction='column' align='center' gap={{ sm: '8px', lg: '16px' }}>
            <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20' textAlign='center'>
              {t('home.notification_center_title')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#1D2939' textAlign='center'>
              {t('home.notification_center_subtitle')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Grid
              templateColumns={{ sm: 'repeat(' + alerts.length + ', 296px)', md: 'repeat(3, 1fr)' }}
              overflowX={{ sm: 'scroll', lg: 'none' }}
              maxW='calc(100vw)'
              gap='16px'
              padding='16px'
              sx={{ '::-webkit-scrollbar': { display: 'none' } }}
              placeItems={alerts.length === 1 ? 'center' : undefined}
            >
              {alerts.slice(0, 3).map(alert => (
                <AlertCard key={alert.id} alert={alert} network={network} />
              ))}
            </Grid>
          </Flex>

          <Flex>
            <Button onClick={() => navigate('/notification-center')} style='solid' type='neutralDark' label={t('home.view_all')} css={{ width: '152px' }} />
          </Flex>
        </Flex>
      </Flex>
    )
  }
}
